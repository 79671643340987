import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Checkbox, createFilterOptions } from '@mui/material'
import { Autocomplete } from '../../../common/components/form'

export const CountriesAutoComplete = ({ name, control, countryData }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const countryFilterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: (option: any) => option.code + option.name,
  })

  return (
    <Autocomplete
      name={name}
      control={control}
      multiple
      options={countryData}
      limitTags={3}
      disableCloseOnSelect
      filterOptions={countryFilterOptions}
      getOptionLabel={option => option.code}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.name} ({option?.code})
        </li>
      )}
    />
  )
}
