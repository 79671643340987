import { useQuery, UseQueryResult } from 'react-query'
import { fetchOpenRebookingTypes, fetchShipments } from '../../services/ShipmentService'
import { QueryState } from './useQueryState'
import { RebookingInitiateFilter, RebookingApproveFilter } from '../../services/ShipmentService'

const shipmentQueries = {
  all: ['shipments'],
  lists: ['shipments', 'list'],
  list: queryState => [...shipmentQueries.lists, queryState],
}

const openRebookingTypeQueries = {
  all: ['openRebookingTypes'],
  lists: ['openRebookingTypes', 'list'],
  list: queryState => [...openRebookingTypeQueries.lists, queryState],
}

export const useShipmentList = (
  queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
): UseQueryResult<{
  data
  totalResources: number
  totalPages
}> => {
  return useQuery(shipmentQueries.list(queryState), () => fetchShipments(queryState), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

export const useOpenRebookingTypes = (
  queryState: QueryState<RebookingApproveFilter>
): UseQueryResult<{
  data: string[]
}> => {
  return useQuery(openRebookingTypeQueries.list(queryState), () => fetchOpenRebookingTypes(queryState), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}
