import { useMemo } from 'react'
import { SelectAllCell, SelectAllHeader } from '../../../common/utils/SelectAllServerRows'
import { useServerDataTable } from '../../../common/components/table'
import { useAllServerRowsSelect } from '../../../common/hooks/useAllServerRowsSelect'
import { Tooltip, Text, Icon } from '@nike/eds'
import { date } from '../../../common/utils/datautils/formatter'
import TooltipRebookingContent from '../../../common/components/tooltiprebookingcontent/TooltipRebookingContent'

const isSelectCheckboxDisabled = row =>
  row?.values?.rebookingStatusData?.status === 'OPEN' ||
  row?.values?.rebookingStatusData?.status === 'INITIATING'

export const useInitiateRebookingTable = (
  queryState,
  gotoPage,
  setPageSize,
  toggleSortBy,
  isFetching,
  isSuccess,
  isError,
  data
) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        width: 20,
        Header: props => <SelectAllHeader {...props} />,
        Cell: props => (
          <SelectAllCell {...props} isSelectCheckboxDisabled={isSelectCheckboxDisabled} />
        ),
      },
      {
        id: 'expander',
        Header: () => null,
        Cell: ({ row }) => {
          return (
            <Tooltip
              bodySlot={<Text>{row.isExpanded ? 'Hide' : 'Show'} history</Text>}
              placement={'right'}
              isDark={true}
            >
              <Icon
                {...row.getToggleRowExpandedProps()}
                name={row.isExpanded ? 'CaretUp' : 'History'}
              />
            </Tooltip>
          )
        },
        width: 20,
      },
      {
        Header: () => <div>Packlist</div>,
        accessor: 'shipmentReference',
        Cell: item => (
          <Tooltip bodySlot={<Text>{item.value}</Text>} isDark={true} placement={'right'}>
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '90px',
              }}
              title={item.value}
            >
              {item.value}
            </div>
          </Tooltip>
        ),
        width: 90,
      },
      {
        Header: () => <div>Packlist Status</div>,
        accessor: 'packlistStatus',
        Cell: item => <div>{item.value}</div>,
      },
      {
        Header: () => <div>Carrier</div>,
        accessor: 'carrierAccountCode',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },

      {
        Header: () => <div>Hub</div>,
        accessor: 'hubCode',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div># Cartons</div>,
        accessor: 'totalNumberOfCartons',
        Cell: item => <div>{item.value}</div>,
        width: 80,
      },
      {
        Header: () => <div># Units</div>,
        accessor: 'totalNumberOfUnits',
        Cell: item => <div>{item.value}</div>,
        width: 80,
      },
      {
        Header: () => <div>Sales Order</div>,
        accessor: 'salesOrder',
        Cell: item => (
          <Tooltip
            isDark
            modifiers={[
              {
                name: 'preventOverflow',
                options: {
                  padding: 8,
                },
              },
            ]}
            bodySlot={<Text>{item.value?.join(', ')}</Text>}
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '150px',
              }}
            >
              {item.value?.join(', ')}
            </div>
          </Tooltip>
        ),
        width: 150,
      },
      {
        Header: () => <div>Delivery Note</div>,
        accessor: 'deliveryDoc',
        Cell: item => (
          <Tooltip
            isDark
            modifiers={[
              {
                name: 'preventOverflow',
                options: {
                  padding: 8,
                },
              },
            ]}
            bodySlot={<Text>{item.value?.join(', ')}</Text>}
          >
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '150px',
              }}
            >
              {item.value?.join(', ')}
            </div>
          </Tooltip>
        ),
        width: 150,
      },
      {
        Header: () => <div>Direct Drop</div>,
        accessor: 'directDrop',
        Cell: item => <div>{item.value ? 'Y' : 'N'}</div>,
        width: 45,
      },
      {
        Header: () => <div>Original Ship To Number</div>,
        accessor: 'shipToCode',
        Cell: item => <div>{item.value}</div>,
        width: 120,
      },
      {
        Header: () => <div>Original IDP Date</div>,
        accessor: 'iDPPlannedTimestamp',
        id: 'originalIDPPlannedTimestamp',
        disableSortBy: true,
        Cell: item => (
          <div>
            {date(item.value.original?.date, false, true)}
            <br></br>
            {item.value.original?.plannedDeliveryFromTime
              ? item.value.original?.plannedDeliveryFromTime +
                ' - ' +
                item.value.original?.plannedDeliveryToTime
              : undefined}
          </div>
        ),
        minWidth: 150,
      },
      {
        Header: () => <div>Original Traffic Instructions</div>,
        id: 'originalTrafficInstructions',
        accessor: 'deliveryInstructionText',
        disableSortBy: true,
        Cell: item => <div>{item.value.original}</div>,
      },
      {
        Header: () => <div>New IDP Date</div>,
        accessor: 'iDPPlannedTimestamp',
        id: 'newIDPPlannedTimestamp',
        disableSortBy: true,
        Cell: item => (
          <div>
            <span
              className={
                item.value.original?.date !== item.value.new?.date
                  ? 'eds-color--pink-70 fw-bold'
                  : 'eds-color--default'
              }
            >
              {date(item.value.new?.date, false, true)}
            </span>
            <br></br>
            <span
              className={
                item.value.original?.plannedDeliveryFromTime !==
                  item.value.new?.plannedDeliveryFromTime ||
                item.value.original?.plannedDeliveryToTime !== item.value.new?.plannedDeliveryToTime
                  ? 'eds-color--pink-70 fw-bold'
                  : 'eds-color--default'
              }
            >
              {item.value.new?.plannedDeliveryFromTime
                ? item.value.new?.plannedDeliveryFromTime +
                  ' - ' +
                  item.value.new?.plannedDeliveryToTime
                : undefined}
            </span>
          </div>
        ),
        minWidth: 150,
      },
      {
        Header: () => <div>New Traffic Instructions</div>,
        id: 'newTrafficInstructions',
        accessor: 'deliveryInstructionText',
        disableSortBy: true,
        Cell: item => <div>{item.value.new}</div>,
      },
      {
        Header: () => <div>Type</div>,
        accessor: 'rebookingTypeData',
        disableSortBy: false,
        Cell: item => (
          <div>
            {item.value.type && (
              <Tooltip
                bodySlot={<TooltipRebookingContent type={item.value.type} data={item.value.data} />}
                isDark={true}
                placement={'right'}
                modifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      padding: 8,
                    },
                  },
                ]}
              >
                <div
                  className="flex-row flex-nowrap content-center justify-items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                    {item.value.type}
                  </div>
                  <Icon name="InfoCircle" size="s" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
        width: 120,
      },
      {
        Header: () => <div>Status</div>,
        accessor: 'rebookingStatusData',
        Cell: item => (
          <div>
            {(item.value.status && !['INITIATING', 'OPEN'].includes(item.value.status) && (
              <Tooltip
                bodySlot={
                  <TooltipRebookingContent type={item.value.status} data={item.value.data} />
                }
                isDark={true}
                placement={'right'}
              >
                <div
                  className="flex-row flex-nowrap content-center justify-items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                    {item.value.status}
                  </div>
                  <Icon name="InfoCircle" size="s" />
                </div>
              </Tooltip>
            )) || (
              <div>
                <div
                  className="flex-row flex-nowrap justify-items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                    {item.value.status}
                  </div>
                </div>
              </div>
            )}
          </div>
        ),
        width: 100,
      },
    ],
    []
  )

  return useServerDataTable({
    queryState,
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
    overrides: {
      gotoPage,
      setPageSize,
      toggleSortBy,
    },
    extraPlugins: [useAllServerRowsSelect],
  })
}
