import { useQuery, UseQueryResult } from 'react-query'
import { fetchReasons } from '../../services/RebookingService'

export interface ReasonResponse {
  code?: string
  deprecated?: boolean
  description?: string
  responsibleParty?: string
  allowExtraInfo?: boolean
}

export const useReasons = (): UseQueryResult<ReasonResponse[], any> => {
  return useQuery(['reasons'], fetchReasons, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
