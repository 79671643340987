import { Button, ButtonGroup, Modal, Text } from '@nike/eds'
import axios from 'axios'
import 'react-dropdown-tree-select/dist/styles.css'
import { deleteAutoRebookingApprovalConfig } from '../../../../services/RebookingService'
import { logUsage } from '../../../../services/ShipmentService'
import './DeleteModal.css'

interface DeleteModalProps {
  deleteModalOpen: boolean
  onCloseDeleteModal: () => void
  selectedRows: any
  refetchData: () => void
}

export const DeleteModal = ({
  deleteModalOpen = false,
  onCloseDeleteModal,
  selectedRows,
  refetchData,
}: DeleteModalProps): JSX.Element => {
  const handleDeleteRebooking = async () => {
    try {
      for (const selectedRow of selectedRows) {
        await deleteAutoRebookingApprovalConfig(selectedRow)
      }
      logUsage('automaticApproval', 'delete_automatic_approval')
      onCloseDeleteModal()
      refetchData()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Cannot delete automatic approval config: ' + JSON.stringify(error.toJSON()))
        throw error
      }
    }
  }

  return (
    <Modal
      disableFocusTrap={true}
      isOpen={deleteModalOpen}
      onDismiss={onCloseDeleteModal}
      headerSlot={
        <div className="flex-row flex-wrap content-space-between justify-items-center">
          <div>
            <Text font="title-3">Delete Automatic Approval Config</Text>
            {selectedRows.length > 0 ? (
              <Text font="body-3" className="eds-spacing--mt-16 eds-spacing--mb-24">
                <b style={{ color: 'var(--eds-color-red-50)' }}>
                  {`You are about to delete ${selectedRows.length} config records`}
                </b>
              </Text>
            ) : null}
          </div>
        </div>
      }
      footerSlot={
        <ButtonGroup>
          <Button variant="primary" type="submit" size="small" onClick={handleDeleteRebooking}>
            Delete
          </Button>
          <Button variant="secondary" onClick={onCloseDeleteModal} size="small">
            Cancel
          </Button>
        </ButtonGroup>
      }
    ></Modal>
  )
}
