import { useQuery, UseQueryResult } from 'react-query'
import { fetchDeclineReasons } from '../../services/RebookingService'

export interface DeclineReasonResponse {
  code: string
  description: string
  rebookingType: string
  allowExtraInfo: boolean
}

export const useDeclineReasons = (): UseQueryResult<DeclineReasonResponse[], any> => {
  return useQuery(['declineReasons'], fetchDeclineReasons, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
