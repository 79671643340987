import { Button, Icon, Tab, TabGroup, Text } from '@nike/eds'
import useQueryState from '../../../common/hooks/useQueryState'
import { useRebookingReasonConfigTable } from './rebookingreasonconfigtable/RebookingReasonConfigTable'
import { TableLayout } from '../../../common/components/table'
import { DeleteModal } from './deletemodal/DeleteModal'
import { useEffect, useMemo, useState } from 'react'
import { RebookingReasonModal } from './rebookingreasonmodal/RebookingReasonModal'
import { useReasons } from '../../../common/hooks/useReasons'
import useRoleContext from '../../../common/hooks/useRoleContext'
import { Navigate } from 'react-router-dom'

const pageSizeOptions = [10, 25, 50, 100, 200, 500]
const parties = ['NIKE', 'CARRIER', 'CUSTOMER', 'LINEHAUL']

const RebookingReason = () => {
  const [rebookingReasonConfigModalOpen, setRebookingReasonConfigModalOpen] =
    useState<boolean>(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const { state: queryState, toggleSortBy } = useQueryState<any>({
    sortBy: [{ id: 'lastUpdatedOn', desc: false }],
  })
  const [selectedResponsibleParty, setSelectedResponsibleParty] = useState('NIKE')
  const {
    data: reasons,
    isSuccess: isReasonsCallSuccess,
    isFetching,
    isError,
    isSuccess,
    refetch,
  } = useReasons()

  const filteredReasons = useMemo(() => {
    return isReasonsCallSuccess
      ? reasons?.filter(reason => reason.responsibleParty === selectedResponsibleParty)
      : []
  }, [reasons, selectedResponsibleParty, isReasonsCallSuccess])

  useEffect(() => {
    refetch()
  }, [selectedResponsibleParty, refetch])

  const tableInstance = useRebookingReasonConfigTable(
    queryState,
    toggleSortBy,
    isFetching,
    isSuccess,
    isError,
    { data: filteredReasons }
  )

  const handleCloseRebookingReasonConfigModalForm = () => {
    setRebookingReasonConfigModalOpen(false)
  }

  const handleCloseDeleteModalForm = () => {
    setDeleteModalOpen(false)
  }

  const hasRebookingReasonsConfigAccess = useRoleContext().hasRebookingReasonConfigAccess()

  if (!hasRebookingReasonsConfigAccess) {
    // no rebooking reasons access - redirect to root and let the router handle the correct redirect
    return <Navigate to="/" replace />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--eds-space-8)',
        }}
      >
        <div
          className="flex-column flex-nowrap content-space-between justify-items-left"
          style={{ padding: 'var(--eds-space-0) var(--eds-space-16)' }}
        >
          <div
            className="flex-row flex-nowrap content-space-between justify-items-center"
            style={{ padding: 'var(--eds-space-16) var(--eds-space-4)' }}
          >
            <div>
              <Button
                size="medium"
                style={{
                  marginRight: 'var(--eds-space-8)',
                  padding:
                    'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                }}
                beforeSlot={<Icon name="Edit" size="m" />}
                onClick={() => setRebookingReasonConfigModalOpen(true)}
                disabled={tableInstance.selectedFlatRows.length !== 1}
              >
                Edit
              </Button>

              <Button
                size="medium"
                style={{
                  marginRight: 'var(--eds-space-8)',
                  padding:
                    'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                }}
                beforeSlot={<Icon name="Delete" size="m" />}
                onClick={() => setDeleteModalOpen(true)}
                disabled={tableInstance.selectedFlatRows.length === 0}
              >
                Delete
              </Button>
            </div>

            <div className="flex-row justify-items-center">
              <Button
                variant="primary"
                className="Button"
                style={{
                  paddingLeft: 'var(--eds-space-16)',
                  padding:
                    'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                }}
                beforeSlot={<Icon name="Plus" size="m" />}
                onClick={() => setRebookingReasonConfigModalOpen(true)}
                disabled={tableInstance.selectedFlatRows.length > 0}
              >
                New
              </Button>
            </div>
          </div>

          <TabGroup
            name="tab-button-group"
            activeId={selectedResponsibleParty}
            onChange={e => setSelectedResponsibleParty(e.target.id)}
            variant={undefined}
            style={{ marginLeft: 'var(--eds-space-16)' }}
          >
            {parties.map(party => {
              return (
                <Tab id={party} key="tab-1">
                  <Text>{party.charAt(0) + party.slice(1).toLowerCase()}</Text>
                </Tab>
              )
            })}
          </TabGroup>

          <TableLayout
            {...{ ...tableInstance, pageSizeOptions, isFetching, isError }}
            queryState={queryState}
            className="-striped -highlight"
            refetchData={refetch}
            withPagination={false}
            noDataMessage={'No data found. Create a record or refresh the table later'}
          />
        </div>
        <RebookingReasonModal
          rebookingReasonModalOpen={rebookingReasonConfigModalOpen}
          onCloseRebookingReasonModal={handleCloseRebookingReasonConfigModalForm}
          selectedRow={tableInstance.selectedFlatRows[0]?.original}
          refetchData={refetch}
          selectedResponsibleParty={selectedResponsibleParty}
        />
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          onCloseDeleteModal={handleCloseDeleteModalForm}
          selectedRows={tableInstance.selectedFlatRows}
          refetchData={refetch}
        />
      </div>
    </>
  )
}

export default RebookingReason
