export const mapStringToArrayOrUndefined = value =>
  value
    ? value
        .trim()
        .split(/[\s:,;\n]+/)
        .map(s => s.trim())
    : undefined

export const valuesAreAllNumbers = input => {
  const values = mapStringToArrayOrUndefined(input)

  if (values) {
    for (let value of values) {
      if (isNaN(value)) {
        return false
      }
    }
  }
  return true
}
