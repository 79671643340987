import { QueryState } from './useQueryState'
import {
  ExportShipmentReportId,
  ExportShipmentReportTask,
  fetchShipmentReportExportTask,
  shipmentReportExportTask,
} from '../../services/ShipmentService'
import { useQuery, UseQueryResult } from 'react-query'
import { addMilliseconds, isWithinInterval } from 'date-fns'
import { RebookingInitiateFilter, RebookingApproveFilter } from '../../services/ShipmentService'
import { ReportType } from '../utils/ReportType'

const createShipmentReportKey = {
  all: ['createShipmentReportKey'],
  lists: ['createShipmentReportKey', 'list'],
  list: (queryState, reportType) => [...createShipmentReportKey.lists, queryState, reportType],
}

const downloadShipmentReportKey = {
  all: ['downloadShipmentReportKey'],
  lists: ['downloadShipmentReportKey', 'list'],
  list: exportShipmentsResult => [...downloadShipmentReportKey.lists, exportShipmentsResult],
}

export const useCreateShipmentReport = (
  queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>,
  isDownloading: boolean,
  setIsDownloading: (data: boolean) => void,
  reportType: ReportType
): UseQueryResult<ExportShipmentReportId> => {
  return useQuery(
    createShipmentReportKey.list(queryState, reportType),
    () => shipmentReportExportTask(queryState, reportType),
    {
      refetchOnWindowFocus: false,
      enabled: isDownloading,
      onError: () => {
        setIsDownloading(false)
      },
    }
  )
}

export const exportTimeout: number = 5 * 60 * 1000 // 5 minutes timeout

export const useDownloadShipmentReport = (
  queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>,
  isDownloading: boolean,
  setIsDownloading: (data: boolean) => void,
  startTime: Date | undefined,
  reportType: ReportType
): UseQueryResult<ExportShipmentReportTask> => {
  const refetchInterval: number = 2000
  const { data: shipmentReportData, isSuccess } = useCreateShipmentReport(
    queryState,
    isDownloading,
    setIsDownloading,
    reportType
  )

  const isTimedOut = (): boolean => {
    const now: Date = new Date()
    const endTime: Date | undefined = startTime
      ? addMilliseconds(startTime, exportTimeout)
      : undefined
    return endTime && startTime ? !isWithinInterval(now, { start: startTime, end: endTime }) : true
  }

  return useQuery(
    downloadShipmentReportKey.list(shipmentReportData?.shipmentReportId),
    () => fetchShipmentReportExportTask(shipmentReportData?.shipmentReportId),
    {
      refetchOnWindowFocus: false,
      refetchInterval: refetchInterval,
      enabled: isSuccess && isDownloading,
      onSuccess: data => {
        if (data.status === 'READY') {
          const a: HTMLAnchorElement = document.createElement('a')
          a.href = data.downloadUrl
          a.download = `shipment-report-${Date.now()}.xlsx`
          document.body.appendChild(a)
          a.click()
          a.remove()
          setIsDownloading(false)
        }
        if (data.status !== 'READY') {
          const isTimeout: boolean = isTimedOut()
          if (isTimeout) {
            setIsDownloading(false)
          }
        }
      },
      onError: () => {
        setIsDownloading(false)
      },
    }
  )
}
