import React, { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import { Outlet } from 'react-router-dom'
import { Text } from '@nike/eds'

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin)
      oktaAuth.setOriginalUri(originalUri)
      console.log(`Sign in with redirect to ${originalUri}`)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, authState])

  if (!authState?.isAuthenticated) {
    return (
      <div className="eds-spacing--m-16">
        <Text as="h3">Get ready to sign-in...</Text>
      </div>
    )
  }

  return <Outlet />
}
