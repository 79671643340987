import { useNavigate, useLocation } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { getOktaAuth } from './config/Okta'
import { Layout } from './pages/layout/index'
import './common/styles/App.css'
import { useEffect } from 'react'
import { logUsage } from './services/ShipmentService'
import AppRoutes from './pages/AppRoutes'
import { NotificationbarProvider } from './pages/notification/NotificationbarProvider'
import { RebookingRequestTracker } from './common/components/deliveryview-ws/RebookingRequestTracker'
import { ErrorBoundary } from './common/components/error/Error'

const App: React.FC = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) =>
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })

  useEffect(() => {
    const page = location.pathname.startsWith('/')
      ? location.pathname.split('/')[1]
      : location.pathname
    page && logUsage(page, 'load')
  }, [location])

  return (
    <Security oktaAuth={getOktaAuth()} restoreOriginalUri={restoreOriginalUri}>
      <Layout>
        <ErrorBoundary key={location.key}>
          <NotificationbarProvider maxNotifications={2}>
            <RebookingRequestTracker>
              <AppRoutes />
            </RebookingRequestTracker>
          </NotificationbarProvider>
        </ErrorBoundary>
      </Layout>
    </Security>
  )
}

export default App
