import React from 'react'
import { TextField as EdsTextField } from '@nike/eds'
import { useController, UseControllerProps } from 'react-hook-form'

export interface TextFieldProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof EdsTextField>,
      'id' | 'name' | 'value' | 'defaultValue' | 'onChange' | 'onBlur'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
}

export const TextField = ({
  name,
  control,
  type = 'text',
  label,
  rules = undefined,
  ...rest
}: TextFieldProps) => {
  const {
    field: { onChange, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <EdsTextField
      id={name}
      {...fieldProps}
      onChange={e => onChange(e.target.value)}
      label={label}
      type={type}
      hasErrors={!!error}
      errorMessage={error?.message}
      {...rest}
    />
  )
}
