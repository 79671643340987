import './TooltipRebookingContent.css'
import { date } from '../../utils/datautils/formatter'

export const TooltipRebookingContent = ({ type, data }) => {
  const {
    responsiblePartyCode,
    reasonCode,
    iDPPlannedTimestamp,
    plannedDeliveryFromTime,
    plannedDeliveryToTime,
    shipTo,
    deliveryInstructionText,
    alignedWithCarrierIndicator,
    expectedMonthOfDelivery,
    creationUser,
    creationDateTime,
    validationUser,
    validatedDateTime,
    declineReasonCode,
    extraInfo,
  } = data
  const { addressLine1, addressLine2, addressLine3, city, code, zipCode } = shipTo || {}
  const typeData = type === 'REBOOKING' || type === 'HOLD'
  const statusData = type === 'DECLINED' || type === 'APPROVED'
  return (
    <table className="tooltip-rebooking-content">
      <thead>
        <th colSpan={2}>
          <td>{type} Details</td>
        </th>
      </thead>
      <tbody>
        {responsiblePartyCode && typeData && (
          <tr>
            <td>Responsible Party</td>
            <td>{responsiblePartyCode}</td>
          </tr>
        )}
        {reasonCode && typeData && (
          <tr>
            <td>Reason</td>
            <td>{reasonCode}</td>
          </tr>
        )}
        {iDPPlannedTimestamp && typeData && (
          <tr>
            <td>New IDP Date</td>
            <td>{date(iDPPlannedTimestamp, false, true)}</td>
          </tr>
        )}
        {plannedDeliveryFromTime && plannedDeliveryToTime && typeData && (
          <tr>
            <td>New IDP Time Window</td>
            <td>
              {plannedDeliveryFromTime} - {plannedDeliveryToTime}
            </td>
          </tr>
        )}
        {code && typeData && (
          <tr>
            <td>New Ship To Code</td>
            <td>{code}</td>
          </tr>
        )}
        {addressLine1 && typeData && (
          <tr>
            <td>New Address</td>
            <td>
              {addressLine1} {addressLine2} {addressLine3}
            </td>
          </tr>
        )}
        {zipCode && typeData && (
          <tr>
            <td>New Zip Code</td>
            <td>{zipCode}</td>
          </tr>
        )}
        {city && typeData && (
          <tr>
            <td>New City</td>
            <td>{city}</td>
          </tr>
        )}
        {deliveryInstructionText && typeData && (
          <tr>
            <td>New Traffic Instructions</td>
            <td>{deliveryInstructionText}</td>
          </tr>
        )}
        {expectedMonthOfDelivery && typeData && (
          <tr>
            <td>Expected Month of Delivery</td>
            <td>{expectedMonthOfDelivery}</td>
          </tr>
        )}
        {typeData && (
          <tr>
            <td>Aligned with Carrier</td>
            <td>{alignedWithCarrierIndicator ? 'Yes' : 'No'}</td>
          </tr>
        )}
        {creationUser && typeData && (
          <tr>
            <td>Requestor User</td>
            <td>{creationUser}</td>
          </tr>
        )}
        {creationDateTime && typeData && (
          <tr>
            <td>Rebooking Creation Date Time</td>
            <td>{date(creationDateTime, true)}</td>
          </tr>
        )}
        {declineReasonCode && statusData && (
          <tr>
            <td>Decline Reason Code</td>
            <td>{declineReasonCode}</td>
          </tr>
        )}
        {extraInfo && statusData && (
          <tr>
            <td>Extra Info</td>
            <td>{extraInfo}</td>
          </tr>
        )}
        {validationUser && statusData && (
          <tr>
            <td>Validator User</td>
            <td>{validationUser}</td>
          </tr>
        )}
        {validatedDateTime && statusData && (
          <tr>
            <td>Validation Date Time</td>
            <td>{date(validatedDateTime, true)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default TooltipRebookingContent
