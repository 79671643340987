import { forwardRef, useCallback, useEffect, useRef } from 'react'
import {
  actions,
  ensurePluginOrder,
  Hooks,
  makePropGetter,
  TableToggleRowsSelectedProps,
  useGetLatest,
} from 'react-table'

const pluginName = 'useAllServerRowsSelect'

// Actions
actions.toggleAllServerRowsSelected = 'toggleAllServerRowsSelected'

export const useAllServerRowsSelect = <D extends object = {}>(hooks: Hooks<D>): void => {
  hooks.getToggleAllServerRowsSelectedProps = [defaultGetToggleAllServerRowsSelectedProps]
  hooks.stateReducers.push(reducer)
  hooks.useInstance.push(useInstance)
}

const defaultGetToggleAllServerRowsSelectedProps = (props, { instance }) => [
  props,
  {
    onChange: e => {
      instance.toggleAllServerRowsSelected(e.target.checked)
    },
    style: {
      cursor: 'pointer',
    },
    checked: instance.state.isAllServerRowsSelected,
    title: 'Toggle All Server rows that match criterias',
    indeterminate: Boolean(
      !instance.state.isAllServerRowsSelected && Object.keys(instance.state.selectedRowIds).length
    ),
  },
]

useAllServerRowsSelect.pluginName = pluginName

function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      isAllServerRowsSelected: false,
    }
  }
  if (action.type === actions.toggleRowSelected) {
    /*
     * Hook in useRowSelect plugin to reset isAllServerRowsSelected when:
     *  - a single row is toggled (selected or unselected)
     */
    return {
      ...state,
      isAllServerRowsSelected: instance.initialState.isAllServerRowsSelected || false,
    }
  }
  if (action.type === actions.resetSelectedRows) {
    /*
     * Hook in useRowSelect plugin to reset isAllServerRowsSelected to previous state or initialState when:
     *  - select rows are reset
     * This is also called when data for a new page is fetched. That is why we need to get the previousState eventually.
     */
    const isAllServerRowsSelected =
      previousState.isAllServerRowsSelected ||
      instance.initialState.isAllServerRowsSelected ||
      false
    const selectedRowIds = instance.initialState.selectedRowIds || {}

    if (isAllServerRowsSelected) {
      const { rowsById, nonGroupedRowsById = rowsById } = instance

      // Re-select all new visible rows if isAllServerRowsSelected is checked
      Object.keys(nonGroupedRowsById).forEach(rowId => {
        selectedRowIds[rowId] = true
      })
    }

    return {
      ...state,
      selectedRowIds,
      isAllServerRowsSelected,
    }
  }
  if (action.type === actions.toggleAllServerRowsSelected) {
    const { value: setSelected } = action
    const { rowsById, nonGroupedRowsById = rowsById } = instance

    const selectAll =
      typeof setSelected !== 'undefined' ? setSelected : !state.isAllServerRowsSelected

    // Only remove/add the rows that are visible on the screen
    // Leave all the other rows that are selected alone.
    const selectedRowIds = Object.assign({}, state.selectedRowIds)

    if (selectAll) {
      Object.keys(nonGroupedRowsById).forEach(rowId => {
        selectedRowIds[rowId] = true
      })
    } else {
      Object.keys(nonGroupedRowsById).forEach(rowId => {
        delete selectedRowIds[rowId]
      })
    }

    return {
      ...state,
      selectedRowIds,
      isAllServerRowsSelected: selectAll,
    }
  }

  return state
}

function useInstance(instance) {
  const {
    state: { isAllServerRowsSelected },
    getHooks,
    plugins,
    dispatch,
  } = instance

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination', 'useRowSelect'],
    pluginName
  )

  const toggleAllServerRowsSelected = useCallback(
    value => dispatch({ type: actions.toggleAllServerRowsSelected, value }),
    [dispatch]
  )

  const getInstance = useGetLatest(instance)

  const getToggleAllServerRowsSelectedProps = makePropGetter(
    getHooks().getToggleAllServerRowsSelectedProps,
    { instance: getInstance() }
  )

  // enrich instance
  Object.assign(instance, {
    isAllServerRowsSelected,
    toggleAllServerRowsSelected,
    getToggleAllServerRowsSelectedProps,
  })
}

export interface UseAllServerRowsSelect {
  isAllServerRowsSelected: boolean
  toggleAllServerRowsSelected: (set?: boolean) => void
  getToggleAllServerRowsSelectedProps: (
    props?: Partial<TableToggleRowsSelectedProps>
  ) => TableToggleRowsSelectedProps
}

export const IndeterminateCheckbox = forwardRef(({ indeterminate, checked, ...rest }: any, ref) => {
  const defaultRef = useRef()
  const resolvedRef: any = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.checked = checked
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate, checked])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
})
