import { Snack } from '@nike/eds'
import { useCallback, useContext } from 'react'
import { Message, NotificationContext } from './NotificationContext'

export const Notification = ({ onDismiss, content, status = 'info', ...props }: Message) => {
  const { removeNotification } = useContext(NotificationContext)

  const onDismissWrapper = useCallback(
    (id: string) => {
      removeNotification(id)
      if (onDismiss) onDismiss(id)
    },
    [removeNotification, onDismiss]
  )

  return (
    <Snack onDismiss={onDismissWrapper} status={status} {...props}>
      {content}
    </Snack>
  )
}
