import { useMemo } from 'react'
import { SelectAllCell, SelectAllHeader } from '../../../../common/utils/SelectAllServerRows'
import { useServerDataTable } from '../../../../common/components/table'
import { useAllServerRowsSelect } from '../../../../common/hooks/useAllServerRowsSelect'
import { date } from '../../../../common/utils/datautils/formatter'
import { Icon, Tooltip } from '@nike/eds'
import TooltipAutomaticApprovalCondition from '../tooltipautomaticapprovalcondition/TooltipAutomaticApprovalCondition'

export const useAutomaticApprovalConfigTable = (
  queryState,
  toggleSortBy,
  isFetching,
  isSuccess,
  isError,
  hasEditAccess,
  data
) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        width: 20,
        Header: props => <SelectAllHeader {...props} />,
        Cell: props => <SelectAllCell {...props} />,
      },
      {
        Header: () => <div>Carrier</div>,
        accessor: 'carrier',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Hub</div>,
        accessor: 'hub',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Aligned With Carrier</div>,
        accessor: 'alignedWithCarrierFlagNeeded',
        Cell: item => <div>{item.value === true ? 'Y' : 'N'}</div>,
        width: 60,
      },
      {
        Header: () => <div>Condition</div>,
        accessor: 'condition',
        Cell: item => (
          <div>
            <Tooltip
              bodySlot={<TooltipAutomaticApprovalCondition data={item.row.original} />}
              isDark={true}
              placement={'right'}
              modifiers={[
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 8,
                  },
                },
              ]}
            >
              <div
                className="flex-row flex-nowrap content-center justify-items-center"
                style={{ cursor: 'pointer' }}
              >
                <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                  {item.value && item.value === 'ACTUAL_SHIP_DATE'
                    ? 'Actual Ship Date'
                    : 'Original / New IDP <> Rebooking Date'}
                </div>
                <Icon name="InfoCircle" size="s" />
              </div>
            </Tooltip>
          </div>
        ),
        width: 300,
      },
      {
        Header: () => <div>Created By</div>,
        accessor: 'createdBy',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Created On</div>,
        accessor: 'createdOn',
        Cell: item => <div>{date(item.value, true)}</div>,
        width: 60,
      },
      {
        Header: () => <div>Last Updated By</div>,
        accessor: 'lastUpdatedBy',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Last Updated On</div>,
        accessor: 'lastUpdatedOn',
        Cell: item => <div>{date(item.value, true)}</div>,
        width: 60,
      },
    ],
    []
  )

  if (!hasEditAccess) {
    columns.shift()
  }

  return useServerDataTable({
    queryState,
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
    overrides: {
      toggleSortBy,
    },
    extraPlugins: [useAllServerRowsSelect],
  })
}
