import { useEffect, useRef, useState } from 'react'
import { AuthState } from '@okta/okta-auth-js'

export interface UseWebSocketOptions {
  authState: AuthState | null
}

export const useWebSocket = (url, { authState = null }: UseWebSocketOptions) => {
  const wsRef = useRef<WebSocket | null>(null)
  const retryTimeoutRef = useRef(250)

  const [lastMessage, setLastMessage] = useState<any>()

  useEffect(() => {
    const _connect = () => {
      const ws = new WebSocket(url)
      wsRef.current = ws

      ws.onopen = () => {
        console.log('WebSocket Client Connected')
      }

      ws.onclose = event => {
        console.log('WebSocket Client Disconnected')
        // code = 1000 means normal closure
        if (event.code !== 1000) {
          // calculate a backoff timeout before reconnect
          const timeout = Math.min(10000, (retryTimeoutRef.current += retryTimeoutRef.current))
          console.log(
            `Attempt to reconnect in ${timeout} due to [${event.code}: ${event.reason}]...`
          )
          setTimeout(_connect, timeout)
        }
      }

      ws.onerror = event => {
        console.log('WebSocket Client Error: ' + JSON.stringify(event))
        ws.close(4001) // this is a private code
      }

      ws.onmessage = event => {
        retryTimeoutRef.current = 250
        try {
          const data = JSON.parse(event.data)
          setLastMessage({
            ...event,
            data,
          })
        } catch (err) {
          console.log(err)
        }
      }
    }

    if (
      authState?.isAuthenticated &&
      (!wsRef.current || [WebSocket.CLOSING, WebSocket.CLOSED].includes(wsRef.current?.readyState))
    ) {
      // close the other connection if url has changed
      if (wsRef.current?.url !== url) {
        wsRef.current?.close()
      }
      _connect()
    }
  }, [authState, url])

  return { lastMessage }
}
