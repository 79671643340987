import { useCallback, useMemo, useReducer } from 'react'
import { Notificationbar } from './Notificationbar'
import { Message, NotificationContext } from './NotificationContext'

const messageReducers = (state, action) => {
  switch (action.type) {
    case 'push':
      if (state.find(it => it.id === action.data.id)) {
        console.error('Cannot add a new notification with existing id: ' + action.data.id)
        return state
      }
      return [...state, action.data]
    case 'remove':
      if (state.find(it => it.id === action.id)) {
        return state.filter(it => it.id !== action.id)
      }
      return state
  }
}

export const NotificationbarProvider = ({ children, maxNotifications }) => {
  const [messages, dispatch] = useReducer(messageReducers, [])

  const pushNotification = useCallback(
    (data: Message) => dispatch({ type: 'push', data }),
    [dispatch]
  )
  const removeNotification = useCallback(
    (id: string) => dispatch({ type: 'remove', id }),
    [dispatch]
  )

  const providedValue = useMemo(
    () => ({
      messages,
      pushNotification,
      removeNotification,
    }),
    [messages, pushNotification, removeNotification]
  )

  return (
    <NotificationContext.Provider value={providedValue}>
      <Notificationbar maxNotifications={maxNotifications} />
      {children}
    </NotificationContext.Provider>
  )
}
