import { useQuery, UseQueryResult } from 'react-query'
import { ApplicationSettings, fetchUserPreferences } from '../../services/UserPreferencesService'

export const useFilters = (authState, page): UseQueryResult<ApplicationSettings, any> => {
  return useQuery(['filters'], () => fetchUserPreferences(authState, page, 'filter'), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
