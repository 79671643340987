import { Snackbar } from '@nike/eds'
import { useContext } from 'react'
import { NotificationContext } from './NotificationContext'
import { Notification } from './Notification'

export interface NotificationbarProps {
  maxNotifications: number
}

export const Notificationbar = ({ maxNotifications = 2 }: NotificationbarProps) => {
  const { messages } = useContext(NotificationContext)

  return (
    <Snackbar>
      {messages.length > maxNotifications && (
        <Notification
          key={-1}
          id="-1"
          content={`${messages.length - maxNotifications}+ Notifications`}
          status="warning"
          hideDismiss={true}
        />
      )}
      {messages.slice(0, maxNotifications).map((props, idx) => (
        <Notification key={idx} {...props} />
      ))}
    </Snackbar>
  )
}
