import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { Error, OktaError } from '../common/components/error/Error'
import RebookingInitiate from './initiatepage/RebookingInitiate'
import RebookingApprove from './approvepage/RebookingApprove'
import { LoginCallback } from '@okta/okta-react'
import useRoleContext from './../common/hooks/useRoleContext'
import { RequiredAuth } from '../pages/okta/SecureRoute'
import { Button } from '@nike/eds'
import AutomaticApproval from './settings/automaticapprovalpage/AutomaticApproval'
import RebookingReason from './settings/rebookingreasonconfigpage/RebookingReason'
import RebookingDeclineReason from './settings/rebookingdeclinereasonconfigpage/RebookingDeclineReason'

const AppRoutes: React.FC = (): JSX.Element => {
  const { getRedirectPath } = useRoleContext()
  const redirectPath = getRedirectPath()
  const navigate = useNavigate()
  return (
    <Routes>
      <Route path="/" element={<Navigate to={redirectPath} />} />
      <Route path="initiate" element={<RequiredAuth />}>
        <Route index element={<RebookingInitiate />} />
      </Route>
      <Route path="approve" element={<RequiredAuth />}>
        <Route index element={<RebookingApprove />} />
      </Route>
      <Route path="/settings/automaticApproval" element={<RequiredAuth />}>
        <Route index element={<AutomaticApproval />} />
      </Route>
      <Route path="/settings/rebookingReason" element={<RequiredAuth />}>
        <Route index element={<RebookingReason />} />
      </Route>
      <Route path="/settings/rebookingDeclineReason" element={<RequiredAuth />}>
        <Route index element={<RebookingDeclineReason />} />
      </Route>
      <Route path="implicit/callback" element={<LoginCallback errorComponent={OktaError} />} />
      <Route
        path="*"
        element={
          <Error
            message={'This page does not exist!'}
            actions={<Button onClick={() => navigate('/', { replace: true })}>Back to Home</Button>}
          ></Error>
        }
      />
    </Routes>
  )
}

export default AppRoutes
