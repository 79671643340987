import { useCallback } from 'react'
import {
  PluginHook,
  TableInstance,
  TableOptions,
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { QueryState } from '../hooks/useQueryState'
import { UseAllServerRowsSelect } from './useAllServerRowsSelect'

export interface ServerDataTableOptions<D extends Record<string, unknown>, Filter>
  extends Omit<TableOptions<D>, 'data'> {
  isSuccess: boolean
  overrides: Partial<TableInstance<D>>
  queryState: QueryState<Filter>
  data: {
    totalPages: number
    totalResources: number
    data: D[]
  }
  extraPlugins?: Array<PluginHook<D>>
}

interface ServerDataTableInstance<D extends Record<string, unknown>>
  extends TableInstance<D>,
    Partial<UseAllServerRowsSelect> {
  totalResources: number
}

const emptyData = []

const useServerDataTable = <D extends Record<string, unknown>, F>({
  columns,
  isSuccess,
  data,
  // used to override some tableInstance properties
  overrides = {},
  // used to add extra plugins
  extraPlugins = [],
  // other extra properties
  queryState,
}: ServerDataTableOptions<D, F>): ServerDataTableInstance<D> => {
  // The state is used to provide utilities, as we control the pagination, we need to control the table state too
  // see https://react-table.tanstack.com/docs/faq#how-can-i-manually-control-the-table-state
  // NOTE: this gives a false ESLint react-hooks/exhaustive-deps warning
  const useControlledState = useCallback(
    state => {
      return {
        ...state,
        pageIndex: queryState.pageNumber,
        pageSize: queryState.pageSize,
        sortBy: queryState.sortBy,
      }
    },
    [queryState]
  )
  // Use the state and functions returned from useTable to build your UI
  const tableInstance = useTable<D>(
    {
      columns,
      data: isSuccess ? data.data : emptyData,
      manualPagination: true,
      manualSortBy: true,
      useControlledState,
      pageCount: isSuccess ? data.totalPages : 0,
      totalResources: isSuccess ? data.totalResources : 0,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    ...extraPlugins
  )

  return {
    ...tableInstance,
    ...overrides,
    totalResources: isSuccess ? data.totalResources : 0,
  }
}

export default useServerDataTable
