import { Button, ButtonGroup, Modal, Text } from '@nike/eds'
import './AutoApprovalRulesModal.css'
import image from './auto-approval-logic.png'

export interface AutoApprovalRulesModalProps {
  autoApprovalRulesModalOpen: boolean
  onCloseAutoApprovalRulesModal: () => void
}

export const AutoApprovalRulesModal = ({
  autoApprovalRulesModalOpen = false,
  onCloseAutoApprovalRulesModal,
}: AutoApprovalRulesModalProps): JSX.Element => {
  return (
    <>
      <Modal
        disableFocusTrap={true}
        hideFade={true}
        isOpen={autoApprovalRulesModalOpen}
        onDismiss={onCloseAutoApprovalRulesModal}
        headerSlot={<Text font="title-3">{'Auto Approval Rules'}</Text>}
        footerSlot={
          <ButtonGroup>
            <Button variant="secondary" onClick={onCloseAutoApprovalRulesModal} size="small">
              Close
            </Button>
          </ButtonGroup>
        }
      >
        <img src={image} alt="Auto Approval Rules" sizes="152x152" />
      </Modal>
    </>
  )
}
