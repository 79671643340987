import React, { useState } from 'react'
import { Button, ButtonGroup, Link, Text, TextGroup } from '@nike/eds'
import { useNavigate } from 'react-router-dom'

export interface ErrorProps {
  title?: React.ReactNode
  message?: React.ReactNode
  error?: Error
  actions?: React.ReactNode
}

export const Error = ({ title, message, error, actions }: ErrorProps) => {
  const [showTechnicalDetails, setShowTechnicalDetails] = useState(false)
  const headerText = title ?? 'Woops!'
  const msg = message ?? ''
  return (
    <TextGroup
      className="flex-column content-center justify-items-center eds-spacing--my-56"
      style={{ textAlign: 'center' }}
    >
      <Text font="title-4" as="h4">
        {headerText}
      </Text>
      <Text font="subtitle-1" color="secondary" as="p">
        {msg}
      </Text>
      {error?.message && (
        <Link
          font="subtitle-1"
          as="p"
          onClick={() => {
            setShowTechnicalDetails(!showTechnicalDetails)
          }}
        >
          {showTechnicalDetails ? 'Close technical details' : 'See technical details'}
        </Link>
      )}

      {showTechnicalDetails && (
        <Text
          font="subtitle-1"
          color="secondary"
          as="p"
          style={{ border: '1px solid black', padding: 'var(--eds-space-16)' }}
        >
          Technical details: {error?.message}
        </Text>
      )}

      <Text font="subtitle-1" color="secondary" as="p">
        <Link href="https://confluence.nike.com/display/EMEAS2C/How+to+get+access">
          How to request application access
        </Link>
      </Text>
      <Text font="subtitle-1" color="secondary" as="p">
        <Link href="https://confluence.nike.com/display/EMEAS2C/AD+security+groups">
          Overview of security groups
        </Link>
      </Text>
      {actions && (
        <ButtonGroup style={{ justifyContent: 'center' }} className="flex-row eds-spacing--mt-40">
          {actions}
        </ButtonGroup>
      )}
    </TextGroup>
  )
}

export const OktaError = ({ error }) => {
  const navigate = useNavigate()
  return (
    <Error
      message="Unable to login. Try logging in again. If the issue persists, check if you have the correct rights with your manager."
      error={error}
      actions={<Button onClick={() => navigate('/', { replace: true })}>Retry</Button>}
    />
  )
}

export class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error while rendering', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Error message="Something went wrong while rendering. Please contact support!" />
    }

    return this.props.children
  }
}
