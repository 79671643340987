import { Button, ButtonGroup, Modal, Radio, Spinner, Text } from '@nike/eds'
import { useForm, useWatch } from 'react-hook-form'
import { Autocomplete, InputGroup, RadioGroup, TextField } from '../../../../common/components/form'
import { Checkbox as CustomCheckbox } from '../../../../common/components/form/Checkbox'
import './AutomaticApprovalModal.css'
import { useAllCarrierHubList } from '../../../../common/hooks/useCarrierHubList'
import { CarrierHubTreeDropdown } from '../../../../common/components/carrierhubtreedropdown/CarrierHubTreeDropdown'
import { useEffect } from 'react'
import {
  postAutoRebookingApprovalConfig,
  updateAutoRebookingApprovalConfig,
} from '../../../../services/RebookingService'
import { logUsage } from '../../../../services/ShipmentService'

export interface Condition {
  code: string
  description: string
}

const conditions: Condition[] = [
  { code: 'ACTUAL_SHIP_DATE', description: 'Actual Ship Date' },
  { code: 'ORIGINAL_OR_IDP', description: 'Original / New Idp <> Rebooking Date' },
]

interface AutomaticApprovalConfigModel {
  carrierHubs: any
  alignedWithCarrierFlagNeeded: boolean
  deadlineTimeOfDay: string | undefined
  condition: Condition
  actualShipDateCondition?: string | undefined
  minDaysBeforeIDPPassed?: number | undefined
  minDaysBeforeIDPNotPassed?: number | undefined
}

const defaultValues: AutomaticApprovalConfigModel = {
  carrierHubs: [],
  alignedWithCarrierFlagNeeded: false,
  deadlineTimeOfDay: '',
  condition: { code: '', description: '' },
  actualShipDateCondition: 'ACTUAL_SHIP_DATE_BLANK_CHECK',
  minDaysBeforeIDPPassed: 0,
  minDaysBeforeIDPNotPassed: 0,
}

export interface AutomaticApprovalModalProps {
  automaticApprovalModalOpen: boolean
  onCloseAutomaticApprovalModal: () => void
  selectedRow: any
  refetchData: () => void
  tableData: any
}

export const AutomaticApprovalModal = ({
  automaticApprovalModalOpen = false,
  onCloseAutomaticApprovalModal,
  selectedRow,
  refetchData,
  tableData,
}: AutomaticApprovalModalProps): JSX.Element => {
  const {
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    setValue,
    resetField,
    reset,
  } = useForm({
    defaultValues: defaultValues,
  })
  const selectedCarrierHubs = useWatch({ name: 'carrierHubs', control })
  const condition = useWatch({ name: 'condition', control })
  const { data: carrierHubs, isSuccess: isAllCarrierHubCallSuccess } = useAllCarrierHubList()

  const filterCarrierHubs = carrierHubs => {
    if (tableData) {
      const configuredCarrierHubs = tableData.map(tableRecord => ({
        carrier: tableRecord.carrier,
        hub: tableRecord.hub,
      }))

      return carrierHubs.filter(
        value =>
          !configuredCarrierHubs.some(
            configuredCarrierHub =>
              configuredCarrierHub.carrier === value.carrierAccountCode &&
              configuredCarrierHub.hub === value.hubCode
          )
      )
    } else {
      return carrierHubs
    }
  }

  //only enable form submit if no validation errors are present
  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  const mapDataForUpdateConfig = data => {
    return data.condition.code === 'ACTUAL_SHIP_DATE'
      ? {
          alignedWithCarrierFlagNeeded: data.alignedWithCarrierFlagNeeded,
          deadlineTimeOfDay: data.deadlineTimeOfDay,
          condition: 'ACTUAL_SHIP_DATE',
          actualShipDateCondition: data.actualShipDateCondition,
        }
      : {
          alignedWithCarrierFlagNeeded: data.alignedWithCarrierFlagNeeded,
          deadlineTimeOfDay: data.deadlineTimeOfDay,
          condition: 'ORIGINAL_OR_IDP',
          minDaysBeforeIDPPassed: data.minDaysBeforeIDPPassed,
          minDaysBeforeIDPNotPassed: data.minDaysBeforeIDPNotPassed,
        }
  }

  const handleAutomaticApprovalConfig = async data => {
    if (selectedRow) {
      await updateAutoRebookingApprovalConfig(
        selectedRow.carrier,
        selectedRow.hub,
        mapDataForUpdateConfig(data)
      )
      logUsage('automaticApproval', 'update_automatic_approval')
    } else {
      for (const carrierHub of data.carrierHubs) {
        await postAutoRebookingApprovalConfig({
          carrier: carrierHub.carrierAccountCode,
          hub: carrierHub.hubCode,
          ...mapDataForUpdateConfig(data),
        })
      }
      logUsage('automaticApproval', 'post_automatic_approval')
    }

    // due to react nature, in this javascript closure, isSubmitting is still false
    onCloseAutomaticApprovalModal()
    refetchData()
  }

  useEffect(() => {
    reset()
    if (selectedRow) {
      setValue('carrierHubs', [
        {
          carrierAccountCode: selectedRow.carrier,
          hubCode: selectedRow.hub,
        },
      ])

      setValue('alignedWithCarrierFlagNeeded', selectedRow.alignedWithCarrierFlagNeeded)
      setValue('deadlineTimeOfDay', selectedRow.deadlineTimeOfDay)
      setValue('condition', {
        code: selectedRow.condition,
        description: conditions.filter(c => c.code === selectedRow.condition)[0].description,
      })
      if (selectedRow.condition === 'ACTUAL_SHIP_DATE') {
        setValue('actualShipDateCondition', selectedRow.actualShipDateCondition)
      } else if (selectedRow.condition === 'ORIGINAL_OR_IDP') {
        setValue('minDaysBeforeIDPNotPassed', selectedRow.minDaysBeforeIDPNotPassed)
        setValue('minDaysBeforeIDPPassed', selectedRow.minDaysBeforeIDPPassed)
      }
    }
  }, [selectedRow, setValue, reset, resetField])

  const closeAndResetFilters = () => {
    if (!isSubmitting && !selectedRow) {
      reset()
    }
    onCloseAutomaticApprovalModal()
  }

  const formId = 'automaticApprovalModalForm'
  return (
    <>
      <Modal
        disableFocusTrap={true}
        hideFade={true}
        isOpen={automaticApprovalModalOpen}
        onDismiss={closeAndResetFilters}
        headerSlot={<Text font="title-3">{'Configure Automatic Rebooking Approval'}</Text>}
        footerSlot={
          <ButtonGroup>
            <Button
              variant="primary"
              type="submit"
              form={formId}
              size="small"
              disabled={isSubmitting || !canSubmit() || selectedCarrierHubs.length === 0}
            >
              {isSubmitting && <Spinner />}
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="secondary"
              onClick={closeAndResetFilters}
              size="small"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <form
          id={formId}
          className="filter-form"
          onSubmit={handleSubmit(handleAutomaticApprovalConfig)}
        >
          <div
            className="flex-column content-space-between justify-items-start"
            style={{ gap: 'var(--eds-space-16)' }}
          >
            <InputGroup label="Carrier / Hub" clearable={false} withDivider={false}>
              <CarrierHubTreeDropdown
                name="carrierHubs"
                control={control}
                carrierHubs={
                  isAllCarrierHubCallSuccess && carrierHubs
                    ? selectedRow
                      ? carrierHubs
                      : filterCarrierHubs(carrierHubs)
                    : []
                }
                showPartiallySelected={true}
                disabled={selectedRow}
              />
            </InputGroup>

            <CustomCheckbox
              name="alignedWithCarrierFlagNeeded"
              label={`Automatic Approval when the rebooking is aligned with the carrier`}
              control={control}
              value={
                selectedRow
                  ? selectedRow.alignedWithCarrierFlagNeeded
                  : defaultValues.alignedWithCarrierFlagNeeded
              }
            />
            <div
              className="flex-row content-start justify-items-center"
              style={{ alignSelf: 'stretch' }}
            >
              <InputGroup label="Condition" clearable={false} withDivider={false}>
                <Autocomplete
                  name={'condition'}
                  control={control}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  options={conditions}
                  getOptionLabel={option => option.description}
                  renderOption={(props, option) => <li {...props}>{option?.description}</li>}
                  rules={{
                    required: {
                      value: true,
                      message: 'This field is required!',
                    },
                    validate: condition => condition.code !== '' || 'This field is required!',
                  }}
                  style={{ width: '360px', marginRight: '100px' }}
                />
              </InputGroup>
              <InputGroup label="Deadline Automatic Approval" clearable={false} withDivider={false}>
                <TextField
                  name={'deadlineTimeOfDay'}
                  control={control}
                  type="time"
                  label=""
                  placeholder=""
                  rules={{ required: { value: true, message: 'This field is required!' } }}
                />
              </InputGroup>
            </div>
            {condition &&
              (condition.code === 'ACTUAL_SHIP_DATE' || condition.code === 'ORIGINAL_OR_IDP') && (
                <Text font="subtitle-1">Automatic Approval when:</Text>
              )}
            {condition && condition.code === 'ACTUAL_SHIP_DATE' && (
              <RadioGroup
                control={control}
                label={''}
                name={'actualShipDateCondition'}
                style={{ marginTop: '0px' }}
                rules={{ required: { value: true, message: 'This field is required!' } }}
              >
                <Radio
                  label={'Actual Shipped Date is blank'}
                  value={'ACTUAL_SHIP_DATE_BLANK_CHECK'}
                  id={`radio-shipDateBlank`}
                />
                <Radio
                  label={
                    'Actual Shipped Date is blank and PGI >= Today OR Actual Shipped Date is not blank, then Actual Shipped Date = Today'
                  }
                  value={'PGI_ACTUAL_SHIP_DATE_CHECK'}
                  id={`radio-shipDateNotBlank`}
                />
              </RadioGroup>
            )}
            {condition && condition.code === 'ORIGINAL_OR_IDP' && (
              <>
                <Text font="subtitle-2">
                  {'Planned IDP date passed (=planned IDP date < rebooking creation date)'}
                </Text>
                <div className="flex-row justify-items-center">
                  <Text font="body-3">{'new IDP date >='}</Text>
                  <TextField
                    name={'minDaysBeforeIDPPassed'}
                    control={control}
                    type="number"
                    label=""
                    placeholder=""
                    min={0}
                    style={{
                      width: '28%',
                      padding: '0px 0px 0px var(--eds-space-8)',
                      alignContent: 'center',
                      marginLeft: 'var(--eds-space-8)',
                    }}
                    rules={{ required: { value: true, message: 'This field is required!' } }}
                  />

                  <Text font="body-3" style={{ marginLeft: '-120px' }}>
                    {'business day(s) from rebooking request date'}
                  </Text>
                </div>

                <Text font="subtitle-2">
                  {'Planned IDP date not passed (=planned IDP date >= rebooking creation date)'}
                </Text>
                <div className="flex-row justify-items-center">
                  <Text font="body-3">{'planned IDP date & new IDP date >='}</Text>
                  <TextField
                    name={'minDaysBeforeIDPNotPassed'}
                    control={control}
                    type="number"
                    label=""
                    placeholder=""
                    min={0}
                    style={{
                      width: '28%',
                      padding: '0px 0px 0px var(--eds-space-8)',
                      alignContent: 'center',
                      marginLeft: 'var(--eds-space-8)',
                    }}
                    rules={{
                      required: { value: true, message: 'This field is required!' },
                    }}
                  />

                  <Text font="body-3" style={{ marginLeft: '-120px' }}>
                    {'business day(s) from rebooking request date'}
                  </Text>
                </div>
              </>
            )}
          </div>
        </form>
      </Modal>
    </>
  )
}
