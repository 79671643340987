import { Box, Button, ButtonGroup, Modal, Spinner, StatusIcon, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import { useContext } from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import { useForm, useWatch } from 'react-hook-form'
import {
  approveDeclineRebooking,
  getSelectedShipmentReferences,
} from '../../../services/RebookingService'
import { logUsage, RebookingApproveFilter } from '../../../services/ShipmentService'
import {
  RebookingRequestTrackerContext,
  RebookingRequestType,
} from '../../../common/components/deliveryview-ws/RebookingRequestTracker'
import { Autocomplete, TextField } from '../../../common/components/form'
import { DeclineReasonResponse, useDeclineReasons } from '../../../common/hooks/useDeclineReasons'
import { ReasonResponse } from '../../../common/hooks/useReasons'
import './DeclineModal.css'

export interface DeclineRebookingFormData {
  reason: DeclineReasonResponse
  allowExtraInfo: boolean
  extraInfo: string
  approvalType: string
}

const emptyFormData = {
  reason: null,
  allowExtraInfo: false,
  extraInfo: undefined,
  approvalType: '',
  axios: null,
}

interface DeclineModalProps {
  declineModalOpen: boolean
  onCloseRebookingModal: () => void
  selectedRows: any
  isAllRowsSelected: boolean
  totalResources?: number
  filter: RebookingApproveFilter
  openRebookingCreationDateTime: Date | undefined
}

export const DeclineModal = ({
  declineModalOpen = false,
  onCloseRebookingModal,
  selectedRows,
  isAllRowsSelected,
  totalResources = 0,
  filter,
  openRebookingCreationDateTime,
}: DeclineModalProps): JSX.Element => {
  const {
    control,
    formState: { isDirty, isValid, isSubmitting, errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: emptyFormData,
  })
  const { authState } = useOktaAuth()
  const { data: declineReasons, isSuccess: isDeclineReasonsFetched } = useDeclineReasons()
  const reason = useWatch({ name: 'reason', control })
  const rebookingType = selectedRows[0]?.original.rebookingTypeData.type

  let reasons = [] as DeclineReasonResponse[]
  if (isDeclineReasonsFetched) {
    declineReasons?.filter(reason => reason.rebookingType === rebookingType).forEach(c => {
      if (reasons.filter(reason => reason.code === c.code).length === 0) {
        reasons.push(c)
      }
    })
    reasons = reasons.sort((a, b) => a.code.localeCompare(b.code))
  }

  const { track } = useContext(RebookingRequestTrackerContext)

  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  const handleDeclineRebooking = async data => {
    try {
      const response = await approveDeclineRebooking(
        getSelectedShipmentReferences(isAllRowsSelected, filter, selectedRows),
        filter,
        data,
        authState?.accessToken?.claims?.sub,
        'DECLINED',
        openRebookingCreationDateTime
      )
      console.log(response)
      track({
        type: RebookingRequestType.VALIDATE,
        requestIds: response.validationRequestIds,
        workingMessage: 'Declining rebookings...',
        successMessage: 'Successfully declined rebookings',
      })
      logUsage('approve', 'decline_rebooking')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Cannot decline rebookings: ' + JSON.stringify(error.toJSON()))
        // try to extract a message from the server
        // @ts-ignore
        const message = error.response?.data?.message
        setError('axios', {
          type: 'server',
          message: message
            ? `Cannot decline rebookings: ${message}`
            : 'Cannot decline rebookings for now. Please retry later or contact support!',
        })
      }
      throw error
    }

    // due to react nature, in this javascript closure, isSubmitting is still false
    closeAndResetFilters()
  }

  const closeAndResetFilters = () => {
    if (!isSubmitting) {
      reset()
      onCloseRebookingModal()
    }
  }

  const numberOfRebookings = isAllRowsSelected ? totalResources : selectedRows.length
  const formId = 'declineRebookingModalForm'

  return (
    <Modal
      disableFocusTrap={true}
      isOpen={declineModalOpen}
      onDismiss={closeAndResetFilters}
      headerSlot={
        <div className="flex-row flex-wrap content-space-between justify-items-center">
          <div>
            <Text font="title-3">Decline a rebooking / hold</Text>
            {numberOfRebookings > 0 ? (
              <Text font="body-3" className="eds-spacing--mt-16 eds-spacing--mb-24">
                <b style={{ color: 'var(--eds-color-red-50)' }}>
                  {`You are about to decline ${numberOfRebookings} rebooking request(s)`}
                </b>
              </Text>
            ) : null}
          </div>
        </div>
      }
      footerSlot={
        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            size="small"
            onClick={() => clearErrors('axios')}
            disabled={isSubmitting || !canSubmit()}
          >
            {isSubmitting && <Spinner />}
            {isSubmitting ? 'Working...' : 'Apply'}
          </Button>
          <Button
            variant="secondary"
            onClick={closeAndResetFilters}
            size="small"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <form id={formId} className="filter-form" onSubmit={handleSubmit(handleDeclineRebooking)}>
        <div className="reasonField">
          <Box>
            <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Reason</Text>
            <Autocomplete
              name={'reason'}
              control={control}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={reasons || []}
              disabled={reasons?.length === 0}
              getOptionLabel={option => option.description}
              rules={{ required: { value: true, message: 'This field is required!' } }}
            />

            {reason &&
              (reason as unknown as ReasonResponse).allowExtraInfo && (
                <>
                  <Text
                    style={{ marginBottom: 'var(--eds-space-8)', marginTop: 'var(--eds-space-8)' }}
                  >
                    Extra Info
                  </Text>
                  <TextField
                    name="extraInfo"
                    type={'text'}
                    control={control}
                    label="Extra Info"
                    hideLabel={true}
                    placeholder=""
                    rules={{
                      required: {
                        value:
                          (reason as unknown as ReasonResponse).allowExtraInfo ? true : false,
                        message: 'This field is required!',
                      },
                    }}
                  />
                </>
              )}
          </Box>
        </div>
        {isDirty && !isValid && !!errors.axios && (
          <div
            className="flex-row justify-items-center eds-spacing--mt-24"
            style={{ maxWidth: '60em' }}
          >
            <StatusIcon status="error" />
            <div className="eds-spacing--ml-16">
              <Text font="body-2" className="eds-color--danger">
                {errors.axios.message}
              </Text>
            </div>
          </div>
        )}
      </form>
    </Modal>
  )
}
