import { Button, ButtonGroup, Modal, Spinner, Text, TextField as EdsTextField } from '@nike/eds'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { InputGroup, TextField } from '../../../../common/components/form'
import {
  postRebookingDeclineReason,
  updateRebookingDeclineReason,
} from '../../../../services/RebookingService'
import { logUsage } from '../../../../services/ShipmentService'
import './RebookingDeclineReasonModal.css'
import { Checkbox as CustomCheckbox } from '../../../../common/components/form/Checkbox'

interface RebookingDeclineReasonModel {
  code: string
  allowExtraInfo: boolean
}

const defaultValues: RebookingDeclineReasonModel = {
  code: '',
  allowExtraInfo: false,
}

export interface RebookingDeclineReasonModalProps {
  rebookingDeclineReasonModalOpen: boolean
  onCloseRebookingDeclineReasonModal: () => void
  selectedRow: any
  refetchData: () => void
  selectedRebookingType: any
}

export const RebookingDeclineReasonModal = ({
  rebookingDeclineReasonModalOpen = false,
  onCloseRebookingDeclineReasonModal,
  selectedRow,
  refetchData,
  selectedRebookingType,
}: RebookingDeclineReasonModalProps): JSX.Element => {
  const {
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  //only enable form submit if no validation errors are present
  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  useEffect(() => {
    reset()
    if (selectedRow) {
      setValue('code', selectedRow.code)
      setValue('allowExtraInfo', selectedRow.allowExtraInfo)
    }
  }, [selectedRow, setValue, reset])

  const handleRebookingDeclineReason = async data => {
    if (selectedRow) {
      await updateRebookingDeclineReason(selectedRow._id, {
        code: data.code,
        description: data.code,
        rebookingType: selectedRebookingType,
        allowExtraInfo: data.allowExtraInfo,
      })
      logUsage('rebookingDeclineReasonConfig', 'update_rebooking_decline_reason')
    } else {
      await postRebookingDeclineReason({
        code: data.code,
        description: data.code,
        rebookingType: selectedRebookingType,
        allowExtraInfo: data.allowExtraInfo,
      })
      logUsage('rebookingDeclineReasonConfig', 'create_rebooking_decline_reason')
    }
    reset()
    // due to react nature, in this javascript closure, isSubmitting is still false
    onCloseRebookingDeclineReasonModal()
    refetchData()
    return data
  }

  const closeAndResetFilters = () => {
    if (!isSubmitting && !selectedRow) {
      reset()
    }
    onCloseRebookingDeclineReasonModal()
  }

  const formId = 'automaticApprovalModalForm'
  return (
    <>
      <Modal
        disableFocusTrap={true}
        hideFade={true}
        isOpen={rebookingDeclineReasonModalOpen}
        onDismiss={closeAndResetFilters}
        headerSlot={<Text font="title-3">{'Configure Rebooking Reason'}</Text>}
        footerSlot={
          <ButtonGroup>
            <Button
              variant="primary"
              type="submit"
              form={formId}
              size="small"
              disabled={isSubmitting || !canSubmit()}
            >
              {isSubmitting && <Spinner />}
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="secondary"
              onClick={closeAndResetFilters}
              size="small"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <form
          id={formId}
          className="filter-form"
          onSubmit={handleSubmit(handleRebookingDeclineReason)}
        >
          <InputGroup label="Type" clearable={false} withDivider={false}>
            <EdsTextField
              id={'party'}
              value={selectedRebookingType.toLowerCase()}
              type="text"
              style={{ textTransform: 'capitalize' }}
              label=""
              disabled
            />
          </InputGroup>
          <InputGroup label="Reason" clearable={false} withDivider={false}>
            <TextField
              name={'code'}
              control={control}
              type="text"
              label=""
              placeholder=""
              maxChars={50}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required!',
                },
                maxLength: {
                  value: 50,
                  message: 'This field contains too many characters',
                },
              }}
            />
          </InputGroup>
          <InputGroup label="" clearable={false} withDivider={false}>
            <CustomCheckbox
              name={'allowExtraInfo'}
              label={'Allow Extra Info'}
              control={control}
            />
          </InputGroup>
        </form>
      </Modal>
    </>
  )
}
