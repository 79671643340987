import { Button, ButtonGroup, Modal, Spinner, Text, TextField as EdsTextField } from '@nike/eds'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { InputGroup, TextField } from '../../../../common/components/form'
import { postRebookingReason, updateRebookingReason } from '../../../../services/RebookingService'
import { logUsage } from '../../../../services/ShipmentService'
import './RebookingReasonModal.css'

interface RebookingReasonModel {
  code: string
}

const defaultValues: RebookingReasonModel = {
  code: '',
}

export interface RebookingReasonModalProps {
  rebookingReasonModalOpen: boolean
  onCloseRebookingReasonModal: () => void
  selectedRow: any
  refetchData: () => void
  selectedResponsibleParty: any
}

export const RebookingReasonModal = ({
  rebookingReasonModalOpen = false,
  onCloseRebookingReasonModal,
  selectedRow,
  refetchData,
  selectedResponsibleParty,
}: RebookingReasonModalProps): JSX.Element => {
  const {
    formState: { errors, isSubmitting },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  })

  //only enable form submit if no validation errors are present
  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  useEffect(() => {
    reset()
    if (selectedRow) {
      setValue('code', selectedRow.code)
    }
  }, [selectedRow, setValue, reset])

  const handleRebookingReason = async data => {
    if (selectedRow) {
      await updateRebookingReason(selectedRow._id, {
        code: data.code,
        description: data.code,
        responsibleParty: selectedResponsibleParty,
        deprecated: false,
      })
      logUsage('rebookingReasonConfig', 'update_rebooking_reason')
    } else {
      await postRebookingReason({
        code: data.code,
        description: data.code,
        responsibleParty: selectedResponsibleParty,
        deprecated: false,
      })
      logUsage('rebookingReasonConfig', 'create_rebooking_reason')
    }
    reset()
    // due to react nature, in this javascript closure, isSubmitting is still false
    onCloseRebookingReasonModal()
    refetchData()
    return data
  }

  const closeAndResetFilters = () => {
    if (!isSubmitting && !selectedRow) {
      reset()
    }
    onCloseRebookingReasonModal()
  }

  const formId = 'automaticApprovalModalForm'
  return (
    <>
      <Modal
        disableFocusTrap={true}
        hideFade={true}
        isOpen={rebookingReasonModalOpen}
        onDismiss={closeAndResetFilters}
        headerSlot={<Text font="title-3">{'Configure Rebooking Reason'}</Text>}
        footerSlot={
          <ButtonGroup>
            <Button
              variant="primary"
              type="submit"
              form={formId}
              size="small"
              disabled={isSubmitting || !canSubmit()}
            >
              {isSubmitting && <Spinner />}
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            <Button
              variant="secondary"
              onClick={closeAndResetFilters}
              size="small"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <form id={formId} className="filter-form" onSubmit={handleSubmit(handleRebookingReason)}>
          <InputGroup label="Responsible Party" clearable={false} withDivider={false}>
            <EdsTextField
              id={'party'}
              value={selectedResponsibleParty.toLowerCase()}
              type="text"
              style={{ textTransform: 'capitalize' }}
              label=""
              disabled
            />
          </InputGroup>
          <InputGroup label="Reason" clearable={false} withDivider={false}>
            <TextField
              name={'code'}
              control={control}
              type="text"
              label=""
              placeholder=""
              maxChars={50}
              rules={{
                required: {
                  value: true,
                  message: 'This field is required!',
                },
                maxLength: {
                  value: 50,
                  message: 'This field contains too many characters',
                },
              }}
            />
          </InputGroup>
        </form>
      </Modal>
    </>
  )
}
