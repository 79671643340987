import { Link, Text } from '@nike/eds'
import clsx from 'clsx'
import './Chips.css'
import Chip from '@mui/material/Chip'
import { useState } from 'react'
import { QueryState } from '../../hooks/useQueryState'
import { CarrierHub } from '../../../services/ShipmentService'
import { date } from '../../../common/utils/datautils/formatter'
import { RebookingApproveFilter } from '../../../services/ShipmentService'
import { RebookingInitiateFilter } from '../../../services/ShipmentService'

export const PACKLIST_TYPES = {
  ALL_PACKLISTS: 'All',
  REBOOKED_HOLD: 'Rebooked / On Hold',
}
export interface ChipValue {
  field: string
  value: any
  stringValue: string
  index?: number
}

export interface ChipsProps {
  setFilter: (value: any) => void
  clearFilter: () => void
  className?: string
  queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
}

export const Chips = ({ setFilter, clearFilter, className = '', queryState }: ChipsProps) => {
  const MAX_CHIPS_LENGTH = 8
  const enrichedClassName = clsx('filter-chips', className)
  const [showAllCarrierHubs, setShowAllCarrierHubs] = useState<boolean>(false)
  const [showAllShipToCodes, setShowAllShipToCodes] = useState<boolean>(false)
  const [showAllCountries, setShowAllCountries] = useState<boolean>(false)
  const [showAllOther, setShowAllOther] = useState<boolean>(false)
  const [showAllSalesOrders, setShowAllSalesOrders] = useState<boolean>(false)
  const [showAllDeliveryDocs, setShowAllDeliveryDocs] = useState<boolean>(false)

  const makeChipsArray = (
    filter: RebookingInitiateFilter | RebookingApproveFilter = {}
  ): ChipValue[] => {
    const chipsArrayFormat = (field: string, value: any) => {
      switch (field) {
        case 'carrierHubs':
          const chipValue = value as CarrierHub
          return `${chipValue?.carrierAccountCode}-${chipValue?.hubCode}`
        case 'shipToCodes':
          return `Ship to Number: ${value}`
        case 'packlists':
          return `Packlist: ${value}`
        case 'iDPPlannedFromTimestamp':
          return `IDP from: ${date(value)}`
        case 'iDPPlannedToTimestamp':
          return `IDP to: ${date(value)}`
        case 'lastChangedFromDateTime':
          return `Last changed date from: ${date(value)}`
        case 'lastChangedToDateTime':
          return `Last changed date to: ${date(value)}`
        case 'countries':
          return `Country: ${value}`
        case 'rebooked':
          return value ? PACKLIST_TYPES.REBOOKED_HOLD : undefined
        case 'trailer':
          return `Trailer: ${value}`
        case 'salesOrders':
          return `Sales Order: ${value}`
        case 'deliveryDocs':
          return `Delivery Note: ${value}`
        case 'open':
          return undefined
        default:
          return value
      }
    }
    return Object.keys(filter)
      .flatMap(field => {
        const filterValue = filter[field]
        if (Array.isArray(filterValue)) {
          return filterValue.map((value, index) => ({
            field,
            value,
            index,
            stringValue: chipsArrayFormat(field, value),
          }))
        }
        let stringValue = chipsArrayFormat(field, filterValue)
        return stringValue !== undefined
          ? [{ field, value: filterValue, stringValue: stringValue }]
          : (undefined as unknown as ChipValue[])
      })
      .filter(it => it?.value !== undefined)
  }

  const allValues = makeChipsArray(queryState.filter)

  const carrierHubChips = allValues.filter(e => e?.field === 'carrierHubs')

  const shipToCodes = allValues.filter(e => e?.field === 'shipToCodes')

  const countries = allValues.filter(e => e?.field === 'countries')

  const salesOrders = allValues.filter(e => e.field === 'salesOrders')

  const deliveryDocs = allValues.filter(e => e.field === 'deliveryDocs')

  const other = allValues.filter(
    e =>
      e?.field !== 'carrierHubs' &&
      e?.field !== 'shipToCodes' &&
      e?.field !== 'countries' &&
      e.field !== 'salesOrders' &&
      e.field !== 'deliveryDocs'
  )

  const handleChipDelete = (
    value: ChipValue,
    queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
  ) => {
    const currentValue = queryState.filter?.[value.field]
    if (currentValue === undefined || queryState.filter === undefined) {
      return
    }
    const currentFilter = queryState.filter
    let newValue
    if (Array.isArray(currentValue)) {
      const values = currentValue.filter((v, i) => i !== value.index)
      newValue = values?.length !== 0 ? values : undefined
    } else {
      newValue = undefined
    }
    setFilter({
      ...currentFilter,
      [value.field]: newValue,
    })
  }
  const createChipsArray = (
    values: ChipValue[],
    show: boolean,
    showAll,
    field: string,
    queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
  ) => {
    return (
      <div>
        {values
          .slice(0, show ? values.length : MAX_CHIPS_LENGTH)
          .map((item: ChipValue, key: number) => (
            <Chip
              className="customChip"
              variant="filled"
              style={{ backgroundColor: 'var(--eds-color-background-default)' }}
              label={item.stringValue}
              key={`${item.field}-${key}`}
              onDelete={() => {
                handleChipDelete(item, queryState)
              }}
            />
          ))}
        {show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(false)}
            label={'See Less'}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
        {!show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(true)}
            label={`+${values.length - MAX_CHIPS_LENGTH}`}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
      </div>
    )
  }
  return (
    <div className={enrichedClassName}>
      <div className="filter-chips-action">
        <Text font="title-5" className="eds-spacing--mb-8">
          Filters ({allValues?.length})
        </Text>
        <Link as="button" variant="secondary" onClick={clearFilter}>
          Clear All
        </Link>
      </div>

      <div className="filter-chips-group">
        {carrierHubChips.length > 0 &&
          createChipsArray(
            carrierHubChips,
            showAllCarrierHubs,
            setShowAllCarrierHubs,
            'carrierHubs',
            queryState
          )}

        {shipToCodes.length > 0 &&
          createChipsArray(
            shipToCodes,
            showAllShipToCodes,
            setShowAllShipToCodes,
            'shipToCodes',
            queryState
          )}

        {countries.length > 0 &&
          createChipsArray(
            countries,
            showAllCountries,
            setShowAllCountries,
            'countries',
            queryState
          )}

        {salesOrders.length > 0 &&
          createChipsArray(
            salesOrders,
            showAllSalesOrders,
            setShowAllSalesOrders,
            'salesOrders',
            queryState
          )}

        {deliveryDocs.length > 0 &&
          createChipsArray(
            deliveryDocs,
            showAllDeliveryDocs,
            setShowAllDeliveryDocs,
            'deliveryDocs',
            queryState
          )}

        {other.length > 0 &&
          createChipsArray(other, showAllOther, setShowAllOther, 'other', queryState)}
      </div>
    </div>
  )
}

export default Chips
