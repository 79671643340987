import { useQuery, UseQueryResult } from 'react-query'
import { fetchResponsibleParties } from '../../services/RebookingService'

export interface ResponsiblePartyResponse {
  code: string
  description: string
}

export const useResponsibleParties = (): UseQueryResult<ResponsiblePartyResponse[], any> => {
  return useQuery(['parties'], fetchResponsibleParties, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
