import { AppHeader, Avatar, Button, Icon, IconButton, Link } from '@nike/eds'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './index.css'
import { useOktaAuth } from '@okta/okta-react'
import useRoleContext from '../../common/hooks/useRoleContext'
import { useMemo, useState } from 'react'
import Config from '../../config/Config'
import { Banner } from '../../common/components/banner/Banner'

function getInitials(name: string) {
  const matchedFirstLetters = name.match(/(\b[A-Z])/g)
  if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
    // Fallback: take first letter
    return name[0]?.toUpperCase()
  }
  return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}

export const Layout = ({ children }) => {
  const [counter, setCounter] = useState(0)
  const { authState } = useOktaAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const initials = useMemo(() => {
    const name = authState?.idToken?.claims?.name
    return name ? getInitials(name) : '-'
  }, [authState])
  const env = Config.ENV
  const appName =
    env === 'PR' ? (
      <Button variant="ghost" disableRipple onClick={() => navigate('/', { replace: true })}>
        Rebooking Service
      </Button>
    ) : (
      <Button variant="ghost" disableRipple onClick={() => navigate('/', { replace: true })}>
        Rebooking Service ({env})
      </Button>
    )

  const hasAutomaticApprovalConfigAccess = useRoleContext().hasAutomaticApprovalConfigAccess()
  const hasRebookingReasonConfigAccess = useRoleContext().hasRebookingReasonConfigAccess()
  const hasInitiateAccess = useRoleContext().hasInitiateAccess()
  const hasApprovalAccess = useRoleContext().hasApprovalAccess()

  const authenticatedProps = {
    avatarSlot: <Avatar initials={initials} onClick={() => setCounter(counter + 1)} size={36} />,
    toolbarSlot: (
      <>
        <Button
          variant="ghost"
          disableRipple
          className="eds-spacing--mr-4"
          onClick={() => window.open('slack://channel?team=T0G3T5X2B&id=C04TFCUSBAS', '_self')}
        >
          #ask-maverick
        </Button>
        {(hasAutomaticApprovalConfigAccess ||
          hasRebookingReasonConfigAccess ||
          hasInitiateAccess) && (
          <IconButton
            icon="Settings"
            label="Settings"
            variant="ghost"
            as={NavLink}
            to="/settings/automaticApproval"
          />
        )}
      </>
    ),
    commandBarSlot: (
      <>
        {hasInitiateAccess && !location.pathname.startsWith('/settings') && (
          <Link
            as={NavLink}
            to={'/initiate'}
            variant="secondary"
            font="subtitle-2"
            style={({ isActive }) => ({ color: isActive ? 'white' : undefined })}
            className="eds-spacing--mr-16"
          >
            Initiate
          </Link>
        )}
        {hasApprovalAccess && !location.pathname.startsWith('/settings') && (
          <Link
            as={NavLink}
            to={'/approve'}
            variant="secondary"
            font="subtitle-2"
            style={({ isActive }) => ({ color: isActive ? 'white' : undefined })}
          >
            Approve / Decline
          </Link>
        )}
        {(hasAutomaticApprovalConfigAccess || hasInitiateAccess) &&
          location.pathname.startsWith('/settings') && (
            <Link
              as={NavLink}
              to={'/settings/automaticApproval'}
              variant="secondary"
              font="subtitle-2"
              style={({ isActive }) => ({ color: isActive ? 'white' : undefined })}
              className="eds-spacing--mr-16"
            >
              Automatic Approval
            </Link>
          )}
        {hasRebookingReasonConfigAccess && location.pathname.startsWith('/settings') && (
          <Link
            as={NavLink}
            to={'/settings/rebookingReason'}
            variant="secondary"
            font="subtitle-2"
            style={({ isActive }) => ({ color: isActive ? 'white' : undefined })}
            className="eds-spacing--mr-16"
          >
            Rebooking Reasons
          </Link>
        )}
        {hasRebookingReasonConfigAccess && location.pathname.startsWith('/settings') && (
          <Link
            as={NavLink}
            to={'/settings/rebookingDeclineReason'}
            variant="secondary"
            font="subtitle-2"
            style={({ isActive }) => ({ color: isActive ? 'white' : undefined })}
            className="eds-spacing--mr-16"
          >
            Decline Reasons
          </Link>
        )}
      </>
    ),
  }

  return (
    <div className="layout">
      <header className="header">
        <AppHeader
          appName={appName}
          isDark
          className={`${counter > 2 ? 'count' : ''}`}
          logoSlot={
            <Link>
              <Icon
                name="NikeSwoosh"
                size="l"
                color="var(--eds-color-white)"
                className="nike-app-icon"
                onClick={() => navigate('/', { replace: true })}
              />
            </Link>
          }
          {...(authState?.isAuthenticated && { ...authenticatedProps })}
        />
      </header>
      <Banner />
      <main className="content">{children}</main>
    </div>
  )
}

export default Layout
