import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Link,
  SidePanel,
  Snack,
  Snackbar,
  Spinner,
  Text,
  Tooltip,
} from '@nike/eds'
import { useForm } from 'react-hook-form'
import { InputGroup, TextArea } from '../../../common/components/form'
import { useCarrierHubList } from '../../../common/hooks/useCarrierHubList'
import { CarrierHubTreeDropdown } from '../../../common/components/carrierhubtreedropdown/CarrierHubTreeDropdown'
import { RebookingApproveFilter } from '../../../services/ShipmentService'
import { useCountriesList } from '../../../common/hooks/useCountriesList'
import {
  ApplicationSettings,
  updateUserPreferences,
  ApplicationFilter,
} from '../../../services/UserPreferencesService'
import { useOktaAuth } from '@okta/okta-react'
import {
  mapStringToArrayOrUndefined,
  valuesAreAllNumbers,
} from '../../../common/utils/datautils/utils'
import { CountriesAutoComplete } from '../countriesautocomplete/CountriesAutoComplete'
import '../../../common/styles/FilterForm.css'

const emptyFormData = {
  hubCodes: [],
  carrierHubs: [],
  packlists: '',
  countries: [],
  shipToCodes: '',
  open: true,
}

const useFilterForm = (
  isOpen,
  initData: RebookingApproveFilter,
  isCountriesCallSuccess,
  countryData
) => {
  const _formData = useMemo(() => {
    const {
      carrierHubs = emptyFormData.carrierHubs,
      packlists = [],
      countries = [],
      shipToCodes = [],
      open = false,
    } = initData ?? {}
    return isOpen
      ? {
          carrierHubs,
          packlists: packlists.join('\n') ?? emptyFormData.packlists,
          countries:
            isCountriesCallSuccess && countryData
              ? countries.map(code => countryData.find(country => country.code === code))
              : emptyFormData.countries,
          shipToCodes: shipToCodes.join('\n') ?? emptyFormData.shipToCodes,
          open,
        }
      : emptyFormData
  }, [isOpen, initData, isCountriesCallSuccess, countryData])

  const { reset, resetField, getValues, ...restForm } = useForm({
    defaultValues: _formData,
    mode: 'all',
  })

  useEffect(() => {
    // refresh the form state whenever we open it
    if (isOpen) {
      reset(_formData)
    }
  }, [isOpen, reset, _formData])

  // extra utility functions...
  const clear = useCallback(() => reset(emptyFormData), [reset])

  return {
    ...restForm,
    reset,
    clear,
    resetField,
    getValues,
  }
}

export interface FilterProps {
  currentFilter: RebookingApproveFilter
  onClickFilter: (formData: RebookingApproveFilter) => void
  expand: boolean
  onCloseSidePanel: () => void
}

export const ApproveFilterForm = ({
  currentFilter: initData,
  onClickFilter,
  expand = false,
  onCloseSidePanel,
}: FilterProps): JSX.Element => {
  const { data: carrierHubData, isSuccess: isCarrierHubCallSuccess } = useCarrierHubList()
  const { data: countryData, isSuccess: isCountriesCallSuccess } = useCountriesList()
  const { control, handleSubmit, clear } = useFilterForm(
    expand,
    initData,
    isCountriesCallSuccess,
    countryData
  )
  const { authState } = useOktaAuth()
  const [isSaving, setIsSaving] = useState(false)
  const [appliedFilterSnackOpen, setAppliedFilterSnackOpen] = useState(false)

  const createFilter = data => {
    return {
      carrierHubs: data.carrierHubs?.length ? data.carrierHubs : undefined,
      packlists: mapStringToArrayOrUndefined(data.packlists),
      countries: data.countries?.length ? data.countries.map(c => c.code) : undefined,
      shipToCodes: mapStringToArrayOrUndefined(data.shipToCodes),
      open: data.open,
    }
  }

  const applyFilters = (data, setDefault: boolean) => {
    if (setDefault) {
      handlePostFilter(data)
    }
    // do some data sanitization before updating the higher state
    onClickFilter(createFilter(data))
    onCloseSidePanel()
  }

  const closeAndResetFilters = () => {
    onCloseSidePanel()
  }

  const handlePostFilter = async data => {
    setIsSaving(true)
    const defaultFilter: ApplicationFilter = {
      name: 'default',
      default: true,
      filter: createFilter(data),
    }
    const applicationSettings: ApplicationSettings = {
      filters: [defaultFilter],
    }
    await updateUserPreferences(authState, applicationSettings, 'approve', 'filter')
    setIsSaving(false)
    setAppliedFilterSnackOpen(true)
    setTimeout(() => {
      setAppliedFilterSnackOpen(false)
    }, 3000)
  }

  const formId = 'shipmentApproveFilterForm'

  return (
    <>
      <SidePanel
        onDismiss={closeAndResetFilters}
        isOpen={expand}
        hasScrim={false}
        headerSlot={
          <>
            <Text font="title-2" as="h2">
              Filter
            </Text>
            <span className="eds-type--body-2">
              <Link as="button" variant="secondary" onClick={clear}>
                Clear All
              </Link>
            </span>
          </>
        }
        footerSlot={
          <ButtonGroup>
            <Button variant="primary" type="submit" form={formId} size="small">
              Apply
            </Button>

            <Tooltip
              bodySlot={<Text>Save as default filter and apply</Text>}
              isDark={true}
              placement={'right'}
            >
              <Button
                form={formId}
                type="submit"
                variant="secondary"
                size="small"
                onClick={async () => {
                  await handleSubmit(data => applyFilters(data, true))()
                }}
                disabled={isSaving}
              >
                {isSaving && <Spinner />}
                {isSaving ? 'Saving...' : 'Save as default'}
              </Button>
            </Tooltip>
          </ButtonGroup>
        }
      >
        <form
          id={formId}
          className="filter-form"
          onSubmit={handleSubmit(data => applyFilters(data, false))}
        >
          <InputGroup label="Carrier / Hub" clearable={false} withDivider={false}>
            <CarrierHubTreeDropdown
              name="carrierHubs"
              control={control}
              carrierHubs={isCarrierHubCallSuccess && carrierHubData ? carrierHubData : []}
              showPartiallySelected={true}
            />
          </InputGroup>

          <InputGroup label="Packlist" clearable={false} withDivider={false}>
            <TextArea
              name="packlists"
              control={control}
              resize="vertical"
              // @ts-ignore
              style={{ padding: 'var(--eds-space-4)' }}
              label="Packlist"
              rules={{
                validate: input =>
                  valuesAreAllNumbers(input) || 'Packlist can only contain numeric values',
              }}
            />
          </InputGroup>

          <InputGroup label="Ship To Country" clearable={false} withDivider={false}>
            <CountriesAutoComplete
              name="countries"
              control={control}
              countryData={isCountriesCallSuccess && countryData ? countryData : []}
            />
          </InputGroup>

          <InputGroup label="Ship To Number" clearable={false} withDivider={false}>
            <TextArea
              name="shipToCodes"
              control={control}
              resize="vertical"
              // @ts-ignore
              style={{ padding: 'var(--eds-space-4)' }}
              label="Ship To Number"
              rules={{
                validate: input =>
                  valuesAreAllNumbers(input) || 'Ship To Number can only contain numeric values',
              }}
            />
          </InputGroup>
        </form>
      </SidePanel>

      <Snackbar>
        {appliedFilterSnackOpen && (
          <Snack id="1" status="success" onDismiss={id => setAppliedFilterSnackOpen(false)}>
            Filter saved!
          </Snack>
        )}
      </Snackbar>
    </>
  )
}
