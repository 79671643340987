import { Divider } from '@mui/material'
import { Button, Icon, Spinner } from '@nike/eds'
import { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Navigate } from 'react-router-dom'
import { logUsage, RebookingInitiateFilter } from '../../services/ShipmentService'
import { removeEmptyAndDuplicateFields } from '../../common/utils/datautils/sanitize'
import { useQueryState } from '../../common/hooks/useQueryState'
import useRoleContext from '../../common/hooks/useRoleContext'
import { useShipmentList } from '../../common/hooks/useShipmentQueries'
import Chips from '../../common/components/chips/Chips'
import { HoldModal } from '../initiatepage/holdmodal/HoldModal'
import { RebookingHistoryRow } from '../../common/components/rebookinghistoryrow/RebookingHistoryRow'
import { RebookingModal } from '../../pages/initiatepage/rebookingmodal/RebookingModal'
import { ReportType } from '../../common/utils/ReportType'
import { DownloadModal } from '../../common/components/downloadmodal/DownloadModal'
import { InitiateFilterForm } from '../../pages/initiatepage/initiatefilterform/InitiateFilterForm'
import { TableLayout } from '../../common/components/table'
import { useFilters } from '../../common/hooks/useFilters'
import { ApplicationSettings, fetchUserPreferences } from '../../services/UserPreferencesService'
import { useInitiateRebookingTable } from './initiaterebookingtable/InitiateRebookingTable'

const pageSizeOptions = [10, 25, 50, 100, 200, 500]

const RebookingInitiate = () => {
  const {
    state: queryState,
    setFilter,
    gotoPage,
    setPageSize,
    toggleSortBy,
    clearFilter,
  } = useQueryState<RebookingInitiateFilter>({ pageSize: 200 })
  const { authState } = useOktaAuth()
  const { isFetching, isError, isSuccess, data, isRefetching, refetch } =
    useShipmentList(queryState)
  const [creationDateTime, setCreationDateTime] = useState<Date | undefined>(undefined)
  const [expandFilterForm, setExpandFilterForm] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [rebookingModalOpen, setRebookingModalOpen] = useState<boolean>(false)
  const [holdModalOpen, setHoldModalOpen] = useState<boolean>(false)
  const isExportButtonDisabled =
    isFetching || isDownloading || isError || data?.totalResources === 0
  const { isSuccess: isFiltersFetched } = useFilters(authState, 'initiate')

  useEffect(() => {
    const applyFilters = async () => {
      let applicationSettings: ApplicationSettings = await fetchUserPreferences(
        authState,
        'initiate',
        'filter'
      )
      if (applicationSettings.filters) {
        setFilter(applicationSettings.filters.filter(filter => filter.default)[0].filter)
      }
    }
    if (isFiltersFetched) {
      applyFilters()
    } else {
      return undefined
    }
    // eslint-disable-next-line
  }, [isFiltersFetched])

  useEffect(() => {
    if (queryState.filter) {
      logUsage('initiate', 'search', { filters: removeEmptyAndDuplicateFields(queryState.filter) })
    }
  }, [queryState.filter])
  useEffect(() => {
    if (!isRefetching) {
      setCreationDateTime(new Date())
    }
  }, [isRefetching])

  // Table
  const tableInstance = useInitiateRebookingTable(
    queryState,
    gotoPage,
    setPageSize,
    toggleSortBy,
    isFetching,
    isSuccess,
    isError,
    data
  )

  const onClickFilter = (filterFormData: RebookingInitiateFilter) => {
    setFilter(filterFormData)
  }
  const handleCloseFilterForm = () => setExpandFilterForm(false)

  const handleCloseRebookingModalFilterForm = () => setRebookingModalOpen(false)

  const handleCloseHoldModalForm = () => setHoldModalOpen(false)

  const hasInitiateAccess = useRoleContext().hasInitiateAccess()

  if (!hasInitiateAccess) {
    // no Initiate access - redirect to root and let the router handle the correct redirect
    return <Navigate to="/" replace />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--eds-space-8)',
        }}
      >
        <div
          className="flex-row flex-nowrap content-space-between justify-items-start"
          style={{ padding: 'var(--eds-space-16) var(--eds-space-16) 0 var(--eds-space-16)' }}
        >
          <div
            style={{
              width: '90%',
            }}
          >
            <Chips setFilter={setFilter} queryState={queryState} clearFilter={clearFilter} />
          </div>
          <div className="flex-shrink-0">
            <Button
              className="popover-filter"
              style={{ marginTop: 'var(--eds-space-8)', paddingLeft: 'var(--eds-space-16)' }}
              onClick={() => setExpandFilterForm(true)}
              disabled={isFetching}
              beforeSlot={<Icon name="Filter" size="m" />}
            >
              Filters
            </Button>
          </div>
        </div>

        <Divider
          style={{
            width: '99%',
            margin: 'auto',
            borderBottomWidth: 2,
          }}
        />

        <div
          className="flex-row flex-nowrap content-space-between justify-items-center"
          style={{ padding: '0 var(--eds-space-16)' }}
        >
          <div>
            <Button
              size="medium"
              disabled={Object.keys(tableInstance.state.selectedRowIds).length === 0}
              style={{ marginRight: 'var(--eds-space-8)' }}
              onClick={() => setRebookingModalOpen(true)}
            >
              Rebook
            </Button>

            <Button
              size="medium"
              disabled={Object.keys(tableInstance.state.selectedRowIds).length === 0}
              style={{ marginRight: 'var(--eds-space-8)' }}
              onClick={() => setHoldModalOpen(true)}
            >
              Hold
            </Button>
          </div>

          <Button
            variant="primary"
            className="Button"
            style={{ paddingLeft: 'var(--eds-space-16)' }}
            disabled={isExportButtonDisabled}
            onClick={() => setExportModalOpen(true)}
            afterSlot={isDownloading ? <Spinner color="white" /> : undefined}
            beforeSlot={<Icon name="Download" size="m" />}
          >
            Export{isDownloading && 'ing'} Results
          </Button>
        </div>

        <TableLayout
          {...{ ...tableInstance, pageSizeOptions, isFetching, isError }}
          queryState={queryState}
          className="-striped -highlight"
          refetchData={refetch}
          expandedRow={RebookingHistoryRow}
        />
      </div>
      <InitiateFilterForm
        currentFilter={queryState.filter}
        onClickFilter={onClickFilter}
        expand={expandFilterForm}
        onCloseSidePanel={handleCloseFilterForm}
      />
      <DownloadModal
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
        queryState={queryState}
        isDownloading={isDownloading}
        setIsDownloading={setIsDownloading}
        reportType={ReportType.REBOOKING_INITIATE_REPORT}
        totalResults={data?.totalResources}
      />

      <RebookingModal
        rebookingModalOpen={rebookingModalOpen}
        filter={queryState.filter}
        selectedRows={tableInstance.selectedFlatRows}
        isAllRowsSelected={!!tableInstance.isAllServerRowsSelected}
        totalResources={tableInstance.totalResources}
        onCloseRebookingModal={handleCloseRebookingModalFilterForm}
        creationDateTime={creationDateTime}
      />

      <HoldModal
        holdModalOpen={holdModalOpen}
        filter={queryState.filter}
        selectedRows={tableInstance.selectedFlatRows}
        isAllRowsSelected={!!tableInstance.isAllServerRowsSelected}
        totalResources={tableInstance.totalResources}
        onCloseRebookingModal={handleCloseHoldModalForm}
        creationDateTime={creationDateTime}
      />
    </>
  )
}

export default RebookingInitiate
