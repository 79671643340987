import './Banner.css'

export function Banner() {
  const text = process.env.REACT_APP_BANNER
  return text ? (
    <div id="banner" className="banner">
      {text}
    </div>
  ) : null
}
