import { OktaAuth } from '@okta/okta-auth-js'
import Config from './Config'

export const OktaConfig = {
  issuer: Config.OKTA_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: 'nike.niketech.rebooking-ui',
  scopes: ['openid', 'profile', 'email', 'demand_planning:carrierint.rebooking.ui::execute:'],
  pkce: true,
}

const oktaAuth = new OktaAuth(OktaConfig)

export const getOktaAuth = () => oktaAuth
