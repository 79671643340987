import { useEffect, useRef } from 'react'
import InternalDropdownTreeSelect, {
  DropdownTreeSelectProps as InternalDropdownTreeSelectProps,
} from 'react-dropdown-tree-select'
import { useController, UseControllerProps } from 'react-hook-form'
import 'react-dropdown-tree-select/dist/styles.css'
import './DropdownTreeSelect.css'

// re-export everything from react-dropdown-tree-select, it is just a wrapper
export * from 'react-dropdown-tree-select'

export interface DropdownTreeSelectProps
  extends Omit<
      InternalDropdownTreeSelectProps,
      'id' | 'name' | 'value' | 'defaultValue' | 'onChange' | 'onBlur'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
  mapValue?: <T>(value: any) => T
}

export const DropdownTreeSelect = ({
  name,
  control,
  data,
  rules = undefined,
  mapValue = x => x,
  onFocus,
  ...rest
}: DropdownTreeSelectProps) => {
  const {
    field: { onChange, onBlur, value: selectedNodes, ...fieldProps },
  } = useController({
    name,
    control,
    rules,
  })

  // this is to avoid re-rending the dropdown while selecting options
  // so we keep selected options in a reference to not trigger react lifecycle
  const _selectedNodes = useRef({ selectedNodes, changed: false })
  // another trick to handle the tag deletion from tag-list
  const _hasFocus = useRef(false)
  useEffect(() => {
    // we should also make sure it is always up-to-date
    _selectedNodes.current = { selectedNodes, changed: false }
  }, [selectedNodes])

  const _onChange = (_, selectedNodes) => {
    _selectedNodes.current = {
      selectedNodes,
      changed: true,
    }
    propagateChange(_hasFocus.current)
  }

  const propagateChange = hasFocus => {
    // once we leave the selection, we can update the state and re-render the components hierarchy
    if (_selectedNodes.current.changed && !hasFocus) {
      onChange(mapValue(_selectedNodes.current.selectedNodes))
      _selectedNodes.current.changed = false
    }
  }

  const _onFocus = () => {
    _hasFocus.current = true
    if (onFocus) {
      onFocus()
    }
  }

  const _onBlur = () => {
    _hasFocus.current = false
    propagateChange(_hasFocus.current)
    onBlur()
  }

  return (
    <InternalDropdownTreeSelect
      id={name}
      {...fieldProps}
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      data={data}
      {...rest}
    />
  )
}
