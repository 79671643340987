import { ComponentType } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { AuthState } from '@okta/okta-auth-js'

export interface RoleContext {
  authState: AuthState | null
  hasRole: (s: string) => boolean
  hasAnyRole: (...s: string[]) => boolean
  hasInitiateAccess: () => boolean
  hasApprovalAccess: () => boolean
  hasAutomaticApprovalConfigAccess: () => boolean
  hasRebookingReasonConfigAccess: () => boolean
  getRedirectPath: () => string
}

export const useRoleContext = (): RoleContext => {
  const { authState } = useOktaAuth()

  const hasRole = (role: string): boolean => {
    return !!authState?.accessToken?.claims['groups']?.toString()?.includes(role)
  }

  const hasAnyRole = (...roles: string[]): boolean => {
    for (const role of roles) {
      if (hasRole(role)) {
        return true
      }
    }
    return false
  }

  const hasInitiateAccess = (): boolean => {
    return hasAnyRole('App.emea.rebookingService.initiateRebooking')
  }

  const hasApprovalAccess = (): boolean => {
    return hasAnyRole(
      'App.emea.rebookingService.approver.admin',
      'App.emea.rebookingService.approver.CEVA',
      'App.emea.rebookingService.approver.SCHE',
      'EXT.App.emea.rebookingService.approver.CEVA'
    )
  }

  const hasAutomaticApprovalConfigAccess = (): boolean => {
    return hasAnyRole('App.emea.carrierManagement.admin')
  }

  const hasRebookingReasonConfigAccess = (): boolean => {
    return hasAnyRole('App.emea.rebookingService.rebookingReasons.admin')
  }

  const getRedirectPath = (): string => {
    // users either have initiate access, approval access or both. If both access types are missing the user cannot
    // login so we must not handle this condition
    if (hasInitiateAccess()) {
      return '/initiate'
    }
    return '/approve'
  }

  return {
    hasRole,
    hasAnyRole,
    hasInitiateAccess,
    hasApprovalAccess,
    hasAutomaticApprovalConfigAccess,
    hasRebookingReasonConfigAccess,
    authState,
    getRedirectPath,
  }
}

export const withRoleContext = <P extends RoleContext>(Component: ComponentType<P>) => {
  return function WithRoleContextHoc(hocProps: Omit<P, keyof RoleContext>) {
    const role = useRoleContext()
    return <Component {...role} {...(hocProps as P)} />
  }
}

export default useRoleContext
