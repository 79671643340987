import { addDays, addMilliseconds } from 'date-fns'
import axios from 'axios'
import { QueryState } from '../common/hooks/useQueryState'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'
import Config from '../config/Config'
import { ReportType } from '../common/utils/ReportType'
import { zonedTimeToUtc } from 'date-fns-tz'

export const EXPORT_LIMIT = 50000

export const shipmentClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.BACKEND_URL}/${Config.BASEPATH}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface CarrierHub {
  carrierAccountCode?: string
  hubCode?: string
}

export interface RebookingInitiateFilter {
  hubCodes?: string[]
  carrierHubs?: CarrierHub[]
  shipToCodes?: string[]
  packlists?: string[]
  iDPPlannedFromTimestamp?: any
  iDPPlannedToTimestamp?: any
  trailer?: string
  salesOrders?: string[]
  deliveryDocs?: string[]
}

export interface RebookingApproveFilter {
  hubCodes?: string[]
  carrierHubs?: CarrierHub[]
  packlists?: string[]
  countries?: string[]
  shipToCodes?: string[]
  open?: boolean
}

export interface ExportShipmentReportId {
  shipmentReportId: string
}

export interface ExportShipmentReportTask {
  downloadUrl: string
  expiryDate: Date
  id: string
  status: string
}

const convertToFilter = (query: QueryState<any>) => {
  // convert to CET
  const iDPPlannedFromTimestamp = query.filter?.iDPPlannedFromTimestamp
    ? zonedTimeToUtc(query.filter?.iDPPlannedFromTimestamp, 'Europe/Brussels').toISOString()
    : undefined

  // convert to CET and add set to end of day
  const iDPPlannedToTimestamp = query.filter?.iDPPlannedToTimestamp
    ? addMilliseconds(
        addDays(zonedTimeToUtc(query.filter?.iDPPlannedToTimestamp, 'Europe/Brussels'), 1),
        -1
      ).toISOString()
    : undefined

  return {
    open: query?.filter?.open,
    carrierHubs: query?.filter?.carrierHubs,
    originalIDPPlannedFromTimestamp: iDPPlannedFromTimestamp,
    originalIDPPlannedToTimestamp: iDPPlannedToTimestamp,
    originalShipToCodes: query?.filter?.shipToCodes,
    shipToCountries: query?.filter?.countries,
    shipmentReferences: query?.filter?.packlists,
    trailerNumber: query?.filter?.trailer,
    deliveryDocs: query?.filter?.deliveryDocs,
    salesOrders: query?.filter?.salesOrders,
    packlistStatuses: ['SCHEDULED', 'READY_FOR_SHIPMENT', 'SHIPPED'],
    idpIndicator: true,
  }
}

const convertToSort = (
  query: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
): string[] => {
  if (!query.sortBy) {
    return []
  }

  const sortFieldMapping = new Map([
    ['shipToCode', 'shipTo.code'],
    ['shipToName', 'shipTo.name'],
    ['trailerAccessNumber', 'cmrs.trailerAccessNumber'],
    ['deliveryDoc', 'deliveryNoteNumbers'],
    ['salesOrder', 'salesOrderNumbers'],
  ])

  return query.sortBy.map(it => `${it.desc ? '-' : ''}${sortFieldMapping.get(it.id) || it.id}`)
}

const getBody = (query: QueryState<RebookingInitiateFilter | RebookingApproveFilter>) => {
  return {
    page: query.pageNumber + 1, // backend expects to receive page 1
    size: query.pageSize,
    filter: convertToFilter(query),
    sort: convertToSort(query),
  }
}

const convertToData = data => {
  return data.objects.map(shipment => ({
    shipmentReference: shipment?.shipmentReference,
    packlistStatus: shipment?.packlistStatus,
    shipToCode: shipment?.original?.shipTo.code,
    shipToName: shipment?.shipTo.name,
    shipToCountry: shipment?.shipTo.country,
    carrierAccountCode: shipment?.carrierAccountCode,
    hubCode: shipment?.hubCode,
    salesOrder: shipment?.salesOrderNumbers,
    deliveryDoc: shipment?.deliveryNoteNumbers,
    directDrop: shipment?.cmrs?.[0]?.directDropIndicator,
    deliveryInstructionText: {
      original: shipment?.original?.deliveryInstructionText,
      new: shipment?.openRebooking
        ? shipment?.openRebooking?.deliveryInstructionText
        : shipment?.lastApprovedRebooking?.deliveryInstructionText,
    },
    iDPPlannedTimestamp: {
      original: {
        lastApprovedIdpDate:
          shipment?.lastApprovedRebooking?.iDPPlannedTimestamp ??
          shipment?.original?.iDPPlannedTimestamp,
        lastApprovedIdpFromTime:
          shipment?.lastApprovedRebooking?.plannedDeliveryFromTime ??
          shipment?.original?.plannedDeliveryFromTime,
        lastApprovedIdpToTime:
          shipment?.lastApprovedRebooking?.plannedDeliveryToTime ??
          shipment?.original?.plannedDeliveryToTime,
        date: shipment?.original?.iDPPlannedTimestamp,
        plannedDeliveryFromTime: shipment?.original?.plannedDeliveryFromTime,
        plannedDeliveryToTime: shipment?.original?.plannedDeliveryToTime,
      },
      new: hasIDPChanged(shipment)
        ? {
            date: getNewIDPPlannedTimestamp(shipment),
            plannedDeliveryFromTime: getNewPlannedDeliveryFromTime(shipment),
            plannedDeliveryToTime: getNewPlannedDeliveryToTime(shipment),
          }
        : undefined,
    },
    totalNumberOfCartons: shipment?.totalNumberOfCartons,
    totalNumberOfUnits: shipment?.totalNumberOfUnits,
    actualShipDateTime: shipment?.actualShipDateTime,
    rebookingTypeData: {
      type:
        shipment?.openRebooking?.type ||
        shipment?.declinedRebooking?.type ||
        shipment?.lastApprovedRebooking?.type,
      data:
        shipment?.openRebooking || shipment?.declinedRebooking || shipment?.lastApprovedRebooking,
    },
    rebookingStatusData: {
      status:
        shipment?.openRebooking?.status ||
        shipment?.declinedRebooking?.status ||
        shipment?.lastApprovedRebooking?.status,
      data:
        shipment?.openRebooking || shipment?.declinedRebooking || shipment?.lastApprovedRebooking,
    },
    lastChangedDateTime: shipment?.lastChangedDateTime,
    trailerAccessNumber: shipment?.cmrs
      ? shipment.cmrs[0]
        ? shipment.cmrs[0].trailerAccessNumber
        : null
      : null,
    plannedGoodsIssueDate: shipment?.plannedGoodsIssueDate,
  }))
}

const hasIDPChanged = (shipment): boolean => {
  if (shipment?.openRebooking) {
    return (
      shipment?.openRebooking?.iDPPlannedTimestamp ||
      shipment?.openRebooking?.plannedDeliveryFromTime ||
      shipment?.openRebooking?.plannedDeliveryToTime
    )
  } else if (shipment?.lastApprovedRebooking) {
    return (
      shipment?.lastApprovedRebooking?.iDPPlannedTimestamp ||
      shipment?.lastApprovedRebooking?.plannedDeliveryFromTime ||
      shipment?.lastApprovedRebooking?.plannedDeliveryToTime
    )
  } else {
    return false
  }
}

const getNewIDPPlannedTimestamp = (shipment): Date => {
  if (shipment?.openRebooking) {
    if (shipment?.openRebooking?.iDPPlannedTimestamp) {
      return shipment?.openRebooking?.iDPPlannedTimestamp
    } else {
      return shipment?.original?.iDPPlannedTimestamp
    }
  } else if (shipment?.lastApprovedRebooking) {
    if (shipment?.lastApprovedRebooking?.iDPPlannedTimestamp) {
      return shipment?.lastApprovedRebooking?.iDPPlannedTimestamp
    } else {
      return shipment?.original?.iDPPlannedTimestamp
    }
  } else {
    return shipment?.original?.iDPPlannedTimestamp
  }
}

const getNewPlannedDeliveryFromTime = shipment => {
  if (shipment?.openRebooking) {
    if (shipment?.openRebooking?.plannedDeliveryFromTime) {
      return shipment?.openRebooking?.plannedDeliveryFromTime
    } else {
      return shipment?.original?.plannedDeliveryFromTime
    }
  } else if (shipment?.lastApprovedRebooking) {
    if (shipment?.lastApprovedRebooking?.plannedDeliveryFromTime) {
      return shipment?.lastApprovedRebooking?.plannedDeliveryFromTime
    } else {
      return shipment?.original?.plannedDeliveryFromTime
    }
  } else {
    return shipment?.original?.plannedDeliveryFromTime
  }
}

const getNewPlannedDeliveryToTime = shipment => {
  if (shipment?.openRebooking) {
    if (shipment?.openRebooking?.plannedDeliveryToTime) {
      return shipment?.openRebooking?.plannedDeliveryToTime
    } else {
      return shipment?.original?.plannedDeliveryToTime
    }
  } else if (shipment?.lastApprovedRebooking) {
    if (shipment?.lastApprovedRebooking?.plannedDeliveryToTime) {
      return shipment?.lastApprovedRebooking?.plannedDeliveryToTime
    } else {
      return shipment?.original?.plannedDeliveryToTime
    }
  } else {
    return shipment?.original?.plannedDeliveryToTime
  }
}

export const fetchShipments = async (
  query: QueryState<RebookingInitiateFilter>
): Promise<{
  data
  totalResources
  totalPages
}> => {
  console.debug('fetchShipments with: ' + JSON.stringify(query))

  return shipmentClient.post(`/shipments/v2/search`, getBody(query)).then(response => {
    return {
      data: convertToData(response.data),
      totalResources: response.data.pages.totalResources,
      totalPages: response.data.pages.totalPages,
    }
  })
}

export const fetchOpenRebookingTypes = async (
  query: QueryState<RebookingApproveFilter>
): Promise<{
  data: string[]
}> => {
  console.debug('fetchOpenRebookingTypes with: ' + JSON.stringify(query))

  return shipmentClient.post(`/shipments/v1/openRebookingTypes`, getBody(query)).then(response => {
    return {data: response.data}
  })
}

export const fetchShipmentReportExportTask = async (
  shipmentReportId: string | undefined
): Promise<ExportShipmentReportTask> => {
  return shipmentClient.get(`/report/v1/${shipmentReportId}`).then(response => {
    return response.data
  })
}

export const shipmentReportExportTask = async (
  query: QueryState<RebookingInitiateFilter | RebookingApproveFilter>,
  reportType: ReportType
): Promise<ExportShipmentReportId> => {
  const queryClone = { ...query }
  queryClone.pageSize = EXPORT_LIMIT
  console.debug('export results with: ' + JSON.stringify(queryClone))

  return shipmentClient
    .post(
      `/${
        reportType === ReportType.REBOOKING_INITIATE_REPORT ? 'initiates' : 'approvals'
      }/v1/export`,
      getBody(queryClone)
    )
    .then(response => {
      return response.data
    })
}

export const logUsage = async (page: string, action: string, params = {}): Promise<string> => {
  return shipmentClient
    .post('/usage-log', { page, action, application: 'rebooking', ...params })
    .then(response => response.data)
}
