import { RadioGroup as EdsRagioGroup } from '@nike/eds'
import { useController, UseControllerProps } from 'react-hook-form'

export interface RadioGroupProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof EdsRagioGroup>,
      'id' | 'name' | 'valueSelected' | 'defaultValue' | 'onChange' | 'onBlur'
    >,
    UseControllerProps {
  control: any
}

export const RadioGroup = ({
  name,
  control,
  label,
  rules = undefined,
  ...rest
}: RadioGroupProps) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <EdsRagioGroup
      id={name}
      name={name}
      label={label}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur}
      valueSelected={value}
      hasErrors={!!error}
      errorMessage={error?.message}
      {...rest}
    />
  )
}
