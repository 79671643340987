import { useQuery, UseQueryResult } from 'react-query'
import { QueryState } from './useQueryState'
import { fetchAutomaticApprovalConfigs } from '../../services/RebookingService'

const automaticApprovalQueries = {
  all: ['automaticApprovals'],
  lists: ['automaticApprovals', 'list'],
  list: queryState => [...automaticApprovalQueries.lists, queryState],
}

export const useAutomaticApprovalConfigList = (
  selectedCarrierHubs,
  queryState: QueryState<any>
): UseQueryResult<{
  data
}> => {
  return useQuery(
    automaticApprovalQueries.list(queryState),
    () => fetchAutomaticApprovalConfigs(selectedCarrierHubs, queryState),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
}
