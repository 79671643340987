import { DropdownTreeSelect, DropdownTreeSelectProps, TreeNodeProps } from '../form'
import { CarrierHubResponse } from '../../hooks/useCarrierHubList'
import { useWatch } from 'react-hook-form'

export interface CarrierHubTreeDropdownProps
  extends Omit<DropdownTreeSelectProps, 'data' | 'onChange'> {
  name: string
  control: any
  carrierHubs: CarrierHubResponse[]
}

const makeCarrierHubTree = (
  carrierHubs: CarrierHubResponse[],
  selectedCarrierHubs
): TreeNodeProps[] => {
  const sortByValue = (a, b) => a.value.localeCompare(b.value)

  const makeChildren = (children: TreeNodeProps[] = [], carrierHub, checked) =>
    [
      ...children,
      {
        label: carrierHub.hubCode,
        value: `${carrierHub.carrierAccountCode}/${carrierHub.hubCode}`,
        tagLabel: `${carrierHub.carrierAccountCode} - ${carrierHub.hubCode}`,
        carrierHubs: [
          {
            carrierAccountCode: carrierHub.carrierAccountCode,
            hubCode: carrierHub.hubCode,
          },
        ],
        checked,
      },
    ].sort(sortByValue)

  // @ts-ignore
  return carrierHubs
    .reduce((acc, carrierHub) => {
      const carrier = acc.find(it => it.value === carrierHub.carrierAccountCode)
      const checked = !!selectedCarrierHubs?.find(
        it =>
          it.carrierAccountCode === carrierHub.carrierAccountCode &&
          it.hubCode === carrierHub.hubCode
      )
      if (carrier) {
        carrier.children = makeChildren(carrier.children, carrierHub, checked)
        carrier.carrierHubs = [
          ...carrier.carrierHubs,
          {
            carrierAccountCode: carrierHub.carrierAccountCode,
            hubCode: carrierHub.hubCode,
          },
        ]
        return acc
      }
      acc.push({
        label: `${carrierHub.carrierAccountCode} - ${carrierHub.carrierName}`,
        value: carrierHub.carrierAccountCode,
        tagLabel: `${carrierHub.carrierAccountCode} - All Hubs`,
        carrierHubs: [
          {
            carrierAccountCode: carrierHub.carrierAccountCode,
            hubCode: carrierHub.hubCode,
          },
        ],
        children: makeChildren([], carrierHub, checked),
      })
      return acc
    }, [] as TreeNodeProps[])
    .sort(sortByValue)
}

export const CarrierHubTreeDropdown = ({
  name,
  control,
  carrierHubs,
  ...rest
}: CarrierHubTreeDropdownProps): JSX.Element => {
  const selectedCarrierHubs = useWatch({ name, control })
  const data = makeCarrierHubTree(carrierHubs, selectedCarrierHubs)

  return (
    <DropdownTreeSelect
      name={name}
      control={control}
      mapValue={v => v.flatMap(it => it.carrierHubs)}
      keepTreeOnSearch={true}
      keepChildrenOnSearch={true}
      data={data}
      texts={{
        placeholder: 'Select Carrier / Hub',
        label: 'Select Carrier/Hub',
      }}
      {...rest}
    />
  )
}
