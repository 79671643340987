import { Box, Button, Modal, Text } from '@nike/eds'
import { useDownloadShipmentReport } from '../../hooks/useDownloadExportQueries'
import { useEffect, useState } from 'react'
import { QueryState } from '../../hooks/useQueryState'
import { logUsage, RebookingInitiateFilter } from '../../../services/ShipmentService'
import { RebookingApproveFilter } from '../../../services/ShipmentService'
import { ReportType } from '../../utils/ReportType'
import { EXPORT_LIMIT } from '../../../services/ShipmentService'
import { useLocation } from 'react-router-dom'

interface DownloadModalInput {
  exportModalOpen: boolean
  setExportModalOpen: (data: boolean) => void
  queryState: QueryState<RebookingInitiateFilter | RebookingApproveFilter>
  isDownloading: boolean
  setIsDownloading: (data: boolean) => void
  reportType: ReportType
  totalResults?: number
}

export const DownloadModal = ({
  exportModalOpen,
  setExportModalOpen,
  queryState,
  isDownloading,
  setIsDownloading,
  reportType,
  totalResults,
}: DownloadModalInput): JSX.Element => {
  const [startTime, setStartTime] = useState<Date>()
  const [downloadQueryState, setDownloadQueryState] =
    useState<QueryState<RebookingInitiateFilter | RebookingApproveFilter>>(queryState)
  const location = useLocation()

  const download = () => {
    const page = location.pathname.startsWith('/')
      ? location.pathname.split('/')[1]
      : location.pathname
    logUsage(page ? page : 'view', 'export')
    if (!isDownloading) {
      setDownloadQueryState(queryState)
    }
    setIsDownloading(true)
    setExportModalOpen(false)
  }

  const cancel = () => {
    setIsDownloading(false)
    setExportModalOpen(false)
  }

  useEffect(() => {
    if (isDownloading) {
      setStartTime(new Date())
    }
    if (!isDownloading) {
      setStartTime(undefined)
    }
  }, [isDownloading])

  useDownloadShipmentReport(
    downloadQueryState,
    isDownloading,
    setIsDownloading,
    startTime,
    reportType
  )

  return (
    <Modal
      isOpen={exportModalOpen}
      onDismiss={() => setExportModalOpen(false)}
      headerSlot={
        <Text font={'title-3'} as={'h3'}>
          Export Results
        </Text>
      }
      footerSlot={
        <div
          style={{
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'start',
          }}
        >
          <Button onClick={() => download()} size="small" variant="primary">
            Download
          </Button>
          <Button onClick={() => cancel()} size="small" variant="secondary">
            Cancel
          </Button>
        </div>
      }
    >
      <Box>
        <Text>You're about to export the results. This can take a while.</Text>
        <Text style={{ marginTop: 'var(--eds-space-16)' }}>
          Once the application has generated the report it will be automatically downloaded.
        </Text>
        {!!(totalResults && totalResults > EXPORT_LIMIT) && (
          <Text style={{ marginTop: 'var(--eds-space-16)' }}>
            Please note that only the first {EXPORT_LIMIT} results will be exported.
          </Text>
        )}
      </Box>
    </Modal>
  )
}
