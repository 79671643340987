import { Snack } from '@nike/eds'
import React, { createContext } from 'react'

type SnackProps = React.ComponentProps<typeof Snack>
type SnackPropsWithStatus = Omit<SnackProps, 'status'>

export interface Message extends SnackPropsWithStatus {
  status?: SnackProps['status'] // make optional and default to 'info' in the notification
  content: any
}

export interface NotificationProps {
  messages: Message[]
}

export interface NotificationContextValue extends NotificationProps {
  pushNotification: (data: Message) => void
  removeNotification: (id: string) => void
}

const doNothing = () => {}

export const NotificationContext = createContext<NotificationContextValue>({
  messages: [],
  pushNotification: doNothing,
  removeNotification: doNothing,
})
