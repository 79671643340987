import { Button, Modal, Text } from '@nike/eds'

interface ConfirmationModalInput {
  confirmationModalOpen: boolean
  setConfirmationModalOpen: (data: boolean) => void
  textConfirm: string
  textCancel: string
  title: string
  body: string
  confirmationCallback?: any
}
export const ConfirmationModal = ({
  confirmationModalOpen,
  setConfirmationModalOpen,
  textConfirm,
  textCancel,
  title,
  body,
  confirmationCallback,
}: ConfirmationModalInput): JSX.Element => {
  return (
    <>
      <Modal
        onDismiss={() => setConfirmationModalOpen(!confirmationModalOpen)}
        isOpen={confirmationModalOpen}
        headerSlot={
          <Text font={'title-3'} as={'h3'}>
            {title}
          </Text>
        }
        footerSlot={
          <div
            style={{
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: 'auto auto',
              justifyContent: 'start',
            }}
          >
            <Button
              onClick={() => {
                setConfirmationModalOpen(!confirmationModalOpen)
                if (confirmationCallback) {
                  confirmationCallback()
                }
              }}
              size="small"
            >
              {textConfirm}
            </Button>
            <Button
              onClick={() => setConfirmationModalOpen(!confirmationModalOpen)}
              size="small"
              variant="secondary"
            >
              {textCancel}
            </Button>
          </div>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Text font={'body-1'} as={'p'}>
            {body}
          </Text>
        </div>
      </Modal>
    </>
  )
}
