import { Button, ButtonGroup, Modal, Spinner, StatusIcon, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import { useForm, useWatch } from 'react-hook-form'
import {
  getSelectedShipmentReferences,
  initiateRebooking,
} from '../../../services/RebookingService'
import { logUsage, RebookingInitiateFilter } from '../../../services/ShipmentService'
import {
  RebookingRequestTrackerContext,
  RebookingRequestType,
} from '../../../common/components/deliveryview-ws/RebookingRequestTracker'
import { Autocomplete } from '../../../common/components/form'
import { Checkbox as CustomCheckbox } from '../../../common/components/form/Checkbox'
import { ReasonResponse, useReasons } from '../../../common/hooks/useReasons'
import {
  ResponsiblePartyResponse,
  useResponsibleParties,
} from '../../../common/hooks/useResponsibleParties'
import { ConfirmationModal } from '../../initiatepage/confirmationmodal/ConfirmationModal'
import '../../approvepage/declinemodal/DeclineModal.css'

const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export interface HoldFormData {
  responsibleParty: ResponsiblePartyResponse | null
  reason: ReasonResponse | null
  alignedWithCarrierIndicator?: boolean
  expectedMonthOfDelivery: string
}

const emptyFormData = {
  responsibleParty: null,
  reason: null,
  expectedMonthOfDelivery: null,
  alignedWithCarrierIndicator: false,
  axios: null,
}

interface HoldModalProps {
  holdModalOpen: boolean
  onCloseRebookingModal: () => void
  selectedRows: any
  filter: RebookingInitiateFilter
  isAllRowsSelected: boolean
  totalResources: number
  creationDateTime: Date | undefined
}

export const HoldModal = ({
  holdModalOpen = false,
  onCloseRebookingModal,
  selectedRows,
  filter,
  isAllRowsSelected,
  totalResources,
  creationDateTime,
}: HoldModalProps): JSX.Element => {
  const {
    control,
    formState: { isSubmitting, isDirty, isValid, errors },
    handleSubmit,
    reset,
    resetField,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: emptyFormData,
  })
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
  const { authState } = useOktaAuth()
  const { data: parties, isSuccess: isResponsiblePartiesCallSuccess } = useResponsibleParties()
  const { data: reasons, isSuccess: isReasonsCallSuccess } = useReasons()
  const responsibleParty = useWatch({ name: 'responsibleParty', control })

  useEffect(() => {
    resetField('reason')
  }, [responsibleParty, resetField])

  const { track } = useContext(RebookingRequestTrackerContext)

  const mapData = data => {
    return {
      responsibleParty: data.responsibleParty ? data.responsibleParty.code : undefined,
      reason: data.reason ? data.reason : undefined,
      idpDate: data.idpDate ? data.idpDate : undefined,
      plannedDeliveryFromTime: data.plannedDeliveryFromTime
        ? data.plannedDeliveryFromTime
        : undefined,
      plannedDeliveryToTime: data.plannedDeliveryToTime ? data.plannedDeliveryToTime : undefined,
      shiptoCode: data.shipToCode ? data.shipToCode : undefined,
      shipToAddressLine1: data.shipToAddressLine1 ? data.shipToAddressLine1 : undefined,
      shipToAddressLine2: data.shipToAddressLine2 ? data.shipToAddressLine2 : undefined,
      shipToAddressLine3: data.shipToAddressLine3 ? data.shipToAddressLine3 : undefined,
      cityName: data.cityName ? data.cityName : undefined,
      zipCode: data.zipCode ? data.zipCode : undefined,
      trafficInstructions: data.trafficInstructions ? data.trafficInstructions : undefined,
      alignedWithCarrierIndicator: data.alignedWithCarrierIndicator
        ? data.alignedWithCarrierIndicator
        : undefined,
      rebookingType: data.rebookingType ? data.rebookingType : undefined,
      expectedMonthOfDelivery: data.expectedMonthOfDelivery
        ? data.expectedMonthOfDelivery
        : undefined,
    }
  }

  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  const handleHold = async data => {
    try {
      const response = await initiateRebooking(
        getSelectedShipmentReferences(isAllRowsSelected, filter, selectedRows),
        filter,
        mapData(data),
        authState?.accessToken?.claims?.sub,
        'HOLD',
        creationDateTime
      )
      console.log(response)
      track({
        type: RebookingRequestType.INITIATE,
        requestIds: response.rebookingRequestIds,
        workingMessage: 'Initiating hold request...',
        successMessage: 'Successfully initiated hold request',
      })
      logUsage('initiate', 'hold_rebooking')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Cannot hold: ' + JSON.stringify(error.toJSON()))
        // try to extract a message from the server
        // @ts-ignore
        const message = error.response?.data?.message
        setError('axios', {
          type: 'server',
          message: message
            ? `Cannot hold shipments: ${message}`
            : 'Cannot hold shipments for now. Please retry later or contact support!',
        })
      }
      throw error
    }

    // due to react nature, in this javascript closure, isSubmitting is still false
    closeAndResetFilters()
  }

  const closeAndResetFilters = () => {
    if (!isSubmitting) {
      reset()
      onCloseRebookingModal()
    }
  }

  const formId = 'holdModalForm'

  const filteredReasons =
    isReasonsCallSuccess && reasons
      ? responsibleParty
        ? reasons.filter(
            value =>
              value.responsibleParty ===
              (responsibleParty as unknown as ResponsiblePartyResponse).code
          )
        : reasons
      : []

  return (
    <Modal
      disableFocusTrap={true}
      isOpen={holdModalOpen}
      onDismiss={closeAndResetFilters}
      headerSlot={
        <div className="flex-row flex-wrap content-space-between justify-items-center">
          <div>
            <Text font="title-3">{`Hold packlist${selectedRows.length > 1 ? 's' : ''}`}</Text>
            {isAllRowsSelected && selectedRows.length > 1 ? (
              <Text font="body-3" className="eds-spacing--mt-16 eds-spacing--mb-24">
                <b style={{ color: 'var(--eds-color-red-50)' }}>
                  {`You are about to hold ${totalResources} packlists`}
                </b>
              </Text>
            ) : null}
          </div>
        </div>
      }
      footerSlot={
        <ButtonGroup>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            size="small"
            onClick={() => clearErrors('axios')}
            disabled={isSubmitting || !canSubmit()}
          >
            {isSubmitting && <Spinner />}
            {isSubmitting ? 'Working...' : 'Apply'}
          </Button>
          <Button
            variant="secondary"
            onClick={closeAndResetFilters}
            size="small"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <form id={formId} className="filter-form" onSubmit={handleSubmit(handleHold)}>
        <div
          className="flex-column flex-nowrap content-space-between justify-items-start"
          style={{ gap: 'var(--eds-space-16)', overflowY: 'scroll' }}
        >
          <div className="flex-row">
            <div className="responsiblePartyField">
              <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Responsible Party</Text>
              <Autocomplete
                name={'responsibleParty'}
                control={control}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={isResponsiblePartiesCallSuccess && parties ? parties : []}
                getOptionLabel={option => (option.code ? option.code : '')}
                renderOption={(props, option) => <li {...props}>{option?.code}</li>}
                style={{ maxWidth: '90%' }}
                rules={{ required: { value: true, message: 'This field is required!' } }}
              />
            </div>

            <div className="reasonField">
              <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Reason</Text>
              <Autocomplete
                name={'reason'}
                control={control}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={filteredReasons}
                getOptionLabel={option => (option.code ? option.code : '')}
                renderOption={(props, option) => <li {...props}>{option?.code}</li>}
                disabled={!responsibleParty}
                rules={{
                  required: {
                    value: responsibleParty ? true : false,
                    message: 'This field is required!',
                  },
                }}
              />
            </div>
          </div>
          <div className="flex-row">
            <div className="responsiblePartyField">
              <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Expected Month Of Delivery</Text>
              <Autocomplete
                name={'expectedMonthOfDelivery'}
                control={control}
                options={months}
                style={{ maxWidth: '90%' }}
                rules={{ required: { value: true, message: 'This field is required!' } }}
              />
            </div>
          </div>
          <div className="flex-row" style={{ minWidth: '100%', marginTop: 'var(--eds-space-16)' }}>
            <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
              <CustomCheckbox
                name="alignedWithCarrierIndicator"
                label="Aligned With Carrier"
                control={control}
              />
            </div>
          </div>
        </div>
        {isDirty && !isValid && !!errors.axios && (
          <div
            className="flex-row justify-items-center eds-spacing--mt-24"
            style={{ maxWidth: '60em' }}
          >
            <StatusIcon status="error" />
            <div className="eds-spacing--ml-16">
              <Text font="body-2" className="eds-color--danger">
                {errors.axios.message}
              </Text>
            </div>
          </div>
        )}
      </form>
      <ConfirmationModal
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
        textConfirm={'Yes'}
        textCancel={'No'}
        title={'Confirm hold'}
        body={'Are you sure you want to hold all selected packlists ?'}
        confirmationCallback={handleHold}
      />
    </Modal>
  )
}
