import axios from 'axios'
import Config from '../config/Config'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'
import { CarrierHubResponse } from '../common/hooks/useCarrierHubList'

const carrierConfigClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.CARRIER_CONFIG_URL}/${Config.BASEPATH_CARRIER_CONFIG}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export const fetchCarriers = async (): Promise<CarrierHubResponse[]> => {
  return carrierConfigClient.get(`/authorized/carriers/v1`).then(response => response.data)
}

export const fetchAllCarriers = async (): Promise<CarrierHubResponse[]> => {
  return carrierConfigClient.get(`/carriers/v1`).then(response => response.data)
}
