import { useQuery, UseQueryResult } from 'react-query'
import { fetchCountries } from '../../services/RebookingService'

export interface CountryResponse {
  code: string
  name: string
}

export const useCountriesList = (): UseQueryResult<CountryResponse[], any> => {
  return useQuery(['countries'], fetchCountries, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
