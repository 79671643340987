import { Spinner, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import Config from '../../../config/Config'
import { uid } from '../../utils/datautils/uid'
import { useWebSocket } from '../../hooks/useWebSocket'
import { NotificationContext } from '../../../pages/notification/NotificationContext'

export enum RebookingRequestType {
  INITIATE,
  VALIDATE,
}

export interface RebookingRequest {
  type: RebookingRequestType
  requestIds: string[]
  workingMessage: string
  successMessage: string
}

export interface RebookingRequestUpdate {
  requestId: string
}

export interface RebookingRequestTrackerContextValue {
  rebookingRequests: Record<string, RebookingRequest>
  track: (request: RebookingRequest) => void
  update: (id: string, rebookingUpdate: RebookingRequestUpdate) => void
  untrack: (id: string) => void
}

const doNothing = () => {}

export const RebookingRequestTrackerContext = createContext<RebookingRequestTrackerContextValue>({
  rebookingRequests: {},
  track: doNothing,
  update: doNothing,
  untrack: doNothing,
})

export const useRebookingRequestTrackingLogic = ({ rebookingRequests, update }) => {
  // websocket state update
  const { authState } = useOktaAuth()
  const socketUrl = `${Config.WS_URL}?email=${authState?.accessToken?.claims?.sub?.toLowerCase()}`
  const { lastMessage } = useWebSocket(socketUrl, { authState })

  useEffect(() => {
    const requestId: string =
      lastMessage?.data.rebookingRequestId ?? lastMessage?.data.validationRequestId
    if (requestId) {
      const trackId = Object.keys(rebookingRequests).find(k =>
        rebookingRequests[k].requestIds.includes(requestId)
      )
      if (trackId && ['INITIATING', 'VALIDATED'].includes(lastMessage?.data.status)) {
        update(trackId, { requestId })
      }
    }
  }, [lastMessage, rebookingRequests, update])

  return {
    lastMessage,
    rebookingRequests,
    update,
  }
}

const actions = {
  track: 'track',
  update: 'update',
  untrack: 'untrack',
}

// state logic
const rebookingRequestReducers = (state, action) => {
  switch (action.type) {
    case actions.track:
      const id = `rebooking-${uid()}`
      return {
        ...state,
        [id]: action.data,
      }
    case actions.update:
      if (!state[action.id]) return state

      const newRequestIds =
        state[action.id]?.requestIds?.filter(id => id !== action.data.requestId) ?? []
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          requestIds: newRequestIds,
        },
      }
    case actions.untrack:
      if (!state[action.id]) return state

      const { [action.id]: removed, ...rest } = state
      return rest
  }
}

export const RebookingRequestTracker = ({ children }) => {
  // state
  const [rebookingRequests, dispatch] = useReducer(rebookingRequestReducers, {})

  // context
  const track = useCallback(
    (request: RebookingRequest) => dispatch({ type: actions.track, data: request }),
    [dispatch]
  )
  const update = useCallback(
    (id: string, rebookingUpdate: RebookingRequestUpdate) =>
      dispatch({ type: actions.update, id, data: rebookingUpdate }),
    [dispatch]
  )
  const untrack = useCallback((id: string) => dispatch({ type: actions.untrack, id }), [dispatch])
  const providedValue = useMemo(
    () => ({
      rebookingRequests,
      track,
      update,
      untrack,
    }),
    [rebookingRequests, track, update, untrack]
  )

  // showing notifications logic
  const { messages, pushNotification, removeNotification } = useContext(NotificationContext)
  useEffect(() => {
    Object.keys(rebookingRequests).forEach(trackId => {
      if (!messages.find(it => it.id === trackId)) {
        pushNotification({
          id: trackId,
          content: (
            <div className="flex-row justify-items-center" style={{ gap: '16px' }}>
              <Spinner />
              <Text>{rebookingRequests[trackId].workingMessage}</Text>
            </div>
          ),
          status: 'info',
          onDismiss: untrack,
        })
      }
      if (rebookingRequests[trackId].requestIds.length === 0) {
        // when there is no requestIds to track anymore, we remove the notification and add an auto dismissable one
        removeNotification(trackId)
        pushNotification({
          id: trackId,
          content: <Text>{rebookingRequests[trackId].successMessage}</Text>,
          status: 'success',
          autoDismissDuration: 5000,
          hideDismiss: true,
        })
        untrack(trackId)
      }
    })
  }, [rebookingRequests, messages, pushNotification, removeNotification, untrack])

  useRebookingRequestTrackingLogic(providedValue)

  return (
    <RebookingRequestTrackerContext.Provider value={providedValue}>
      {children}
    </RebookingRequestTrackerContext.Provider>
  )
}
