import { useMemo } from 'react'
import { SelectAllCell, SelectAllHeader } from '../../../../common/utils/SelectAllServerRows'
import { useServerDataTable } from '../../../../common/components/table'
import { useAllServerRowsSelect } from '../../../../common/hooks/useAllServerRowsSelect'
import { date } from '../../../../common/utils/datautils/formatter'

export const useRebookingReasonConfigTable = (
  queryState,
  toggleSortBy,
  isFetching,
  isSuccess,
  isError,
  data
) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        width: 20,
        Header: props => <SelectAllHeader {...props} />,
        Cell: props => <SelectAllCell {...props} />,
        disableSortBy: true,
      },
      {
        Header: () => <div>Rebooking Reason</div>,
        accessor: 'code',
        Cell: item => <div>{item.value}</div>,
        width: 60,
        disableSortBy: true,
      },
      {
        Header: () => <div>Created By</div>,
        accessor: 'createdBy',
        Cell: item => <div>{item.value}</div>,
        width: 60,
        disableSortBy: true,
      },
      {
        Header: () => <div>Created On</div>,
        accessor: 'createdOn',
        Cell: item => <div>{date(item.value, true)}</div>,
        width: 60,
        disableSortBy: true,
      },
      {
        Header: () => <div>Last Updated By</div>,
        accessor: 'lastUpdatedBy',
        Cell: item => <div>{item.value}</div>,
        width: 60,
        disableSortBy: true,
      },
      {
        Header: () => <div>Last Updated On</div>,
        accessor: 'lastUpdatedOn',
        Cell: item => <div>{date(item.value, true)}</div>,
        width: 60,
        disableSortBy: true,
      },
    ],
    []
  )

  return useServerDataTable({
    queryState,
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
    overrides: {
      toggleSortBy,
    },
    extraPlugins: [useAllServerRowsSelect],
  })
}
