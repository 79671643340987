import { Button, ButtonGroup, Modal, Spinner, StatusIcon, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import { useContext } from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import { useForm } from 'react-hook-form'
import {
  approveDeclineRebooking,
  getSelectedShipmentReferences,
} from '../../../services/RebookingService'
import { logUsage, RebookingApproveFilter } from '../../../services/ShipmentService'
import {
  RebookingRequestTrackerContext,
  RebookingRequestType,
} from '../../../common/components/deliveryview-ws/RebookingRequestTracker'

interface ApproveModalInput {
  approveModalOpen: boolean
  setApproveModalOpen: (data: boolean) => void
  selectedRows: any
  isAllRowsSelected: boolean
  totalResources?: number
  filter: RebookingApproveFilter
  openRebookingCreationDateTime: Date | undefined
}

export const ApproveModal = ({
  approveModalOpen,
  setApproveModalOpen,
  selectedRows,
  isAllRowsSelected,
  totalResources = 0,
  filter,
  openRebookingCreationDateTime,
}: ApproveModalInput): JSX.Element => {
  const {
    formState: { isValid, isSubmitting, errors },
    handleSubmit,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: { axios: null },
  })
  const { authState } = useOktaAuth()

  const numberOfRebookings = isAllRowsSelected ? totalResources : selectedRows.length

  const { track } = useContext(RebookingRequestTrackerContext)

  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  const handleApprove = async () => {
    try {
      const response = await approveDeclineRebooking(
        getSelectedShipmentReferences(isAllRowsSelected, filter, selectedRows),
        filter,
        undefined,
        authState?.accessToken?.claims?.sub,
        'APPROVED',
        openRebookingCreationDateTime
      )
      console.log(response)
      track({
        type: RebookingRequestType.VALIDATE,
        requestIds: response.validationRequestIds,
        workingMessage: 'Approving rebookings...',
        successMessage: 'Successfully approved rebookings',
      })
      logUsage('approve', 'approve_rebooking')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Cannot approve rebookings: ' + JSON.stringify(error.toJSON()))
        // try to extract a message from the server
        // @ts-ignore
        const message = error.response?.data?.message
        setError('axios', {
          type: 'server',
          message: message
            ? `Cannot approve rebookings: ${message}`
            : 'Cannot approve rebookings for now. Please retry later or contact support!',
        })
      }
      throw error
    }

    // due to react nature, in this javascript closure, isSubmitting is still false
    closeAndReset()
  }

  const closeAndReset = () => {
    if (!isSubmitting) {
      reset()
      setApproveModalOpen(false)
    }
  }

  const formId = 'approveRebookingModalForm'

  return (
    <>
      <Modal
        isOpen={approveModalOpen}
        disableFocusTrap={true}
        onDismiss={closeAndReset}
        headerSlot={
          <div className="flex-row flex-wrap content-space-between justify-items-center">
            <div>
              <Text font="title-3">Approve a rebooking / hold</Text>
              {numberOfRebookings > 0 ? (
                <Text font="body-3" className="eds-spacing--mt-16 eds-spacing--mb-24">
                  <b style={{ color: 'var(--eds-color-red-50)' }}>
                    {`You are about to approve ${numberOfRebookings} rebooking request(s)`}
                  </b>
                </Text>
              ) : null}
            </div>
          </div>
        }
        footerSlot={
          <ButtonGroup>
            <Button
              variant="primary"
              type="submit"
              form={formId}
              size="small"
              onClick={() => clearErrors('axios')}
              disabled={isSubmitting || !canSubmit()}
            >
              {isSubmitting && <Spinner />}
              {isSubmitting ? 'Working...' : 'Apply'}
            </Button>
            <Button
              onClick={closeAndReset}
              size="small"
              variant="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <form
          id={formId}
          className="filter-form"
          onSubmit={handleSubmit(handleApprove)}
          style={{ minWidth: '60rem' }}
        >
          {!isValid && !!errors.axios && (
            <div
              className="flex-row justify-items-center eds-spacing--mt-24"
              style={{ maxWidth: '60rem' }}
            >
              <StatusIcon status="error" />
              <div className="eds-spacing--ml-16">
                <Text font="body-2" className="eds-color--danger">
                  {errors.axios.message}
                </Text>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </>
  )
}
