import { AxiosInstance } from 'axios'
import { getOktaAuth } from './Okta'

export const setupAxiosOktaInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  const onRequestSuccess = async config => {
    const oktaAuth = getOktaAuth()

    if (await oktaAuth.isAuthenticated()) {
      config.headers.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
    }

    return config
  }
  const onResponseSuccess = response => response
  const onResponseError = err => {
    const status = err.status || err.response.status
    if (status === 403 || status === 401) {
      const oktaAuth = getOktaAuth()
      oktaAuth.signInWithRedirect()
    }
    return Promise.reject(err)
  }
  axiosInstance?.interceptors.request.use(onRequestSuccess)
  axiosInstance?.interceptors.response.use(onResponseSuccess, onResponseError)
  return axiosInstance
}
