import React from 'react'
import { Checkbox as EdsCheckbox } from '@nike/eds'
import { useController, UseControllerProps } from 'react-hook-form'

export interface CheckboxProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof EdsCheckbox>,
      'id' | 'name' | 'checked' | 'defaultValue' | 'onBlur'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
}

export const Checkbox = ({ name, control, rules = undefined, ...rest }: CheckboxProps) => {
  const {
    field: { onChange, value, ref, ...fieldProps },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <EdsCheckbox
      id={name}
      {...fieldProps}
      checked={value ? true : false}
      onChange={e => {
        onChange(e.target.checked)
      }}
      {...rest}
    />
  )
}
