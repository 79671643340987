import './TooltipAutomaticApprovalCondition.css'

export const TooltipAutomaticApprovalCondition = ({ data }) => {
  return (
    <table className="tooltip-rebooking-content">
      <thead>
        <th colSpan={2}>
          <td>Condition Details</td>
        </th>
      </thead>
      <tbody>
        {data.deadlineTimeOfDay && (
          <tr>
            <td>Deadline Time of Day</td>
            <td>{data.deadlineTimeOfDay}</td>
          </tr>
        )}
        {data.actualShipDateCondition && (
          <tr>
            <td>Actual Ship Date Condition</td>
            <td>
              {data.actualShipDateCondition === 'ACTUAL_SHIP_DATE_BLANK_CHECK'
                ? 'Actual Ship Date is Blank Check'
                : 'PGI/Actual Ship Date Check'}
            </td>
          </tr>
        )}
        {data.minDaysBeforeIDPPassed !== undefined && (
          <tr>
            <td>Minimal number of days before IDP Passed</td>
            <td>{data.minDaysBeforeIDPPassed}</td>
          </tr>
        )}
        {data.minDaysBeforeIDPNotPassed !== undefined && (
          <tr>
            <td>Minimal number of days before IDP Not Passed</td>
            <td>{data.minDaysBeforeIDPNotPassed}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default TooltipAutomaticApprovalCondition
