export const removeEmptyAndDuplicateFields = obj => {
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length !== 0) {
          return [key, value.filter(e => e).filter((v, i, a) => a.indexOf(v) === i)]
        }
        return [key, value !== '' ? value : undefined]
      })
      .filter(([key, value]) => {
        return (
          (Array.isArray(value) && value.length !== 0) ||
          (typeof value === 'string' && value === '') ||
          value !== null ||
          true
        )
      })
  )
}
