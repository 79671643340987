import { Button, ButtonGroup, Link, Modal, Spinner, StatusIcon, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'
import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import {
  getSelectedShipmentReferences,
  initiateRebooking,
} from '../../../services/RebookingService'
import { logUsage, RebookingInitiateFilter } from '../../../services/ShipmentService'
import {
  RebookingRequestTrackerContext,
  RebookingRequestType,
} from '../../../common/components/deliveryview-ws/RebookingRequestTracker'
import { Autocomplete, TextField } from '../../../common/components/form'
import { Checkbox as CustomCheckbox } from '../../../common/components/form/Checkbox'
import { useReasons } from '../../../common/hooks/useReasons'
import {
  ResponsiblePartyResponse,
  useResponsibleParties,
} from '../../../common/hooks/useResponsibleParties'
import './RebookingModal.css'
import { AutoApprovalRulesModal } from '../../../common/components/autoapprovalrulesmodal/AutoApprovalRulesModal'

export enum DateTimePickerType {
  DATE = 'date',
  TIME = 'time',
}

const emptyFormData = {
  responsibleParty: null,
  reason: null,
  idpDate: '',
  plannedDeliveryFromTime: '',
  plannedDeliveryToTime: '',
  shipToCode: '',
  shipToAddressLine1: '',
  shipToAddressLine2: '',
  shipToAddressLine3: '',
  cityName: '',
  zipCode: '',
  trafficInstructions: '',
  alignedWithCarrierIndicator: false,
  rebookingType: '',
  expectedMonthOfDelivery: '',
  axios: null,

  isNewIdpDate: false,
  isNewIdpWindow: false,
  isNewShipToNumber: false,
  isNewShipToAddress: false,
  isNewTrafficInstructions: false,
  isNothingChecked: false,
}

export interface RebookingModalProps {
  rebookingModalOpen: boolean
  onCloseRebookingModal: () => void
  selectedRows: any
  isAllRowsSelected: boolean
  totalResources: number
  filter: RebookingInitiateFilter
  creationDateTime: Date | undefined
}

const useFormFieldsLogic = ({ control, register, resetField, clearErrors, setValue, trigger }) => {
  // form watch fields
  const responsibleParty: ResponsiblePartyResponse | null = useWatch({
    name: 'responsibleParty',
    control,
  })
  const reason = useWatch({ name: 'reason', control })
  const plannedDeliveryFromTime = useWatch({ name: 'plannedDeliveryFromTime', control })
  const plannedDeliveryToTime = useWatch({ name: 'plannedDeliveryToTime', control })
  const isNewIdpDate = useWatch({ name: 'isNewIdpDate', control })
  const isNewIdpWindow = useWatch({ name: 'isNewIdpWindow', control })
  const isNewShipToNumber = useWatch({ name: 'isNewShipToNumber', control })
  const isNewShipToAddress = useWatch({ name: 'isNewShipToAddress', control })
  const isNewTrafficInstructions = useWatch({ name: 'isNewTrafficInstructions', control })

  useEffect(() => {
    resetField('reason')
  }, [responsibleParty, resetField])

  useEffect(() => {
    if (!isNewIdpDate) {
      resetField('idpDate')
    }
  }, [isNewIdpDate, clearErrors, resetField, setValue])

  useEffect(() => {
    if (!isNewIdpWindow) {
      resetField('plannedDeliveryFromTime')
      resetField('plannedDeliveryToTime')

      clearErrors('plannedDeliveryFromTime')
      clearErrors('plannedDeliveryToTime')
    }
  }, [isNewIdpWindow, clearErrors, resetField, setValue])

  useEffect(() => {
    setValue('isNewShipToAddress', isNewShipToNumber)

    if (!isNewShipToNumber) {
      resetField('shipToCode')
      resetField('shipToAddressLine1')
      resetField('shipToAddressLine2')
      resetField('shipToAddressLine3')
      resetField('zipCode')
      resetField('cityName')

      clearErrors('shipToCode')
      clearErrors('shipToAddressLine1')
      clearErrors('zipCode')
      clearErrors('cityName')
    }
  }, [isNewShipToNumber, clearErrors, resetField, setValue])

  useEffect(() => {
    if (!isNewShipToAddress) {
      resetField('shipToAddressLine1')
      resetField('shipToAddressLine2')
      resetField('shipToAddressLine3')
      resetField('zipCode')
      resetField('cityName')

      clearErrors('shipToAddressLine1')
      clearErrors('zipCode')
      clearErrors('cityName')
    }
  }, [isNewShipToAddress, clearErrors, resetField, setValue])

  useEffect(() => {
    if (!isNewTrafficInstructions) {
      resetField('trafficInstructions')

      clearErrors('trafficInstructions')
    }
  }, [isNewTrafficInstructions, clearErrors, resetField, setValue])

  // register a dummy field to check that at least one checkbox is checked
  register('isNothingChecked', {
    validate: _ => {
      if (!!responsibleParty && !!reason) {
        const isValid =
          isNewIdpDate ||
          isNewIdpWindow ||
          isNewShipToNumber ||
          isNewShipToAddress ||
          isNewTrafficInstructions
        return (
          isValid ||
          'At least one of the following checkboxes must be selected: New IDP Date, New IDP Time Window, New Ship To Number, New Ship To Address or New Traffic Instructions'
        )
      } else {
        return true
      }
    },
  })

  useEffect(() => {
    const validate = async () => {
      await trigger('isNothingChecked')
    }
    validate()
  }, [
    isNewIdpDate,
    isNewIdpWindow,
    isNewShipToNumber,
    isNewShipToAddress,
    isNewTrafficInstructions,
    trigger,
  ])

  return {
    responsibleParty,
    reason,
    plannedDeliveryFromTime,
    plannedDeliveryToTime,
    isNewIdpDate,
    isNewIdpWindow,
    isNewShipToNumber,
    isNewShipToAddress,
    isNewTrafficInstructions,
  }
}

export const RebookingModal = ({
  rebookingModalOpen = false,
  onCloseRebookingModal,
  selectedRows,
  isAllRowsSelected,
  totalResources,
  filter,
  creationDateTime,
}: RebookingModalProps): JSX.Element => {
  const {
    formState: { isDirty, isValid, errors, isSubmitting },
    control,
    register,
    handleSubmit,
    reset,
    resetField,
    clearErrors,
    setValue,
    trigger,
    setError,
  } = useForm({
    defaultValues: emptyFormData,
  })
  const {
    responsibleParty,
    plannedDeliveryFromTime,
    plannedDeliveryToTime,
    isNewIdpDate,
    isNewIdpWindow,
    isNewShipToNumber,
    isNewShipToAddress,
    isNewTrafficInstructions,
  } = useFormFieldsLogic({ control, register, resetField, clearErrors, setValue, trigger })

  const { authState } = useOktaAuth()
  const { data: parties, isSuccess: isResponsiblePartiesCallSuccess } = useResponsibleParties()
  const { data: reasons, isSuccess: isReasonsCallSuccess } = useReasons()
  const [autoApprovalRulesModalOpen, setAutoApprovalRulesModalOpen] = useState<boolean>(false)
  const { track } = useContext(RebookingRequestTrackerContext)

  const mapData = data => {
    return {
      responsibleParty: data.responsibleParty ? data.responsibleParty.code : undefined,
      reason: data.reason ? data.reason : undefined,
      idpDate: data.idpDate ? data.idpDate : undefined,
      plannedDeliveryFromTime: data.plannedDeliveryFromTime
        ? data.plannedDeliveryFromTime
        : undefined,
      plannedDeliveryToTime: data.plannedDeliveryToTime ? data.plannedDeliveryToTime : undefined,
      shipToCode: data.shipToCode ? data.shipToCode : undefined,
      shipToAddressLine1: data.shipToAddressLine1 ? data.shipToAddressLine1 : undefined,
      shipToAddressLine2: data.shipToAddressLine2 ? data.shipToAddressLine2 : undefined,
      shipToAddressLine3: data.shipToAddressLine3 ? data.shipToAddressLine3 : undefined,
      cityName: data.cityName ? data.cityName : undefined,
      zipCode: data.zipCode ? data.zipCode : undefined,
      trafficInstructions: data.trafficInstructions ? data.trafficInstructions : undefined,
      alignedWithCarrierIndicator: data.alignedWithCarrierIndicator
        ? data.alignedWithCarrierIndicator
        : undefined,
      rebookingType: data.rebookingType ? data.rebookingType : undefined,
      expectedMonthOfDelivery: data.expectedMonthOfDelivery
        ? data.expectedMonthOfDelivery
        : undefined,
    }
  }

  const canSubmit = () => {
    return Object.keys(errors ?? {}).filter(s => s !== 'axios').length === 0
  }

  const handleRebooking = async data => {
    try {
      const response = await initiateRebooking(
        getSelectedShipmentReferences(isAllRowsSelected, filter, selectedRows),
        filter,
        mapData(data),
        authState?.accessToken?.claims?.sub,
        'REBOOKING',
        creationDateTime
      )
      console.log(response)
      track({
        type: RebookingRequestType.INITIATE,
        requestIds: response.rebookingRequestIds,
        workingMessage: 'Initiating rebooking request...',
        successMessage: 'Successfully initiated rebooking request',
      })
      logUsage('initiate', 'initiate_rebooking')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Cannot rebook: ' + JSON.stringify(error.toJSON()))
        // try to extract a message from the server
        // @ts-ignore
        const message = error.response?.data?.message
        setError('axios', {
          type: 'server',
          message: message
            ? `Cannot rebook shipments: ${message}`
            : 'Cannot rebook shipments for now. Please retry later or contact support!',
        })
      }
      throw error
    }

    // due to react nature, in this javascript closure, isSubmitting is still false
    closeAndResetFilters()
  }

  const closeAndResetFilters = () => {
    if (!isSubmitting) {
      reset()
      onCloseRebookingModal()
    }
  }

  const handleCloseAutoApprovalRulesModalForm = () => {
    setAutoApprovalRulesModalOpen(false)
  }

  const formId = 'rebookingModalForm'

  const filteredReasons =
    isReasonsCallSuccess && reasons
      ? responsibleParty
        ? reasons.filter(
            value => value.responsibleParty === (responsibleParty as ResponsiblePartyResponse).code
          )
        : reasons
      : []

  return (
    <>
      <Modal
        disableFocusTrap={true}
        isOpen={rebookingModalOpen}
        onDismiss={closeAndResetFilters}
        headerSlot={
          <div className="flex-row flex-wrap content-space-between justify-items-center">
            <div>
              <Text font="title-3">{`Rebook packlist${selectedRows.length > 1 ? 's' : ''}`}</Text>
              {isAllRowsSelected && selectedRows.length > 1 ? (
                <Text font="body-3" className="eds-spacing--mt-16 eds-spacing--mb-24">
                  <b style={{ color: 'var(--eds-color-red-50)' }}>
                    {`You are about to rebook ${totalResources} packlists`}
                  </b>{' '}
                </Text>
              ) : null}
            </div>
          </div>
        }
        footerSlot={
          <div className="flex-row flex-nowrap content-space-between justify-items-center">
            <ButtonGroup>
              <Button
                variant="primary"
                type="submit"
                form={formId}
                size="small"
                onClick={() => {
                  clearErrors('axios')
                }}
                disabled={isSubmitting || !canSubmit()}
              >
                {isSubmitting && <Spinner />}
                {isSubmitting ? 'Working...' : 'Apply'}
              </Button>
              <Button
                variant="secondary"
                onClick={closeAndResetFilters}
                size="small"
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </ButtonGroup>
            <Link
              font="subtitle-1"
              as="p"
              onClick={() => {
                setAutoApprovalRulesModalOpen(true)
              }}
              style={{ marginRight: 'var(--eds-space-8)' }}
            >
              Auto Approval Rules
            </Link>
          </div>
        }
      >
        <form id={formId} className="filter-form" onSubmit={handleSubmit(handleRebooking)}>
          <div
            className="flex-column flex-nowrap content-space-between justify-items-start"
            style={{ gap: 'var(--eds-space-8)' }}
          >
            <div className="flex-row">
              <div className="responsiblePartyField">
                <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Responsible Party </Text>

                <Autocomplete
                  name={'responsibleParty'}
                  control={control}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={isResponsiblePartiesCallSuccess && parties ? parties : []}
                  getOptionLabel={option => (option.code ? option.code : '')}
                  renderOption={(props, option) => <li {...props}>{option?.code}</li>}
                  style={{ maxWidth: '90%' }}
                  rules={{ required: { value: true, message: 'This field is required!' } }}
                />
              </div>

              <div className="reasonField">
                <Text style={{ marginBottom: 'var(--eds-space-8)' }}>Reason </Text>

                <Autocomplete
                  name={'reason'}
                  control={control}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={filteredReasons}
                  getOptionLabel={option => (option.code ? option.code : '')}
                  renderOption={(props, option) => <li {...props}>{option?.code}</li>}
                  disabled={!responsibleParty}
                  rules={{
                    required: {
                      value: responsibleParty ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <div className="flex-grow-1 idpDateCheck">
                <CustomCheckbox name="isNewIdpDate" label="New IDP Date" control={control} />
              </div>
              <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
                <TextField
                  name={'idpDate'}
                  control={control}
                  type={'date'}
                  min={format(new Date(), 'yyyy-MM-dd')}
                  onKeyDown={e => e.preventDefault()}
                  label="IDP Date"
                  hideLabel={true}
                  disabled={!isNewIdpDate}
                  rules={{
                    required: {
                      value: isNewIdpDate ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <div className="flex-grow-1 flex-shrink-0 idpWindowCheck">
                <CustomCheckbox
                  name="isNewIdpWindow"
                  label="New IDP Time Window"
                  control={control}
                />
              </div>
              <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
                <div
                  className="flex-row flex-wrap content-start justify-items-center"
                  style={{ gap: 'var(--eds-space-8)' }}
                >
                  <div className="flex-grow-1">
                    <TextField
                      name={'plannedDeliveryFromTime'}
                      control={control}
                      label="From"
                      hideLabel={true}
                      type={'time'}
                      placeholder="From"
                      aria-label={`From planneddeliveryfromtime`}
                      disabled={!isNewIdpWindow}
                      max={plannedDeliveryToTime}
                      rules={{
                        required: {
                          value: isNewIdpWindow ? true : false,
                          message: 'This field is required!',
                        },
                        max: {
                          value: plannedDeliveryToTime,
                          message: `The value must be ${plannedDeliveryToTime} or earlier`,
                        },
                      }}
                    />
                  </div>
                  <div className="flex-grow-1">
                    <TextField
                      name={'plannedDeliveryToTime'}
                      control={control}
                      label="To"
                      hideLabel={true}
                      type={'time'}
                      placeholder="To"
                      aria-label={`To planneddeliverytotime`}
                      disabled={!isNewIdpWindow}
                      min={plannedDeliveryFromTime}
                      rules={{
                        required: {
                          value: isNewIdpWindow ? true : false,
                          message: 'This field is required!',
                        },
                        min: {
                          value: plannedDeliveryFromTime,
                          message: `The value must be ${plannedDeliveryFromTime} or later`,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <div className="flex-grow-1 idpShipToCheck">
                <CustomCheckbox
                  name="isNewShipToNumber"
                  label="New Ship To Number"
                  control={control}
                />
              </div>

              <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
                <TextField
                  name="shipToCode"
                  type={'text'}
                  control={control}
                  label="Ship to Number"
                  hideLabel={true}
                  placeholder="Ship To Number"
                  disabled={!isNewShipToNumber}
                  rules={{
                    required: {
                      value: isNewShipToNumber ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <div className="flex-grow-1 idpShipToAddressCheck">
                <CustomCheckbox
                  name="isNewShipToAddress"
                  label="New Ship To Address"
                  control={control}
                  disabled={isNewShipToNumber}
                />
              </div>
              <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
                <TextField
                  name="shipToAddressLine1"
                  className="addressTextField"
                  type={'text'}
                  control={control}
                  label="Address Line 1"
                  hideLabel={true}
                  placeholder="Address Line 1"
                  disabled={!isNewShipToAddress}
                  rules={{
                    required: {
                      value: isNewShipToNumber || isNewShipToAddress ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />

                <TextField
                  name="shipToAddressLine2"
                  className="addressTextField"
                  type={'text'}
                  control={control}
                  label="Address Line 2"
                  hideLabel={true}
                  placeholder="Address Line 2"
                  disabled={!isNewShipToAddress}
                />

                <TextField
                  name="shipToAddressLine3"
                  className="addressTextField"
                  type={'text'}
                  control={control}
                  label="Address Line 3"
                  hideLabel={true}
                  placeholder="Address Line 3"
                  disabled={!isNewShipToAddress}
                />

                <TextField
                  name="zipCode"
                  className="addressTextField"
                  type={'text'}
                  control={control}
                  label="Zip Code"
                  hideLabel={true}
                  placeholder="Zip Code"
                  disabled={!isNewShipToAddress}
                  rules={{
                    required: {
                      value: isNewShipToNumber || isNewShipToAddress ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />

                <TextField
                  name="cityName"
                  className="addressTextField"
                  type={'text'}
                  control={control}
                  label="City Name"
                  hideLabel={true}
                  placeholder="City Name"
                  disabled={!isNewShipToAddress}
                  rules={{
                    required: {
                      value: isNewShipToNumber || isNewShipToAddress ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <div className="flex-grow-1 idpTrafficInstructionsCheck">
                <CustomCheckbox
                  name="isNewTrafficInstructions"
                  label="New Traffic Instructions"
                  control={control}
                />
              </div>
              <div className="flex-grow-1" style={{ minWidth: '25vw' }}>
                <TextField
                  name="trafficInstructions"
                  type={'text'}
                  control={control}
                  label="Traffic Instructions"
                  hideLabel={true}
                  placeholder="Traffic Instructions"
                  disabled={!isNewTrafficInstructions}
                  rules={{
                    required: {
                      value: isNewTrafficInstructions ? true : false,
                      message: 'This field is required!',
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-32)' }}>
              <CustomCheckbox
                name="alignedWithCarrierIndicator"
                label="Aligned With Carrier"
                control={control}
              />
            </div>
            {isDirty && !isValid && (!!errors.isNothingChecked || !!errors.axios) && (
              <div
                className="flex-row justify-items-center eds-spacing--mt-24"
                style={{ maxWidth: '60em' }}
              >
                <StatusIcon status="error" />
                <div className="eds-spacing--ml-16">
                  {!!errors.isNothingChecked && (
                    <Text font="body-2" className="eds-color--danger">
                      {errors.isNothingChecked.message}
                    </Text>
                  )}
                  {!!errors.axios && (
                    <Text font="body-2" className="eds-color--danger">
                      {errors.axios.message}
                    </Text>
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
        <AutoApprovalRulesModal
          autoApprovalRulesModalOpen={autoApprovalRulesModalOpen}
          onCloseAutoApprovalRulesModal={handleCloseAutoApprovalRulesModalForm}
        />
      </Modal>
    </>
  )
}
