import { useQuery, UseQueryResult } from 'react-query'
import { fetchHistory } from '../../services/RebookingService'

export interface RebookingHistoryResponse {
  status: string
  type: string
  requestorUser: string
  validatorUser: string
  validatedDateTime: string
  declineReasonCode: string
  extraInfo: string
  responsiblePartyCode: string
  reasonCode: string
  iDPPlannedTimestamp: string
  plannedDeliveryFromTime: string
  plannedDeliveryToTime: string
  shipToCode: string
  addressLine1Text: string
  addressLine2Text: string
  addressLine3Text: string
  zipCode: string
  cityName: string
  deliveryInstructionText: string
  alignedWithCarrierIndicator: string
  expectedMonthOfDelivery: string
  creationDateTime: string
}

export const useRebookingHistory = (
  shipmentReference
): UseQueryResult<RebookingHistoryResponse[], any> => {
  return useQuery(['rebookingHistory', shipmentReference], () => fetchHistory(shipmentReference), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
