import { Text, Tooltip } from '@nike/eds'
import { IndeterminateCheckbox } from '../hooks/useAllServerRowsSelect'

const defaultIsSelectCheckboxDisabled = row => false

export const SelectAllHeader = ({
  totalResources,
  getToggleAllServerRowsSelectedProps,
  tooltipText,
  maxResources = 10_000,
}) => {
  const wrapper = children => {
    const text = tooltipText ?? `Can't select more than ${maxResources} rows`
    return totalResources > maxResources ? (
      <Tooltip bodySlot={<Text as="p">{text}</Text>} isDark={true} placement={'right'}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    )
  }
  return wrapper(
    <IndeterminateCheckbox
      {...getToggleAllServerRowsSelectedProps()}
      disabled={totalResources > maxResources}
    />
  )
}

export const SelectAllCell = ({
  row,
  state: { isAllServerRowsSelected },
  isSelectCheckboxDisabled = defaultIsSelectCheckboxDisabled,
}) => {
  const checked = isAllServerRowsSelected
    ? !isSelectCheckboxDisabled(row)
    : row.getToggleRowSelectedProps().checked
  const disabled = isAllServerRowsSelected || isSelectCheckboxDisabled(row)
  return (
    <div>
      <IndeterminateCheckbox
        {...row.getToggleRowSelectedProps()}
        checked={checked}
        disabled={disabled}
      />
    </div>
  )
}
