const Config = {
  VERSION: process.env.REAC_APP_VERSION,
  NAME: process.env.REACT_APP_NAME,
  ENV: process.env.REACT_APP_ENV,
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  REBOOKING_SERVICE_URL: process.env.REACT_APP_REBOOKING_SERVICE_URL,
  CARRIER_CONFIG_URL: process.env.REACT_APP_CARRIER_CONFIG_URL,
  USER_PREFERENCES_URL: process.env.REACT_APP_USER_PREFERENCES_URL,
  BASEPATH: process.env.REACT_APP_BASEPATH,
  BASEPATH_CARRIER_CONFIG: process.env.REACT_APP_BASEPATH_CARRIER_CONFIG,
  BASEPATH_USER_PREFERENCES_CONFIG: process.env.REACT_APP_BASEPATH_USER_PREFERENCES_CONFIG,
  OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER,
  WS_URL: process.env.REACT_APP_WS_URL,
}

export default Config
