import { useMemo } from 'react'
import { SelectAllCell, SelectAllHeader } from '../../../common/utils/SelectAllServerRows'
import { useServerDataTable } from '../../../common/components/table'
import { useAllServerRowsSelect } from '../../../common/hooks/useAllServerRowsSelect'
import { Tooltip, Text, Icon } from '@nike/eds'
import { date } from '../../../common/utils/datautils/formatter'
import TooltipRebookingContent from '../../../common/components/tooltiprebookingcontent/TooltipRebookingContent'
import clsx from 'clsx'

export const useApproveRebookingTable = (
  queryState,
  gotoPage,
  setPageSize,
  toggleSortBy,
  isFetching,
  isSuccess,
  isError,
  data
) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        width: 20,
        Header: props => <SelectAllHeader {...props} />,
        Cell: props => <SelectAllCell {...props} />,
      },
      {
        id: 'expander',
        Header: () => null,
        Cell: ({ row }) => {
          return (
            <Tooltip
              bodySlot={<Text>{row.isExpanded ? 'Hide' : 'Show'} history</Text>}
              placement={'right'}
              isDark={true}
            >
              <Icon
                {...row.getToggleRowExpandedProps()}
                name={row.isExpanded ? 'CaretUp' : 'History'}
              />
            </Tooltip>
          )
        },
        width: 20,
      },
      {
        Header: () => <div>Packlist</div>,
        accessor: 'shipmentReference',
        Cell: item => (
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '90px',
            }}
            title={item.value}
          >
            {item.value}
          </div>
        ),
        width: 90,
      },
      {
        Header: () => <div>Ship To Number</div>,
        accessor: 'shipToCode',
        Cell: item => <div>{item.value}</div>,
        width: 120,
      },
      {
        Header: () => <div>Ship To Name</div>,
        accessor: 'shipToName',
        Cell: item => <div>{item.value}</div>,
        width: 200,
      },
      {
        Header: () => <div>Ship To Country</div>,
        accessor: 'shipToCountry',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Carrier</div>,
        accessor: 'carrierAccountCode',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Hub</div>,
        accessor: 'hubCode',
        Cell: item => <div>{item.value}</div>,
        width: 60,
      },
      {
        Header: () => <div>Direct Drop</div>,
        accessor: 'directDrop',
        Cell: item => <div>{item.value ? 'Y' : 'N'}</div>,
        width: 45,
      },
      {
        Header: () => <div>Planned Goods Issue Date</div>,
        accessor: 'plannedGoodsIssueDate',
        Cell: item => <div>{date(item.value)}</div>,
      },
      {
        Header: () => <div>Actual Departure Date</div>,
        accessor: 'actualShipDateTime',
        Cell: item => <div>{date(item.value)}</div>,
      },
      {
        Header: () => <div>Last Approved IDP Date</div>,
        accessor: 'iDPPlannedTimestamp',
        id: 'originalIDPPlannedTimestamp',
        disableSortBy: true,
        Cell: item => (
          <div>
            {date(item.value.original?.lastApprovedIdpDate, false, true)}
            <br></br>
            {item.value.original?.lastApprovedIdpFromTime
              ? item.value.original?.lastApprovedIdpFromTime +
                ' - ' +
                item.value.original?.lastApprovedIdpToTime
              : undefined}
          </div>
        ),
        minWidth: 150,
      },
      {
        Header: () => <div>Original Traffic Instructions</div>,
        id: 'originalTrafficInstructions',
        accessor: 'deliveryInstructionText',
        disableSortBy: true,
        Cell: item => <div>{item.value.original}</div>,
      },
      {
        Header: () => <div>New IDP Date</div>,
        accessor: 'iDPPlannedTimestamp',
        id: 'newIDPPlannedTimestamp',
        disableSortBy: true,
        Cell: item => (
          <div>
            <span
              className={clsx({
                'eds-color--pink-70 fw-bold': item.value.original?.date !== item.value.new?.date,
              })}
            >
              {date(item.value.new?.date, false, true)}
            </span>
            <br></br>
            <span
              className={
                item.value.original?.plannedDeliveryFromTime !==
                  item.value.new?.plannedDeliveryFromTime ||
                item.value.original?.plannedDeliveryToTime !== item.value.new?.plannedDeliveryToTime
                  ? 'eds-color--pink-70 fw-bold'
                  : 'eds-color--default'
              }
            >
              {item.value.new?.plannedDeliveryFromTime
                ? item.value.new?.plannedDeliveryFromTime +
                  ' - ' +
                  item.value.new?.plannedDeliveryToTime
                : undefined}
            </span>
          </div>
        ),
        minWidth: 150,
      },
      {
        Header: () => <div>New Traffic Instructions</div>,
        id: 'newTrafficInstructions',
        accessor: 'deliveryInstructionText',
        disableSortBy: true,
        Cell: item => <div>{item.value.new}</div>,
      },
      {
        Header: () => <div>Type</div>,
        accessor: 'rebookingTypeData',
        disableSortBy: true,
        Cell: item => (
          <div className={`responsible-party-${item.value.data.responsiblePartyCode}`}>
            {item.value.type && (
              <Tooltip
                bodySlot={<TooltipRebookingContent type={item.value.type} data={item.value.data} />}
                isDark={true}
                placement={'right'}
                modifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      padding: 8,
                    },
                  },
                ]}
              >
                <div
                  className="flex-row flex-nowrap content-center justify-items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                    {item.value.type}
                  </div>
                  <Icon name="InfoCircle" size="s" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
        width: 120,
      },
    ],
    []
  )

  return useServerDataTable({
    queryState,
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
    overrides: {
      gotoPage,
      setPageSize,
      toggleSortBy,
    },
    extraPlugins: [useAllServerRowsSelect],
  })
}
