import { Button, Icon, Spinner, Table, TableCell, TableHeading, Text } from '@nike/eds'
import React from 'react'
import { TableInstance } from 'react-table'
import './TableLayout.css'
import { Error } from '../../error/Error'
import { MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { TablePagination } from '../tablepagination/TablePagination'

interface TableLayoutProps extends TableInstance {
  pageSizeOptions: number[]
  totalResources: number
  isFetching: boolean
  isError: boolean
  refetchData: () => void
  expandedRow?: React.ElementType
  withPagination?: boolean
}

const FetchingError = ({ refetchData }) => {
  return (
    <Error
      message={'There was an issue fetching the table data. Please try again!'}
      actions={<Button onClick={() => refetchData()}>Refresh table</Button>}
    />
  )
}

const FetchingData = () => (
  <div className="flex-row flex-wrap content-center justify-items-center">
    <div style={{ marginRight: 'var(--eds-space-8)' }}>
      <Spinner size="large" />
    </div>
    <div>
      <Text>Fetching Data</Text>
    </div>
  </div>
)

const NoData = ({ refetchData, noDataMessage = '' }) => (
  <Error
    title="No Data Found"
    message={
      noDataMessage !== '' ? noDataMessage : 'Please use other criterias or refresh the table later'
    }
    actions={<Button onClick={() => refetchData()}>Refresh table</Button>}
  />
)

export const TableLayout = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  page,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  setPageSize,
  toggleSortBy,
  state: { pageIndex, pageSize },
  pageSizeOptions,
  totalResources,
  isFetching,
  isError,
  refetchData,
  expandedRow: ExpandedRow,
  withPagination = true,
  noDataMessage = '',
}: TableLayoutProps): JSX.Element => {
  const changePageSize = (event: SelectChangeEvent<string>) => {
    setPageSize(event.target.value as unknown as number)
    gotoPage(0)
  }

  let isFewRecords = false
  if (totalResources < pageSize) {
    isFewRecords = true
  }

  let isLastPage = false
  if (pageIndex === pageCount - 1) {
    isLastPage = true
  }
  // Render the UI for your table
  return (
    <div>
      {isError && <FetchingError refetchData={refetchData} />}
      {!isError && isFetching && <FetchingData />}

      {!isError && !isFetching && (withPagination ? totalResources === 0 : page.length === 0) && (
        <NoData refetchData={refetchData} noDataMessage={noDataMessage} />
      )}
      {!isError && !isFetching && (withPagination ? totalResources > 0 : page.length > 0) && (
        <>
          {withPagination && (
            <div
              className="flex-row flex-wrap content-center justify-items-center TableLayoutPagination"
              style={{ marginTop: 'calc(-1 * var(--eds-space-72))' }}
            >
              <TablePagination
                {...{
                  pageIndex,
                  pageCount,
                  canPreviousPage,
                  canNextPage,
                  gotoPage,
                  refetchData,
                }}
                style={{ height: 'var(--eds-space-56)' }}
              />
            </div>
          )}

          <div
            style={{
              overflowX: 'clip',
            }}
          >
            <Table isDark={false} {...getTableProps()}>
              <thead>
                {
                  // Loop over the header rows
                  headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map(column => (
                          // Apply the header cell props
                          <TableHeading
                            {...column.getHeaderProps({
                              style: { minWidth: column.minWidth, width: column.width },
                            })}
                          >
                            {
                              // Render the header
                              //if column is sortable
                              column.canSort ? (
                                <div
                                  {...column.getSortByToggleProps({
                                    style: { minWidth: column.minWidth, width: column.width },
                                  })}
                                  onClick={() => {
                                    toggleSortBy(
                                      column.id,
                                      column.isSorted ? !column.isSortedDesc : false
                                    )
                                  }}
                                >
                                  <div className="flex-row flex-nowrap content-space-between justify-items-center">
                                    <div>{column.render('Header')}</div>
                                    <div>
                                      {/* Add a sort direction indicator */}
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <Icon
                                            name="SortDescending"
                                            size="m"
                                            className="TableSortIconDesc"
                                          />
                                        ) : (
                                          <Icon
                                            name="SortAscending"
                                            size="m"
                                            className="TableSortIconAsc"
                                          />
                                        )
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                //if column is not sortable
                                <div>{column.render('Header')}</div>
                              )
                            }
                          </TableHeading>
                        ))
                      }
                    </tr>
                  ))
                }
              </thead>
              {/* Apply the table body props */}

              <tbody {...getTableBodyProps()}>
                {
                  // Loop over the table rows
                  page.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    const { key, ...rowProps } = row.getRowProps()
                    return (
                      // Apply the row props
                      <React.Fragment key={key}>
                        <tr {...rowProps}>
                          {
                            // Loop over the rows cells
                            row.cells.map(cell => {
                              // Apply the cell props
                              return (
                                <TableCell {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render('Cell')
                                  }
                                </TableCell>
                              )
                            })
                          }
                        </tr>
                        {row.isExpanded && ExpandedRow && (
                          <tr {...rowProps}>
                            <td colSpan={row.cells.length}>
                              <ExpandedRow {...row} />
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
          {withPagination && (
            <div className="flex-row flex-wrap content-space-between justify-items-center TableLayoutPagination">
              <div>
                <Text font="body-2" as="span">
                  {totalResources === 0 ? 0 : pageSize * (pageIndex + 1) - (pageSize - 1)} -{' '}
                  {isFewRecords
                    ? totalResources
                    : isLastPage
                    ? totalResources
                    : pageSize * (pageIndex + 1)}{' '}
                  of {totalResources} results
                </Text>
              </div>

              <TablePagination
                {...{
                  pageIndex,
                  pageCount,
                  canPreviousPage,
                  canNextPage,
                  gotoPage,
                  refetchData,
                }}
                style={{ height: 'var(--eds-space-56)' }}
              />

              <div>
                <Text font="body-2" as="span" style={{ marginRight: 'var(--eds-space-4)' }}>
                  Rows per page:{' '}
                </Text>
                <Select
                  value={pageSize?.toString()}
                  onChange={changePageSize}
                  style={{ marginRight: 'var(--eds-space-16)' }}
                >
                  {pageSizeOptions.map(it => (
                    <MenuItem key={it} value={it}>
                      {it}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
