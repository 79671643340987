import { Button, Icon, Text, Tooltip } from '@nike/eds'
import clsx from 'clsx'
import React from 'react'
import { TableInstance } from 'react-table'

type TablePaginationPropsFromTable = Pick<
  TableInstance,
  'pageIndex' | 'pageCount' | 'canPreviousPage' | 'canNextPage' | 'gotoPage'
>

export interface TablePaginationProps extends TablePaginationPropsFromTable {
  refetchData: () => void
  style?: React.CSSProperties
  className?: string
}

export const TablePagination = ({
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  refetchData,
  className,
  style,
}: TablePaginationProps) => (
  <div
    className={clsx('flex-row flex-wrap content-space-between justify-items-center', className)}
    style={style}
  >
    <Tooltip bodySlot={<Text>First Page</Text>} isDark={true} placement={'top'}>
      <Button onClick={() => gotoPage(0)} disabled={pageIndex === 0} size="small" variant="ghost">
        <Icon
          name="CaretLeft"
          style={{ marginRight: '-8px' }}
          className={pageIndex === 0 ? 'disabled' : ''}
        />
        <Icon
          name="CaretLeft"
          style={{ marginLeft: '-16px' }}
          className={pageIndex === 0 ? 'disabled' : ''}
        />
      </Button>
    </Tooltip>

    <Tooltip bodySlot={<Text>Previous Page</Text>} isDark={true} placement={'top'}>
      <Button
        onClick={() => gotoPage(pageIndex - 1)}
        disabled={!canPreviousPage}
        size="small"
        className="button-previous"
        variant="ghost"
        style={{ marginRight: 'var(--eds-space-16)' }}
      >
        <Icon name="CaretLeft" className={!canPreviousPage ? 'disabled' : ''} />
      </Button>
    </Tooltip>
    <Text font="body-2" as="span" style={{ marginTop: '-4px' }}>
      {pageCount === 0 ? 0 : pageIndex + 1} of {pageCount} pages
    </Text>

    <Tooltip bodySlot={<Text>Next Page</Text>} isDark={true} placement={'top'}>
      <Button
        onClick={() => gotoPage(pageIndex + 1)}
        disabled={!canNextPage}
        size="small"
        className="button-next"
        variant="ghost"
        style={{ marginLeft: 'var(--eds-space-16)' }}
      >
        <Icon name="CaretRight" className={!canNextPage ? 'disabled' : ''} />
      </Button>
    </Tooltip>

    <Tooltip bodySlot={<Text>Last Page</Text>} isDark={true} placement={'top'}>
      <Button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={pageIndex === pageCount - 1 || pageCount === 0}
        size="small"
        variant="ghost"
      >
        <Icon
          name="CaretRight"
          style={{ marginRight: '-8px' }}
          className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
        />
        <Icon
          name="CaretRight"
          style={{ marginLeft: '-16px' }}
          className={pageIndex === pageCount - 1 || pageCount === 0 ? 'disabled' : ''}
        />
      </Button>
    </Tooltip>
    <Tooltip bodySlot={<Text>Refresh table data</Text>} isDark={true} placement={'top'}>
      <Button
        onClick={refetchData}
        size="small"
        variant="ghost"
        style={{ marginLeft: 'var(--eds-space-16)' }}
      >
        <Icon name="Repeat" />
      </Button>
    </Tooltip>
  </div>
)
