import { Button, Icon, Link } from '@nike/eds'
import useQueryState from '../../../common/hooks/useQueryState'
import { useAutomaticApprovalConfigList } from '../../../common/hooks/useAutomaticApprovalConfigQueries'
import { useAutomaticApprovalConfigTable } from './automaticapprovalconfigtable/AutomaticApprovalConfigTable'
import { TableLayout } from '../../../common/components/table'
import { DeleteModal } from './deletemodal/DeleteModal'
import { useEffect, useState } from 'react'
import { AutomaticApprovalModal } from './automaticapprovalmodal/AutomaticApprovalModal'
import { useAllCarrierHubList } from '../../../common/hooks/useCarrierHubList'
import { CarrierHubTreeDropdown } from '../../../common/components/carrierhubtreedropdown/CarrierHubTreeDropdown'
import { useForm, useWatch } from 'react-hook-form'
import './AutomaticApproval.css'
import { AutoApprovalRulesModal } from '../../../common/components/autoapprovalrulesmodal/AutoApprovalRulesModal'
import useRoleContext from '../../../common/hooks/useRoleContext'
import { Navigate } from 'react-router-dom'

const pageSizeOptions = [10, 25, 50, 100, 200, 500]

const AutomaticApproval = () => {
  const { state: queryState, toggleSortBy } = useQueryState<any>({
    sortBy: [{ id: 'lastUpdatedOn', desc: false }],
  })
  const { control } = useForm({
    defaultValues: {
      carrierHubs: [],
    },
  })
  const selectedCarrierHubs = useWatch({ name: 'carrierHubs', control })
  const { isFetching, isError, isSuccess, data, refetch } = useAutomaticApprovalConfigList(
    selectedCarrierHubs,
    queryState
  )

  useEffect(() => {
    refetch()
  }, [selectedCarrierHubs, refetch])

  const hasEditAccess = useRoleContext().hasAutomaticApprovalConfigAccess()

  const tableInstance = useAutomaticApprovalConfigTable(
    queryState,
    toggleSortBy,
    isFetching,
    isSuccess,
    isError,
    hasEditAccess,
    data
  )
  const [automaticApprovalModalOpen, setAutomaticApprovalModalOpen] = useState<boolean>(false)
  const [autoApprovalRulesModalOpen, setAutoApprovalRulesModalOpen] = useState<boolean>(false)

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const { data: carrierHubs, isSuccess: isAllCarrierHubCallSuccess } = useAllCarrierHubList()

  const handleCloseAutomaticApprovalModalForm = () => {
    setAutomaticApprovalModalOpen(false)
  }

  const handleCloseDeleteModalForm = () => {
    setDeleteModalOpen(false)
  }

  const handleCloseAutoApprovalRulesModalForm = () => {
    setAutoApprovalRulesModalOpen(false)
  }

  useEffect(() => {
    console.log('selectedCarrierHubs', selectedCarrierHubs)
  }, [selectedCarrierHubs])

  const hasAutomaticApprovalAccess = useRoleContext().hasAutomaticApprovalConfigAccess()
  const hasInitiateAccess = useRoleContext().hasInitiateAccess()
  if (!(hasAutomaticApprovalAccess || hasInitiateAccess)) {
    // no automatic approval access - redirect to other settings and let the router handle the correct redirect
    return <Navigate to="/settings/rebookingReason" replace />
  }

  const formId = 'automaticApprovalForm'
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--eds-space-8)',
        }}
      >
        <div
          className="flex-column flex-nowrap content-space-between justify-items-left"
          style={{ padding: 'var(--eds-space-0) var(--eds-space-16)' }}
        >
          <div
            className="flex-row flex-nowrap content-space-between justify-items-center"
            style={{ padding: 'var(--eds-space-16) var(--eds-space-4)' }}
          >
            <div className="flex-row justify-items-center">
              {hasEditAccess && (
                <Button
                  size="medium"
                  style={{
                    marginRight: 'var(--eds-space-8)',
                    padding:
                      'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                  }}
                  beforeSlot={<Icon name="Edit" size="m" />}
                  onClick={() => setAutomaticApprovalModalOpen(true)}
                  disabled={tableInstance.selectedFlatRows.length !== 1}
                >
                  Edit
                </Button>
              )}

              {hasEditAccess && (
                <Button
                  size="medium"
                  style={{
                    marginRight: 'var(--eds-space-32)',
                    padding:
                      'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                  }}
                  beforeSlot={<Icon name="Delete" size="m" />}
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={tableInstance.selectedFlatRows.length === 0}
                >
                  Delete
                </Button>
              )}

              <Link
                font="subtitle-1"
                as="p"
                onClick={() => {
                  setAutoApprovalRulesModalOpen(true)
                }}
              >
                Auto Approval Rules
              </Link>
            </div>

            <div className="flex-row justify-items-center">
              <form
                id={formId}
                className="filter-form"
                style={{ marginRight: 'var(--eds-space-32)', maxWidth: '600px' }}
                onChange={() => refetch()}
              >
                <CarrierHubTreeDropdown
                  name="carrierHubs"
                  className="customcarrierhubdropdown"
                  control={control}
                  carrierHubs={isAllCarrierHubCallSuccess && carrierHubs ? carrierHubs : []}
                  showPartiallySelected={true}
                />
              </form>

              {hasEditAccess && (
                <Button
                  variant="primary"
                  className="Button"
                  style={{
                    paddingLeft: 'var(--eds-space-16)',
                    padding:
                      'var(--eds-space-8) var(--eds-space-16) var(--eds-space-8) var(--eds-space-8)',
                  }}
                  beforeSlot={<Icon name="Plus" size="m" />}
                  onClick={() => setAutomaticApprovalModalOpen(true)}
                  disabled={tableInstance.selectedFlatRows.length > 0}
                >
                  New
                </Button>
              )}
            </div>
          </div>

          <TableLayout
            {...{ ...tableInstance, pageSizeOptions, isFetching, isError }}
            queryState={queryState}
            className="-striped -highlight"
            refetchData={refetch}
            withPagination={false}
            noDataMessage={'No data found. Create a record or refresh the table later'}
          />
        </div>
        <AutomaticApprovalModal
          automaticApprovalModalOpen={automaticApprovalModalOpen}
          onCloseAutomaticApprovalModal={handleCloseAutomaticApprovalModalForm}
          selectedRow={tableInstance.selectedFlatRows[0]?.original}
          refetchData={refetch}
          tableData={data?.data}
        />
        <DeleteModal
          deleteModalOpen={deleteModalOpen}
          onCloseDeleteModal={handleCloseDeleteModalForm}
          selectedRows={tableInstance.selectedFlatRows}
          refetchData={refetch}
        />
        <AutoApprovalRulesModal
          autoApprovalRulesModalOpen={autoApprovalRulesModalOpen}
          onCloseAutoApprovalRulesModal={handleCloseAutoApprovalRulesModalForm}
        />
      </div>
    </>
  )
}

export default AutomaticApproval
