import { addDays, addMilliseconds } from 'date-fns'
import axios from 'axios'
import Config from '../config/Config'
import { RebookingApproveFilter, RebookingInitiateFilter } from './ShipmentService'
import { DeclineRebookingFormData } from '../pages/approvepage/declinemodal/DeclineModal'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'
import { DeclineReasonResponse } from '../common/hooks/useDeclineReasons'
import { ReasonResponse } from '../common/hooks/useReasons'
import { ResponsiblePartyResponse } from '../common/hooks/useResponsibleParties'
import { RebookingHistoryResponse } from '../common/hooks/useRebookingHistory'
import { zonedTimeToUtc } from 'date-fns-tz'

export interface RebookingFormData {
  responsibleParty: ResponsiblePartyResponse | null
  reason: ReasonResponse | null
  idpDate?: string
  plannedDeliveryFromTime?: string
  plannedDeliveryToTime?: string
  shipToCode?: string
  shipToAddressLine1?: string
  shipToAddressLine2?: string
  shipToAddressLine3?: string
  cityName?: string
  zipCode?: string
  trafficInstructions?: string
  alignedWithCarrierIndicator?: boolean
  rebookingType: string
  expectedMonthOfDelivery: string
}

//client for rebooking service
export const rebookingClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.REBOOKING_SERVICE_URL}/${Config.BASEPATH}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

//client for rebooking delivery view
export const rdvClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.BACKEND_URL}/${Config.BASEPATH}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface CarrierHub {
  carrierAccountCode?: string
  hubCode?: string
}

export const getSelectedShipmentReferences = (
  isAllRowsSelected: boolean,
  filter: RebookingInitiateFilter | RebookingApproveFilter,
  selectedRows: any
) => {
  if (isAllRowsSelected) {
    if (filter?.packlists && filter.packlists.length > 0) {
      return filter.packlists
    }
    return undefined
  }
  return selectedRows.map(row => row.original.shipmentReference)
}

export const getSelectedAutoApprovalConfigs = (selectedRows: any) => {
  return selectedRows.map(row => row.original.carrier)
}

const getBodyForInitiation = (
  selectedShipmentReferences,
  filter,
  formData: RebookingFormData | undefined,
  user,
  rebookingType: string,
  creationDateTime: Date | undefined
) => {
  // convert to CET
  const iDPPlannedFromTimestamp = filter?.iDPPlannedFromTimestamp
    ? zonedTimeToUtc(filter?.iDPPlannedFromTimestamp, 'Europe/Brussels').toISOString()
    : undefined

  // convert to CET and add set to end of day
  const iDPPlannedToTimestamp = filter?.iDPPlannedToTimestamp
    ? addMilliseconds(
        addDays(zonedTimeToUtc(filter?.iDPPlannedToTimestamp, 'Europe/Brussels'), 1),
        -1
      ).toISOString()
    : undefined

  return {
    shipments: {
      shipmentReferences: selectedShipmentReferences,
      carrierHubs: filter?.carrierHubs,
      originalIDPPlannedFromTimestamp: iDPPlannedFromTimestamp,
      originalIDPPlannedToTimestamp: iDPPlannedToTimestamp,
      originalShipToCodes: filter?.shipToCodes,
      trailerNumber: filter?.trailer,
      salesOrders: filter?.salesOrders,
      deliveryDocs: filter?.deliveryDocs,
      packlistStatuses: ['SCHEDULED', 'READY_FOR_SHIPMENT', 'SHIPPED'],
    },
    type: rebookingType, // REBOOKING or HOLD
    responsiblePartyCode: formData?.responsibleParty,
    reasonCode: formData?.reason?.code,
    requestorUser: user,
    alignedWithCarrierIndicator: formData?.alignedWithCarrierIndicator || false,
    addressLine1Text: formData?.shipToAddressLine1?.trim(),
    addressLine2Text: formData?.shipToAddressLine2?.trim(),
    addressLine3Text: formData?.shipToAddressLine3?.trim(),
    cityName: formData?.cityName?.trim(),
    zipCode: formData?.zipCode?.trim(),
    shipToCode: formData?.shipToCode,
    deliveryInstructionText: formData?.trafficInstructions?.trim(),
    iDPPlannedTimestamp: formData?.idpDate
      ? zonedTimeToUtc(formData?.idpDate, 'Europe/Brussels').toISOString()
      : undefined,
    plannedDeliveryFromTime: formData?.plannedDeliveryFromTime,
    plannedDeliveryToTime: formData?.plannedDeliveryToTime,
    expectedMonthOfDelivery: formData?.expectedMonthOfDelivery?.toUpperCase(),
    creationDateTime: creationDateTime?.toISOString(),
  }
}

const getBodyForApproveDecline = (
  selectedShipmentReferences,
  filter,
  formData: DeclineRebookingFormData | undefined,
  user,
  type: string,
  openRebookingCreationDateTime: Date | undefined
) => {
  return {
    filter: {
      open: true,
      shipmentReferences: selectedShipmentReferences,
      carrierHubs: filter?.carrierHubs,
      originalShipToCodes: filter?.shipToCodes,
      shipToCountries: filter?.countries,
      packlistStatuses: ['SCHEDULED', 'READY_FOR_SHIPMENT', 'SHIPPED'],
    },
    validatorUser: user,
    declineReasonCode: formData?.reason.code,
    allowExtraInfo: formData?.reason.allowExtraInfo || false,
    extraInfo: formData?.extraInfo,
    type: type,
    openRebookingCreationDateTime: openRebookingCreationDateTime?.toISOString(),
  }
}

const getBodyForAutoRebookingApprovalConfig = data => {
  return {
    carrier: data.carrier,
    hub: data.hub,
    alignedWithCarrierFlagNeeded: data.alignedWithCarrierFlagNeeded
      ? data.alignedWithCarrierFlagNeeded
      : false,
    deadlineTimeOfDay: data.deadlineTimeOfDay,
    condition: data.condition,
    actualShipDateCondition: data.actualShipDateCondition
      ? data.actualShipDateCondition
      : undefined,
    minDaysBeforeIDPPassed:
      data.minDaysBeforeIDPPassed || data.minDaysBeforeIDPPassed === 0
        ? +data.minDaysBeforeIDPPassed
        : undefined,
    minDaysBeforeIDPNotPassed:
      data.minDaysBeforeIDPNotPassed || data.minDaysBeforeIDPPassed === 0
        ? +data.minDaysBeforeIDPNotPassed
        : undefined,
  }
}

const getQueryBodyForAutomaticApprovalConfig = (selectedCarrierHubs, queryState) => {
  return {
    filter: {
      carrierHubs:
        selectedCarrierHubs.length > 0
          ? selectedCarrierHubs.map(carrierHub => {
              return {
                carrier:
                  carrierHub.carrierAccountCode || carrierHub.carrierAccountCode === ''
                    ? carrierHub.carrierAccountCode
                    : undefined,
                hub:
                  carrierHub.hubCode || carrierHub.hubCode === '' ? carrierHub.hubCode : undefined,
              }
            })
          : undefined,
    },

    sort: [
      queryState.sortBy
        ? queryState.sortBy[0].desc === true
          ? '-' + queryState.sortBy[0].id
          : queryState.sortBy[0].id
        : undefined,
    ],
  }
}

export const initiateRebooking = async (
  selectedShipmentReferences: string[] | undefined,
  filter: RebookingInitiateFilter,
  formData: RebookingFormData | undefined,
  user: string | undefined,
  rebookingType: string,
  creationDateTime: Date | undefined
): Promise<{
  rebookingIds
  rebookingRequestIds
}> => {
  return rebookingClient
    .post(
      `/rebooking/v1`,
      getBodyForInitiation(
        selectedShipmentReferences,
        filter,
        formData,
        user,
        rebookingType,
        creationDateTime
      )
    )
    .then(response => {
      return response.data
    })
}

export const approveDeclineRebooking = async (
  selectedShipmentReferences: string[] | unknown,
  filter: RebookingApproveFilter,
  formData: DeclineRebookingFormData | undefined,
  user: string | undefined,
  type: string,
  openRebookingCreationDateTime: Date | undefined
): Promise<{
  rebookingIds
  validationRequestIds
}> => {
  return rebookingClient
    .post(
      `/rebooking/v1/approval`,
      getBodyForApproveDecline(
        selectedShipmentReferences,
        filter,
        formData,
        user,
        type,
        openRebookingCreationDateTime
      )
    )
    .then(response => {
      return response.data
    })
}

const filterRebookingReasons = async (rebookingReasons: ReasonResponse[]) => {
  return rebookingReasons
    ?.filter(rebookingReason => rebookingReason.deprecated === false)
    .sort((a, b) => {
      let compareDescription = a?.description?.localeCompare(b?.description ? b?.description : '')
      return compareDescription ? compareDescription : 0
    })
}

export const fetchResponsibleParties = async (): Promise<ResponsiblePartyResponse[]> => {
  return rebookingClient.get(`/rebooking/v1/rebookingResponsibleParties`).then(response => {
    return response.data
  })
}

export const fetchCountries = async (): Promise<ResponsiblePartyResponse[]> => {
  return rdvClient.get(`/countries`).then(response => {
    return response.data
  })
}

export const fetchHistory = async (shipmentReference): Promise<RebookingHistoryResponse[]> => {
  return rebookingClient
    .get(`rebooking/v1/history/${encodeURIComponent(shipmentReference)}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 404) {
        return []
      }
      throw error
    })
}

export const fetchAutomaticApprovalConfigs = async (
  selectedCarrierHubs,
  queryState
): Promise<{
  data
}> => {
  console.debug('fetchConfigs with: ' + JSON.stringify(selectedCarrierHubs))

  return rebookingClient
    .post(
      `/rebooking-config/v2/query`,
      getQueryBodyForAutomaticApprovalConfig(selectedCarrierHubs, queryState)
    )
    .then(response => {
      return {
        data: response.data,
      }
    })
}

export const fetchAutomaticApprovalConfig = async (carrier, hub): Promise<{ data }> => {
  return rebookingClient
    .get(`rebooking-config/v2/${carrier}/${hub}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error.response.status === 404) {
        return []
      }
      throw error
    })
}

export const deleteAutoRebookingApprovalConfig = async (
  selectedConfigs: any
): Promise<{
  rebookingIds
  validationRequestIds
}> => {
  return rebookingClient
    .delete(
      `/rebooking-config/v2/${selectedConfigs?.original.carrier}/${selectedConfigs?.original.hub}`
    )
    .then(response => {
      return response.data
    })
}

export const postAutoRebookingApprovalConfig = async (data): Promise<{}> => {
  return rebookingClient
    .post(`/rebooking-config/v2`, getBodyForAutoRebookingApprovalConfig(data))
    .then(response => {
      return response.data
    })
}

export const updateAutoRebookingApprovalConfig = async (carrier, hub, body): Promise<{}> => {
  return rebookingClient
    .put(`/rebooking-config/v2/${carrier}/${hub}`, getBodyForAutoRebookingApprovalConfig(body))
    .then(response => {
      return response.data
    })
}

export const fetchReasons = async (): Promise<ReasonResponse[] | undefined> => {
  return rebookingClient.get(`/rebooking/v1/rebookingReasons`).then(response => {
    return filterRebookingReasons(response.data)
  })
}

export const fetchReason = async (selectedReason: any): Promise<ReasonResponse | undefined> => {
  return rebookingClient
    .get(`/rebooking/v1/rebookingReason/${selectedReason?.original._id}`)
    .then(response => {
      return response.data
    })
}

export const deleteRebookingReason = async (selectedReason: any): Promise<{}> => {
  return rebookingClient
    .delete(`/rebooking/v1/rebookingReasons/${selectedReason?.original._id}`)
    .then(response => {
      return response.data
    })
}

export const postRebookingReason = async (body): Promise<{}> => {
  return rebookingClient.post(`/rebooking/v1/rebookingReasons`, body).then(response => {
    return response.data
  })
}

export const updateRebookingReason = async (reasonId: any, body): Promise<{}> => {
  return rebookingClient.put(`/rebooking/v1/rebookingReasons/${reasonId}`, body).then(response => {
    return response.data
  })
}

export const fetchDeclineReasons = async (): Promise<DeclineReasonResponse[]> => {
  return rebookingClient
    .get(`/rebooking/v1/rebookingDeclineReasons`)

    .then(response => {
      return response.data
    })
}

export const fetchDeclineReason = async (
  selectedConfigs: any
): Promise<ReasonResponse | undefined> => {
  return rebookingClient
    .get(`/rebooking/v1/rebookingDeclineReasons/${selectedConfigs?.original._id}`)
    .then(response => {
      return response.data
    })
}

export const deleteRebookingDeclineReason = async (selectedReason: any): Promise<{}> => {
  return rebookingClient
    .delete(`/rebooking/v1/rebookingDeclineReasons/${selectedReason?.original._id}`)
    .then(response => {
      return response.data
    })
}

export const postRebookingDeclineReason = async (body): Promise<{}> => {
  return rebookingClient.post(`/rebooking/v1/rebookingDeclineReasons`, body).then(response => {
    return response.data
  })
}

export const updateRebookingDeclineReason = async (reasonId: any, body): Promise<{}> => {
  return rebookingClient
    .put(`/rebooking/v1/rebookingDeclineReasons/${reasonId}`, body)
    .then(response => {
      return response.data
    })
}
