import { RebookingHistoryResponse, useRebookingHistory } from '../../hooks/useRebookingHistory'
import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Table, TableCell, TableHeading, Text } from '@nike/eds'
import './RebookingHistoryRow.css'
import { date } from '../../../common/utils/datautils/formatter'
import { clsx } from 'clsx'

export const RebookingHistoryRow = props => {
  const shipmentReference = props.values.shipmentReference
  const originalIdpPlannedTimestamp = props.values.originalIDPPlannedTimestamp.original

  const { data, isFetched, isFetching } = useRebookingHistory(shipmentReference)

  const columns: Array<Column<RebookingHistoryResponse>> = useMemo(
    () => [
      {
        Header: () => <div>Event Date</div>,
        accessor: 'creationDateTime',
        Cell: item => <div>{date(item.value, true)}</div>,
        minWidth: 160,
      },
      {
        Header: () => <div>Status</div>,
        accessor: 'status',
        Cell: item => <div>{item.value}</div>,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: () => <div>Type</div>,
        accessor: 'type',
        Cell: item => <div>{item.value}</div>,
        minWidth: 100,
        maxWidth: 100,
      },
      {
        Header: () => <div>Responsible Party</div>,
        accessor: 'responsiblePartyCode',
        Cell: item => <div>{item.value}</div>,
        width: 140,
      },
      {
        Header: () => <div>Reason</div>,
        accessor: 'reasonCode',
        Cell: props => {
          const { status, reasonCode, declineReasonCode, extraInfo } = props.row.original
          const reason =
            status === 'APPROVED'
              ? reasonCode
              : extraInfo && extraInfo.length > 0
              ? extraInfo
              : declineReasonCode

          return <div>{reason}</div>
        },
        width: 200,
      },
      {
        Header: () => <div>Requestor User</div>,
        accessor: 'requestorUser',
        Cell: item => <div>{item.value}</div>,
      },
      {
        Header: () => <div>Validator User</div>,
        accessor: 'validatorUser',
        Cell: item => <div>{item.value}</div>,
      },
      {
        Header: () => <div>Validated</div>,
        accessor: 'validatedDateTime',
        Cell: item => <div>{date(item.value, true)}</div>,
        maxWidth: 160,
      },
      {
        Header: () => <div>IDP Date</div>,
        accessor: 'iDPPlannedTimestamp',
        Cell: props => {
          const {
            iDPPlannedTimestamp: newIDPPlannedTimestamp,
            plannedDeliveryFromTime: newPlannedDeliveryFromTime,
            plannedDeliveryToTime: newPlannedDeliveryToTime,
          } = props.row.original // this has nothing to do with original IDP, "original" is a property from react-table
          return (
            <div>
              <span
                className={clsx({
                  'eds-color--pink-70 fw-bold':
                    date(originalIdpPlannedTimestamp?.date) !== date(newIDPPlannedTimestamp),
                })}
              >
                {date(newIDPPlannedTimestamp, false, true)}
              </span>
              <br />
              {newPlannedDeliveryFromTime && newPlannedDeliveryToTime && (
                <span
                  className={
                    originalIdpPlannedTimestamp?.plannedDeliveryFromTime !==
                      newPlannedDeliveryFromTime ||
                    originalIdpPlannedTimestamp?.plannedDeliveryToTime !== newPlannedDeliveryToTime
                      ? 'eds-color--pink-70 fw-bold'
                      : 'eds-color--default'
                  }
                >
                  {newPlannedDeliveryFromTime} - {newPlannedDeliveryToTime}
                </span>
              )}
            </div>
          )
        },
        minWidth: 200,
        maxWidth: 200,
      },
      {
        Header: () => <div>Ship To</div>,
        accessor: 'shipToCode',
        Cell: item => <div>{item.value}</div>,
        maxWidth: 90,
      },
      {
        Header: () => <div>Address Lines</div>,
        accessor: 'addressLine1Text',
        Cell: props => {
          const { addressLine1Text, addressLine2Text, addressLine3Text } = props.row.original
          return (
            <div>
              {addressLine1Text} {addressLine2Text} {addressLine3Text}
            </div>
          )
        },
        width: 250,
      },
      {
        Header: () => <div>Zip Code</div>,
        accessor: 'zipCode',
        Cell: item => <div>{item.value}</div>,
      },
      {
        Header: () => <div>City</div>,
        accessor: 'cityName',
        Cell: item => <div>{item.value}</div>,
      },
      {
        Header: () => <div>Traffic Instructions</div>,
        accessor: 'deliveryInstructionText',
        Cell: item => <div>{item.value}</div>,
      },
    ],
    [originalIdpPlannedTimestamp]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: isFetched && data ? data : [],
  })

  return (
    <div className={'rebooking-history-sub'}>
      {isFetching && <Text as={'p'}>Loading...</Text>}
      {isFetched && data && data.length === 0 && !isFetching && (
        <Text as={'p'}>No history available for packlist {shipmentReference}</Text>
      )}
      {isFetched && data && data.length > 0 && !isFetching && (
        <>
          <Text as={'p'} className="eds-spacing--mb-16">
            History for packlist {shipmentReference}
          </Text>
          <Table {...getTableProps()}>
            <thead>
              {
                // Loop over the header rows
                headerGroups.map(headerGroup => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <TableHeading
                          {...column.getHeaderProps({
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                              maxWidth: column.maxWidth,
                            },
                          })}
                        >
                          {<div>{column.render('Header')}</div>}
                        </TableHeading>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map(row => {
                  // Prepare the row for display
                  prepareRow(row)
                  return (
                    // Apply the row props
                    <>
                      <tr {...row.getRowProps()}>
                        {
                          // Loop over the rows cells
                          row.cells.map(cell => {
                            // Apply the cell props
                            return (
                              <TableCell {...cell.getCellProps()}>
                                {
                                  // Render the cell contents
                                  cell.render('Cell')
                                }
                              </TableCell>
                            )
                          })
                        }
                      </tr>
                    </>
                  )
                })
              }
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}
