import { useQuery, UseQueryResult } from 'react-query'
import { CarrierHub } from '../../services/ShipmentService'
import { fetchAllCarriers, fetchCarriers } from '../../services/CarrierConfigService'

export interface CarrierHubResponse {
  carrierCode: string
  description?: string
  carrierName?: string
  carrierAccountCode: string
  hubCode?: string
  xDockScacCodes?: XDocScacCode[]
}

export interface DropDownTreeSelectCarrierHubResponse {
  label?: string
  value?: string
  carrierHubs?: CarrierHub[]
  carrierHub?: CarrierHub
  children?: DropDownTreeSelectCarrierHubResponse[]
  tagLabel?: string
  checked?: boolean
}

interface XDocScacCode {
  xDockScacCode: string
  active?: boolean
}

export const useCarrierHubList = (): UseQueryResult<CarrierHubResponse[], any> => {
  return useQuery(['carriers'], fetchCarriers, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}

export const useAllCarrierHubList = (): UseQueryResult<CarrierHubResponse[], any> => {
  return useQuery(['carriers'], fetchAllCarriers, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
